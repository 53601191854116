import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { AppSettings } from "./AppSettings";

i18next
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        supportedLngs: ["de", "en", "fr"],
        fallbackLng: "en",
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false
        },
        detection: {
            caches: []
        },
        backend: {
            loadPath: AppSettings.rootPath + "/v1/locales/{{lng}}/translation.json",
            allowMultiLoading: false
        },
        debug: process.env.NODE_ENV === "development"
    })

export default i18next