import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    CalendarMonth,
    Card,
    Divider,
    Drawer,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerPanelContent,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Hint,
    HintBody,
    InputGroup,
    Modal,
    ModalVariant,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Popover,
    SearchInput,
    Spinner,
    Switch,
    Tab,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    TextInput,
    Title,
    TitleSizes,
    ToggleGroup,
    ToggleGroupItem
} from "@patternfly/react-core";

import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    ListIcon,
    PlusIcon,
    QuestionCircleIcon,
    SyncAltIcon,
    OutlinedCalendarAltIcon
} from "@patternfly/react-icons";

import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";

import { AppSettings } from "./AppSettings";
import AnchorDetails from "./AnchorDetails";
import {date2ISOString, formatDate} from "./Utils";
import queryString from "query-string";

class AnchorList extends Component {
    static ANCHOR_COLUMNS = [
        {field: "serialNumber", key: "fe_common_serial-number", width: 15},
        {field: "status", key: "fe_common_status", width: 20},
        {field: "hardwareVersion", key: "anchor_prop_hardware-version", width: 10},
        {field: "name", key: "fe_common_name", width: 30},
        {field: "customer", key: "fe_common_customer", width: 25}
    ]

    static COEFFICIENT_COLUMNS = [
        {field: "anchorVersion", key: "anchor_prop_hardware-version", width: 10},
        {field: "a0", label: "a₀", width: 15},
        {field: "a1", label: "a₁", width: 15},
        {field: "a2", label: "a₂", width: 15},
        {field: "a3", label: "a₃", width: 15},
        {field: "a4", label: "a₄", width: 15},
        {field: "a5", label: "a₅", width: 15}
    ]

    static sortAnchors(rows, index, direction) {
        const field = AnchorList.ANCHOR_COLUMNS[index].field
        return rows.sort((g1, g2) => {
            if (direction === "asc") {
                return g1[field].localeCompare(g2[field])
            }
            return g2[field].localeCompare(g1[field])
        })
    }

    static filterAnchors(rows, filter) {
        return rows.filter(row => {
            return (!filter.provisioned || row["statusEnum"] === "Operational" || row["statusEnum"] === "IncompleteAnchorSettings" || row["statusEnum"] === "Error") &&
                (!filter.notCalibrated || row["statusEnum"] === "NotCalibrated" || row["statusEnum"] === "Error") &&
                (!filter.notProvisioned || row["statusEnum"] === "NotCalibrated" || row["statusEnum"] === "Unused") &&
                (!filter.searchString || row["serialNumber"].includes(filter.searchString))
        })
    }

    static status2Icon(statusEnum) {
        switch (statusEnum) {
        case "IncompleteAnchorSettings":
        case "NotCalibrated":
            return <ExclamationTriangleIcon className="Warning"/>
        case "Operational":
            return <CheckCircleIcon className="Success"/>
        case "Error":
            return <ExclamationCircleIcon className="Danger"/>
        default:
            return <QuestionCircleIcon className="Unknown"/>
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            anchors: [],
            coefficients: [],
            activeTabKey: 0,
            selectedAnchorIndex: -1,
            selectedCoefficientsIndex: -1,
            activeSortIndex: 0,
            activeSortDirection: "asc",
            registerMany: false,
            registerFromNumber: "",
            registerUntilNumber: "",
            registerManufacturedAt: null,
            registerManufacturedAtFormatted: "",
            registerCharge: "",
            registerHardwareVersion: "",
            registerArticleNumber: "",
            registerTotalLength: "",
            diameterDropDownOpen: false,
            registerDiameter: "",
            materialDropDownOpen: false,
            registerMaterial: "",
            registerSensorPosition: "",
            registerSignalBias1: "",
            registerSignalBias2: "",
            registerForceBias1: "",
            registerForceBias2: "",
            registerTemperatureOffset: "",
            registerInProgress: false,
            calendarOpen: false,
            hardwareVersionDropDownOpen: false,
            focusSerialNumber: false,
            successMessage: false,
            successMessage2: false,
            filter: {
                notProvisioned: false,
                provisioned: false,
                notCalibrated: false,
                searchString: ""
            },
            addHardwareVersion: "",
            addA0: 0.0,
            addA1: 0.0,
            addA2: 0.0,
            addA3: 0.0,
            addA4: 0.0,
            addA5: 0.0,
            addInProgress: false,
            knownHardwareVersions: [],
            reloadTrigger: false
        }

        this.refreshAnchorList = this.refreshAnchorList.bind(this)
        this.loadAnchorList = this.loadAnchorList.bind(this)
        this.onAnchorClick = this.onAnchorClick.bind(this)
        this.onCoefficientsClick = this.onCoefficientsClick.bind(this)
        this.onTabSelect = this.onTabSelect.bind(this)
        this.onDrawerCloseClick = this.onDrawerCloseClick.bind(this)
        this.onSort = this.onSort.bind(this)
        this.onToggleCalendar = this.onToggleCalendar.bind(this)
        this.onSelectCalendar = this.onSelectCalendar.bind(this)
        this.onChangeRegisterMany = this.onChangeRegisterMany.bind(this)
        this.onChangeFromNumber = this.onChangeFromNumber.bind(this)
        this.onChangeUntilNumber = this.onChangeUntilNumber.bind(this)
        this.onChangeCharge = this.onChangeCharge.bind(this)
        this.onSelectHardwareVersion = this.onSelectHardwareVersion.bind(this)
        this.onToggleHardwareVersionDropDown = this.onToggleHardwareVersionDropDown.bind(this)
        this.onChangeHardwareVersion = this.onChangeHardwareVersion.bind(this)
        this.onChangeArticleNumber = this.onChangeArticleNumber.bind(this)
        this.onChangeTotalLength = this.onChangeTotalLength.bind(this)
        this.onSelectDiameter = this.onSelectDiameter.bind(this)
        this.onToggleDiameterDropDown = this.onToggleDiameterDropDown.bind(this)
        this.onChangeDiameter = this.onChangeDiameter.bind(this)
        this.onSelectMaterial = this.onSelectMaterial.bind(this)
        this.onToggleMaterialDropDown = this.onToggleMaterialDropDown.bind(this)
        this.onChangeMaterial = this.onChangeMaterial.bind(this)
        this.onChangeSensorPosition = this.onChangeSensorPosition.bind(this)
        this.onChangeSignalBias1 = this.onChangeSignalBias1.bind(this)
        this.onChangeSignalBias2 = this.onChangeSignalBias2.bind(this)
        this.onChangeForceBias1 = this.onChangeForceBias1.bind(this)
        this.onChangeForceBias2 = this.onChangeForceBias2.bind(this)
        this.onChangeTemperatureOffset = this.onChangeTemperatureOffset.bind(this)
        this.isRegisterButtonDisabled = this.isRegisterButtonDisabled.bind(this)
        this.onRegisterAnchor = this.onRegisterAnchor.bind(this)
        this.onRegisterAnchors = this.onRegisterAnchors.bind(this)
        this.filterClicked = this.filterClicked.bind(this)
        this.onChangeSearchString = this.onChangeSearchString.bind(this)
        this.loadCompensationCoefficients = this.loadCompensationCoefficients.bind(this)
        this.onChangeAddHardwareVersion = this.onChangeAddHardwareVersion.bind(this)
        this.onChangeAddA0 = this.onChangeAddA0.bind(this)
        this.onChangeAddA1 = this.onChangeAddA1.bind(this)
        this.onChangeAddA2 = this.onChangeAddA2.bind(this)
        this.onChangeAddA3 = this.onChangeAddA3.bind(this)
        this.onChangeAddA4 = this.onChangeAddA4.bind(this)
        this.onChangeAddA5 = this.onChangeAddA5.bind(this)
        this.isAddHardwareButtonDisabled = this.isAddHardwareButtonDisabled.bind(this)
        this.onAddHardware = this.onAddHardware.bind(this)
        this.loadKnownHardwareVersions = this.loadKnownHardwareVersions.bind(this)
        this.reloadAnchorDetails = this.reloadAnchorDetails.bind(this)
        this.renderEditCoefficientsModal = this.renderEditCoefficientsModal.bind(this)
        this.closeEditCoefficientsModal = this.closeEditCoefficientsModal.bind(this)
        this.confirmEditCoefficientsModal = this.confirmEditCoefficientsModal.bind(this)
        this.onChangeCoefficient = this.onChangeCoefficient.bind(this)
    }

    componentDidMount() {
        const filter = queryString.parse(this.props["location"].search).filter || false
        this.setState({
            filter: {
                provisioned: filter === "provisioned",
                notCalibrated: filter === "notCalibrated",
                searchString: filter && filter.startsWith("q:") ? filter.substring(2) : ""
            }
        })

        this.loadAnchorList()
        this.refreshTimerId = window.setInterval(this.refreshAnchorList, 60 * 1_000)
    }

    componentWillUnmount() {
        if (this.refreshTimerId !== false) {
            window.clearInterval(this.refreshTimerId)
            this.refreshTimerId = false
        }
    }

    refreshAnchorList() {
        const selectedAnchorId = this.state.selectedAnchorIndex !== -1 ? this.state.anchors[this.state.selectedAnchorIndex].serialNumber : false
        this.loadAnchorList(selectedAnchorId)
    }

    loadAnchorList(selectedAnchorId) {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.anchors = data
                            this.setState(prevState => {
                                const anchors = AnchorList.sortAnchors(AnchorList.filterAnchors(this.anchors, prevState.filter), prevState.activeSortIndex, prevState.activeSortDirection)
                                return {
                                    selectedAnchorIndex: anchors.length === 1 ? 0 : selectedAnchorId ? anchors.findIndex(g => g.serialNumber === selectedAnchorId) : -1,
                                    anchors: anchors,
                                    reloadTrigger: !prevState.reloadTrigger
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    render() {
        const drawerExpanded = this.state.selectedAnchorIndex !== -1
        const serialNumber = drawerExpanded ? this.state.anchors[this.state.selectedAnchorIndex].serialNumber : ""
        return (
            <Translation>
                { t =>
                    <>
                        <Drawer isExpanded={drawerExpanded} position="right">
                            <DrawerContent panelContent={
                                <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                    {
                                        drawerExpanded && <AnchorDetails
                                            app={this.props.app}
                                            serialNumber={serialNumber}
                                            reloadTrigger={this.state.reloadTrigger}
                                            closeDrawer={() => this.setState({selectedAnchorIndex: -1})}
                                            reload={() => this.loadAnchorList(serialNumber)}
                                            reloadAndClose={this.loadAnchorList}
                                        />
                                    }
                                </DrawerPanelContent>
                            }>
                                <DrawerContentBody>
                                    <PageGroup>
                                        <PageSection variant={PageSectionVariants.light}>
                                            <Title
                                                className="Title"
                                                headingLevel="h1"
                                            >
                                                {t("fe_anchors_title")}
                                            </Title>
                                            <Tabs
                                                activeKey={this.state.activeTabKey}
                                                onSelect={this.onTabSelect}
                                                isBox={true}
                                            >
                                                <Tab eventKey={0}
                                                     title={<>
                                                         <TabTitleIcon><ListIcon/></TabTitleIcon>
                                                         <TabTitleText>{t("fe_anchors_overview")}</TabTitleText>
                                                     </>}>
                                                    <ToggleGroup aria-label="Filter items" className="TopSpacer">
                                                        <ToggleGroupItem
                                                            text={t("anchor_status_not-operational")}
                                                            buttonId="notProvisioned"
                                                            isSelected={this.state.filter.notProvisioned}
                                                            onChange={this.filterClicked}
                                                        />
                                                        <ToggleGroupItem
                                                            text={t("anchor_status_operational")}
                                                            buttonId="provisioned"
                                                            isSelected={this.state.filter.provisioned}
                                                            onChange={this.filterClicked}
                                                        />
                                                        <ToggleGroupItem
                                                            text={t("anchor_status_not-calibrated")}
                                                             buttonId="notCalibrated"
                                                             isSelected={this.state.filter.notCalibrated}
                                                             onChange={this.filterClicked}
                                                        />
                                                        <SearchInput
                                                            className="SearchInput"
                                                            placeholder={t("fe_common_serial-number")}
                                                            value={this.state.filter.searchString}
                                                            onChange={this.onChangeSearchString}
                                                            onClear={e => this.onChangeSearchString('', e)}
                                                        />
                                                    </ToggleGroup>
                                                    <Card component="div">
                                                        <TableComposable
                                                            aria-label="SensorAnchors"
                                                            variant="compact"
                                                            borders
                                                            isStickyHeader
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    {
                                                                        AnchorList.ANCHOR_COLUMNS.map((column, index) => {
                                                                            const sortParams = {
                                                                                sort: {
                                                                                    sortBy: {
                                                                                        index: this.state.activeSortIndex,
                                                                                        direction: this.state.activeSortDirection
                                                                                    },
                                                                                    onSort: this.onSort,
                                                                                    columnIndex: index
                                                                                }
                                                                            };
                                                                            return <Th key={index} {...sortParams}
                                                                                       width={column.width}>{t(column.key)}</Th>
                                                                        })
                                                                    }
                                                                    <Th key="refresh" className="RefreshButton">
                                                                        <Button
                                                                            variant="plain"
                                                                            isInline
                                                                            aria-label="Refresh"
                                                                            onClick={this.loadAnchorList}>
                                                                            <SyncAltIcon/>
                                                                        </Button>
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    this.state.anchors.map((row, rowIndex) => {
                                                                        return (
                                                                            <Tr isHoverable key={rowIndex}
                                                                                onRowClick={event => this.onAnchorClick(event, rowIndex)}
                                                                                isRowSelected={this.state.selectedAnchorIndex === rowIndex}>
                                                                                <Td key={`${rowIndex}_0`}
                                                                                    dataLabel={t(AnchorList.ANCHOR_COLUMNS[0].key)}>
                                                                                    {row["serialNumber"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_1`}
                                                                                    dataLabel={t(AnchorList.ANCHOR_COLUMNS[1].key)}>
                                                                                    {AnchorList.status2Icon(row["statusEnum"])}
                                                                                    {row["status"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_2`}
                                                                                    dataLabel={t(AnchorList.ANCHOR_COLUMNS[2].key)}>
                                                                                    {row["hardwareVersion"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_3`}
                                                                                    dataLabel={t(AnchorList.ANCHOR_COLUMNS[3].key)}>
                                                                                    {row["name"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_4`}
                                                                                    dataLabel={t(AnchorList.ANCHOR_COLUMNS[4].key)}>
                                                                                    {row["customer"]}
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                                }
                                                            </Tbody>
                                                        </TableComposable>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey={1}
                                                     title={<>
                                                         <TabTitleIcon><PlusIcon/></TabTitleIcon>
                                                         <TabTitleText>{t("fe_anchors_register")}</TabTitleText>
                                                     </>}>
                                                    {
                                                        this.state.successMessage ?
                                                            <Alert
                                                                isInline
                                                                className="TopSpacer"
                                                                variant="success"
                                                                title={this.state.successMessage}
                                                            /> : false
                                                    }
                                                    <Form>
                                                        <Flex>
                                                            <FlexItem style={{width: '400px'}}>
                                                                <Title
                                                                    headingLevel="h3"
                                                                    size={TitleSizes['2xl']}
                                                                    className="TopSpacer"
                                                                >
                                                                    {t("fe_common_generic-details")}
                                                                </Title>
                                                                <Switch
                                                                    id="register-many"
                                                                    label={t("anchor_create_many")}
                                                                    isChecked={this.state.registerMany}
                                                                    onChange={this.onChangeRegisterMany}
                                                                    className="TopSpacer"
                                                                />
                                                                <FormGroup
                                                                    label={t(this.state.registerMany ? "anchor_from_number" : "fe_common_seq-number")}
                                                                    fieldId="from-number"
                                                                    className="TopSpacer"
                                                                    isRequired={this.state.registerMany}
                                                                >
                                                                    <TextInput
                                                                        type="number"
                                                                        id="from-number"
                                                                        name="from-number"
                                                                        value={this.state.registerFromNumber}
                                                                        placeholder={!this.state.registerMany ? t("fe_common_auto-on-empty-placeholder") : ""}
                                                                        onChange={this.onChangeFromNumber}
                                                                    />
                                                                </FormGroup>
                                                                {
                                                                    this.state.registerMany &&
                                                                    <FormGroup
                                                                        label={t("anchor_until_number")}
                                                                        fieldId="until-number"
                                                                        className="TopSpacer"
                                                                        isRequired
                                                                    >
                                                                        <TextInput
                                                                            type="number"
                                                                            id="until-number"
                                                                            name="until-number"
                                                                            value={this.state.registerUntilNumber}
                                                                            onChange={this.onChangeUntilNumber}
                                                                        />
                                                                    </FormGroup>
                                                                }
                                                                <FormGroup
                                                                    label={t("anchor_prop_date-of-manufacture")}
                                                                    isRequired
                                                                    fieldId="manufactured-at"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Popover
                                                                        position="bottom"
                                                                        bodyContent={
                                                                            <CalendarMonth
                                                                                locale={t("language")}
                                                                                date={this.state.registerManufacturedAt}
                                                                                onChange={(value) => this.onSelectCalendar(value, t)}
                                                                            />
                                                                        }
                                                                        showClose={false}
                                                                        isVisible={this.state.calendarOpen}
                                                                        hasNoPadding
                                                                        hasAutoWidth
                                                                    >
                                                                        <InputGroup>
                                                                            <TextInput
                                                                                type="text"
                                                                                id="manufactured-at"
                                                                                name="manufactured-at"
                                                                                isReadOnly
                                                                                value={this.state.registerManufacturedAtFormatted || t("fe_common_select-placeholder")}
                                                                            />
                                                                            <Button variant="control"
                                                                                    onClick={this.onToggleCalendar}>
                                                                                <OutlinedCalendarAltIcon/>
                                                                            </Button>
                                                                        </InputGroup>
                                                                    </Popover>
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("anchor_prop_charge")}
                                                                    isRequired
                                                                    fieldId="charge"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="number"
                                                                        id="charge"
                                                                        name="charge"
                                                                        value={this.state.registerCharge}
                                                                        onChange={this.onChangeCharge}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("anchor_prop_hardware-version")}
                                                                    isRequired
                                                                    fieldId="hardware-version"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectHardwareVersion}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="hardware-version"
                                                                                onToggle={this.onToggleHardwareVersionDropDown}
                                                                                style={{width: "500px"}}>
                                                                                {this.state.registerHardwareVersion || t("fe_common_select-placeholder")}
                                                                            </DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.hardwareVersionDropDownOpen}
                                                                        dropdownItems={this.state.knownHardwareVersions.map((version, index) =>
                                                                            <DropdownItem key={index} component="button"
                                                                                          onClick={() => this.onChangeHardwareVersion(version)}>{version}</DropdownItem>)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("anchor_prop_article-number")}
                                                                    fieldId="article-number"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="text"
                                                                        id="article-number"
                                                                        name="article-number"
                                                                        value={this.state.registerArticleNumber}
                                                                        onChange={this.onChangeArticleNumber}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("fe_anchor-req_total-length-with-unit")}
                                                                    isRequired
                                                                    fieldId="total-length"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="number"
                                                                        id="total-length"
                                                                        name="total-length"
                                                                        value={this.state.registerTotalLength}
                                                                        onChange={this.onChangeTotalLength}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("anchor_prop_diameter")}
                                                                    isRequired
                                                                    fieldId="diameter"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectDiameter}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="diameter"
                                                                                onToggle={this.onToggleDiameterDropDown}
                                                                                style={{width: "500px"}}>{this.state.registerDiameter || t("fe_common_select-placeholder")}</DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.diameterDropDownOpen}
                                                                        dropdownItems={[
                                                                            <DropdownItem key="16" component="button"
                                                                                          onClick={() => this.onChangeDiameter("16")}>M16</DropdownItem>,
                                                                            <DropdownItem key="20" component="button"
                                                                                          onClick={() => this.onChangeDiameter("20")}>M20</DropdownItem>,
                                                                            <DropdownItem key="24" component="button"
                                                                                          onClick={() => this.onChangeDiameter("24")}>M24</DropdownItem>,
                                                                            <DropdownItem key="27" component="button"
                                                                                          onClick={() => this.onChangeDiameter("27")}>M27</DropdownItem>,
                                                                            <DropdownItem key="30" component="button"
                                                                                          onClick={() => this.onChangeDiameter("30")}>M30</DropdownItem>
                                                                        ]}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("anchor_prop_material")}
                                                                    isRequired
                                                                    fieldId="material"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectMaterial}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="material"
                                                                                onToggle={this.onToggleMaterialDropDown}
                                                                                style={{width: "500px"}}>{this.state.registerMaterial || t("fe_common_select-placeholder")}</DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.materialDropDownOpen}
                                                                        dropdownItems={[
                                                                            <DropdownItem key="R" component="button"
                                                                                          onClick={() => this.onChangeMaterial("R")}>R</DropdownItem>,
                                                                            <DropdownItem key="X" component="button"
                                                                                          onClick={() => this.onChangeMaterial("X")}>X</DropdownItem>,
                                                                            <DropdownItem key="T" component="button"
                                                                                          onClick={() => this.onChangeMaterial("T")}>T</DropdownItem>,
                                                                            <DropdownItem key="GVZ" component="button"
                                                                                          onClick={() => this.onChangeMaterial("GVZ")}>GVZ</DropdownItem>,
                                                                            <DropdownItem key="HCR" component="button"
                                                                                          onClick={() => this.onChangeMaterial("HCR")}>HCR</DropdownItem>
                                                                        ]}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("anchor_prop_sensor-position-with-unit")}
                                                                    fieldId="sensor-position"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="number"
                                                                        id="sensor-position"
                                                                        name="sensor-position"
                                                                        value={this.state.registerSensorPosition}
                                                                        onChange={this.onChangeSensorPosition}
                                                                        placeholder="0..1000"
                                                                    />
                                                                </FormGroup>
                                                            </FlexItem>
                                                            { !this.state.registerMany && (
                                                                <>
                                                                    <Divider isVertical/>
                                                                    <FlexItem style={{width: '400px'}}>
                                                                        <Title headingLevel="h3" size={TitleSizes['2xl']}
                                                                               className="TopSpacer">{t("fe_anchor-req_calibration")}</Title>
                                                                        <Hint className="TopSpacer">
                                                                            <HintBody>{t("fe_anchor-req_calibration-hint")}</HintBody>
                                                                        </Hint>
                                                                        <FormGroup
                                                                            label={t("fe_anchor-req_signal1-with-unit")}
                                                                            fieldId="signal-bias-1"
                                                                            className="TopSpacer"
                                                                        >
                                                                            <TextInput
                                                                                type="number"
                                                                                id="signal-bias-1"
                                                                                name="signal-bias-1"
                                                                                value={this.state.registerSignalBias1}
                                                                                onChange={this.onChangeSignalBias1}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup
                                                                            label={t("fe_anchor-req_load1-with-unit")}
                                                                            fieldId="force-bias-1"
                                                                            className="TopSpacer"
                                                                        >
                                                                            <TextInput
                                                                                type="number"
                                                                                id="force-bias-1"
                                                                                name="force-bias-1"
                                                                                value={this.state.registerForceBias1}
                                                                                onChange={this.onChangeForceBias1}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup
                                                                            label={t("fe_anchor-req_signal2-with-unit")}
                                                                            fieldId="signal-bias-2"
                                                                            className="TopSpacer"
                                                                        >
                                                                            <TextInput
                                                                                type="number"
                                                                                id="signal-bias-2"
                                                                                name="signal-bias-2"
                                                                                value={this.state.registerSignalBias2}
                                                                                onChange={this.onChangeSignalBias2}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup
                                                                            label={t("fe_anchor-req_load2-with-unit")}
                                                                            fieldId="force-bias-2"
                                                                            className="TopSpacer"
                                                                        >
                                                                            <TextInput
                                                                                type="number"
                                                                                id="force-bias-2"
                                                                                name="force-bias-2"
                                                                                value={this.state.registerForceBias2}
                                                                                onChange={this.onChangeForceBias2}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup
                                                                            label={t("fe_anchor-req_temperature-offset-with-unit")}
                                                                            fieldId="temperature-offset"
                                                                            className="TopSpacer"
                                                                        >
                                                                            <TextInput
                                                                                type="number"
                                                                                id="temperature-offset"
                                                                                name="temperature-offset"
                                                                                value={this.state.registerTemperatureOffset}
                                                                                onChange={this.onChangeTemperatureOffset}
                                                                            />
                                                                        </FormGroup>
                                                                    </FlexItem>
                                                                </>)
                                                            }
                                                        </Flex>
                                                        <ActionGroup>
                                                            {
                                                                this.state.registerInProgress ? <Spinner isSVG/> : (
                                                                    <Button
                                                                        isDisabled={this.isRegisterButtonDisabled()}
                                                                        variant="primary"
                                                                        onClick={() => this.state.registerMany ? this.onRegisterAnchors(t) : this.onRegisterAnchor(t)}
                                                                    >
                                                                        {t("fe_common_register")}
                                                                    </Button>
                                                                )
                                                            }
                                                        </ActionGroup>
                                                    </Form>
                                                </Tab>
                                                <Tab eventKey={2}
                                                     title={<>
                                                         <TabTitleIcon><ListIcon/></TabTitleIcon>
                                                         <TabTitleText>{t("fe_anchors_temperature-compensation-overview")}</TabTitleText>
                                                     </>}>
                                                    <Card component="div">
                                                        <TableComposable
                                                            aria-label="Coefficients"
                                                            variant="compact"
                                                            borders
                                                            isStickyHeader
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    {
                                                                        AnchorList.COEFFICIENT_COLUMNS.map((column, index) => {
                                                                            return <Th key={index}
                                                                                       width={column.width}>{column.label}</Th>
                                                                        })
                                                                    }
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    this.state.coefficients.map((row, rowIndex) => {
                                                                        return (
                                                                            <Tr isHoverable key={rowIndex}
                                                                                onRowClick={event => this.onCoefficientsClick(event, rowIndex)}
                                                                                isRowSelected={this.state.selectedCoefficientsIndex === rowIndex}>
                                                                                <Td key={`${rowIndex}_0`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[0].label}>
                                                                                    {row["anchorVersion"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_1`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[1].label}>
                                                                                    {row["a0"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_2`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[2].label}>
                                                                                    {row["a1"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_3`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[3].label}>
                                                                                    {row["a2"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_4`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[4].label}>
                                                                                    {row["a3"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_5`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[5].label}>
                                                                                    {row["a4"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_6`}
                                                                                    dataLabel={AnchorList.COEFFICIENT_COLUMNS[6].label}>
                                                                                    {row["a5"]}
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                                }
                                                            </Tbody>
                                                        </TableComposable>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey={3}
                                                     title={<>
                                                         <TabTitleIcon><PlusIcon/></TabTitleIcon>
                                                         <TabTitleText>{t("fe_anchors_new-hardware")}</TabTitleText>
                                                     </>}>
                                                    {
                                                        this.state.successMessage2 ?
                                                            <Alert
                                                                isInline
                                                                className="TopSpacer"
                                                                variant="success"
                                                                title={this.state.successMessage2}
                                                            /> : false
                                                    }
                                                    <Title
                                                        headingLevel="h3"
                                                        size={TitleSizes['2xl']}
                                                        className="TopSpacer"
                                                    >
                                                        {t("fe_anchors_add-hardware-hint")}
                                                    </Title>
                                                    <Hint className="TopSpacer">
                                                        <HintBody>
                                                            {t("fe_anchors_temp-comp-formulas")}:
                                                            <br/>
                                                            <var>K<sub>Temp</sub>(T<sub>Anchor</sub>) = a<sub>0</sub> + a<sub>1</sub> *
                                                                T<sub>Anchor</sub> + a<sub>2</sub> *
                                                                T<sub>Anchor</sub><sup>2</sup> + a<sub>3</sub> *
                                                                T<sub>Anchor</sub><sup>3</sup> + a<sub>4</sub> *
                                                                T<sub>Anchor</sub><sup>4</sup> + a<sub>5</sub> *
                                                                T<sub>Anchor</sub><sup>5</sup></var><br/>
                                                            <var>K<sub>AnchorT</sub> = K<sub>Anchor</sub> +
                                                                K<sub>Temp</sub></var>
                                                        </HintBody>
                                                    </Hint>
                                                    <Form style={{width: '400px'}}>
                                                        <FormGroup label={t("anchor_prop_hardware-version")} isRequired
                                                                   fieldId="hardware-version" className="TopSpacer">
                                                            <TextInput type="text" id="hardware-version"
                                                                       name="hardware-version"
                                                                       value={this.state.addHardwareVersion}
                                                                       onChange={this.onChangeAddHardwareVersion}/>
                                                        </FormGroup>
                                                        <FormGroup label="a₀" isRequired fieldId="a0"
                                                                   className="TopSpacer">
                                                            <TextInput type="number" id="a0"
                                                                       name="a0"
                                                                       value={this.state.addA0}
                                                                       onChange={this.onChangeAddA0}/>
                                                        </FormGroup>
                                                        <FormGroup label="a₁" isRequired fieldId="a1"
                                                                   className="TopSpacer">
                                                            <TextInput type="number" id="a1"
                                                                       name="a1"
                                                                       value={this.state.addA1}
                                                                       onChange={this.onChangeAddA1}/>
                                                        </FormGroup>
                                                        <FormGroup label="a₂" isRequired fieldId="a2"
                                                                   className="TopSpacer">
                                                            <TextInput type="number" id="a2"
                                                                       name="a2"
                                                                       value={this.state.addA2}
                                                                       onChange={this.onChangeAddA2}/>
                                                        </FormGroup>
                                                        <FormGroup label="a₃" isRequired fieldId="a3"
                                                                   className="TopSpacer">
                                                            <TextInput type="number" id="a3"
                                                                       name="a3"
                                                                       value={this.state.addA3}
                                                                       onChange={this.onChangeAddA3}/>
                                                        </FormGroup>
                                                        <FormGroup label="a₄" isRequired fieldId="a4"
                                                                   className="TopSpacer">
                                                            <TextInput type="number" id="a4"
                                                                       name="a4"
                                                                       value={this.state.addA4}
                                                                       onChange={this.onChangeAddA4}/>
                                                        </FormGroup>
                                                        <FormGroup label="a₅" isRequired fieldId="a5"
                                                                   className="TopSpacer">
                                                            <TextInput type="number" id="a5"
                                                                       name="a5"
                                                                       value={this.state.addA5}
                                                                       onChange={this.onChangeAddA5}/>
                                                        </FormGroup>
                                                        <ActionGroup>
                                                            {
                                                                this.state.addInProgress ? <Spinner isSVG/> : (
                                                                    <Button
                                                                        isDisabled={this.isAddHardwareButtonDisabled()}
                                                                        variant="primary"
                                                                        onClick={this.onAddHardware}>{t("fe_common_add")}</Button>
                                                                )
                                                            }
                                                        </ActionGroup>
                                                    </Form>
                                                </Tab>
                                            </Tabs>
                                        </PageSection>
                                    </PageGroup>
                                </DrawerContentBody>
                            </DrawerContent>
                        </Drawer>
                        {
                            this.state.activeTabKey === 2 && this.state.selectedCoefficientsIndex !== -1 && this.renderEditCoefficientsModal(t)
                        }
                        <a id="id_save_as" style={{display: 'none', visibility: 'hidden'}} href="/#">save-as</a>
                    </>
                }
            </Translation>
        )
    }

    renderEditCoefficientsModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_anchors_edit-coeffs")}
                isOpen={this.state.selectedCoefficientsIndex !== -1}
                onClose={this.closeEditCoefficientsModal}
                actions={[
                    <Button key="confirm" variant="primary" form="modal-with-form-form" onClick={() => this.confirmEditCoefficientsModal(false)}>{t("fe_common_save")}</Button>
                ]}
            >
                <Form id="modal-with-form-form" onSubmit={(event) => this.confirmEditCoefficientsModal(event, t)}>
                    <FormGroup label={t("anchor_prop_hardware-version")} fieldId="hardware-version" className="TopSpacer">
                        <TextInput type="text" id="hardware-version" isReadOnly
                                   name="hardware-version"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].anchorVersion}/>
                    </FormGroup>
                    <FormGroup label="a₀" isRequired fieldId="a0" className="TopSpacer">
                        <TextInput type="number" id="a0"
                                   name="a0"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].a0}
                                   onChange={value => this.onChangeCoefficient("a0", value)} />
                    </FormGroup>
                    <FormGroup label="a₁" isRequired fieldId="a1" className="TopSpacer">
                        <TextInput type="number" id="a1"
                                   name="a1"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].a1}
                                   onChange={value => this.onChangeCoefficient("a1", value)} />
                    </FormGroup>
                    <FormGroup label="a₂" isRequired fieldId="a2" className="TopSpacer">
                        <TextInput type="number" id="a2"
                                   name="a2"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].a2}
                                   onChange={value => this.onChangeCoefficient("a2", value)} />
                    </FormGroup>
                    <FormGroup label="a₃" isRequired fieldId="a3" className="TopSpacer">
                        <TextInput type="number" id="a3"
                                   name="a3"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].a3}
                                   onChange={value => this.onChangeCoefficient("a3", value)} />
                    </FormGroup>
                    <FormGroup label="a₄" isRequired fieldId="a4" className="TopSpacer">
                        <TextInput type="number" id="a4"
                                   name="a4"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].a4}
                                   onChange={value => this.onChangeCoefficient("a4", value)} />
                    </FormGroup>
                    <FormGroup label="a₅" isRequired fieldId="a5" className="TopSpacer">
                        <TextInput type="number" id="a5"
                                   name="a5"
                                   value={this.state.coefficients[this.state.selectedCoefficientsIndex].a5}
                                   onChange={value => this.onChangeCoefficient("a5", value)} />
                    </FormGroup>
                </Form>
            </Modal>
        )
    }

    closeEditCoefficientsModal() {
        this.setState({selectedCoefficientsIndex: -1})
    }

    onChangeCoefficient(identifier, value) {
        this.setState(prevState => {
            prevState.coefficients[prevState.selectedCoefficientsIndex][identifier] = value
            return {
                coefficients: prevState.coefficients
            }
        })
    }

    confirmEditCoefficientsModal(event) {
        event && event.preventDefault()
        const coefficients = this.state.coefficients[this.state.selectedCoefficientsIndex]
        const requestInit = {
            cache: "no-cache",
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                a0: coefficients.a0,
                a1: coefficients.a1,
                a2: coefficients.a2,
                a3: coefficients.a3,
                a4: coefficients.a4,
                a5: coefficients.a5
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/temperatureCompensationCoefficients/${coefficients.anchorVersion}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({
                        selectedCoefficientsIndex: -1
                    }, () => {
                        this.loadCompensationCoefficients()
                    })
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onSort(event, index, direction) {
        this.setState(prevState => {
            return {
                selectedAnchorIndex: -1,
                activeSortIndex: index,
                activeSortDirection: direction,
                anchors: AnchorList.sortAnchors(AnchorList.filterAnchors(this.anchors, prevState.filter), index, direction)
            }
        })
    }

    onAnchorClick(event, rowIndex) {
        this.setState(prevState => {
            return {
                selectedAnchorIndex: prevState.selectedAnchorIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    onCoefficientsClick(event, rowIndex) {
        this.setState(prevState => {
            return {
                selectedCoefficientsIndex: prevState.selectedCoefficientsIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    onDrawerCloseClick() {
        this.setState({selectedAnchorIndex: -1})
    }

    onTabSelect(event, tabIndex) {
        this.setState({
            activeTabKey: tabIndex,
            selectedAnchorIndex: -1,
            selectedCoefficientsIndex: -1
        })
        if (tabIndex === 0) {
            this.loadAnchorList()
            this.refreshTimerId = window.setInterval(this.refreshAnchorList, 60 * 1_000)
        }  else {
            if (this.refreshTimerId !== false) {
                window.clearInterval(this.refreshTimerId)
                this.refreshTimerId = false
            }
            if (tabIndex === 1) {
                this.loadKnownHardwareVersions()
            } else if (tabIndex === 2) {
                this.loadCompensationCoefficients()
            }
        }
    }

    onToggleCalendar() {
        this.setState(prevState => {
            return {
                calendarOpen: !prevState.calendarOpen
            }
        })
    }

    onSelectCalendar(newValueDate, t) {
        this.setState({
            registerManufacturedAt: newValueDate,
            registerManufacturedAtFormatted: formatDate(newValueDate, t),
            calendarOpen: false
        })
    };

    onChangeRegisterMany(value) {
        this.setState({registerMany: value})
    }

    onChangeFromNumber(value) {
        if (value === "" || value > 0) {
            this.setState({registerFromNumber: value})
        }
    }

    onChangeUntilNumber(value) {
        if (value === "" || value > 0) {
            this.setState({registerUntilNumber: value})
        }
    }

    onChangeCharge(value) {
        if (value > 0) {
            this.setState({registerCharge: value})
        }
    }

    onSelectHardwareVersion() {
        this.setState({
            hardwareVersionDropDownOpen: false
        })
    }

    onToggleHardwareVersionDropDown() {
        this.setState(prevState => {
            return {
                hardwareVersionDropDownOpen: !prevState.hardwareVersionDropDownOpen
            }
        })
    }

    onChangeHardwareVersion(value) {
        this.setState({registerHardwareVersion: value})
    }

    onChangeArticleNumber(value) {
        this.setState({registerArticleNumber: value})
    }

    onChangeTotalLength(value) {
        if (value > 0) {
            this.setState({registerTotalLength: value})
        }
    }

    onSelectDiameter() {
        this.setState({
            diameterDropDownOpen: false
        })
    }

    onToggleDiameterDropDown() {
        this.setState(prevState => {
            return {
                diameterDropDownOpen: !prevState.diameterDropDownOpen
            }
        })
    }

    onChangeDiameter(value) {
        if (value > 0) {
            this.setState({registerDiameter: value})
        }
    }

    onSelectMaterial() {
        this.setState({
            materialDropDownOpen: false
        })
    }

    onToggleMaterialDropDown() {
        this.setState(prevState => {
            return {
                materialDropDownOpen: !prevState.materialDropDownOpen
            }
        })
    }

    onChangeMaterial(value) {
        this.setState({registerMaterial: value})
    }

    onChangeSensorPosition(value) {
        this.setState({registerSensorPosition: value})
    }

    onChangeSignalBias1(value) {
        this.setState({registerSignalBias1: value})
    }

    onChangeSignalBias2(value) {
        this.setState({registerSignalBias2: value})
    }

    onChangeForceBias1(value) {
        this.setState({registerForceBias1: value})
    }

    onChangeForceBias2(value) {
        this.setState({registerForceBias2: value})
    }

    onChangeTemperatureOffset(value) {
        this.setState({registerTemperatureOffset: value})
    }

    isRegisterButtonDisabled() {
        const sequenceNumbersValid = !this.state.registerMany || (
            this.state.registerFromNumber !== "" &&
            this.state.registerUntilNumber !== ""
        )
        const calibrationValid = this.state.registerMany || (
            this.state.registerSignalBias1 === "" &&
            this.state.registerSignalBias2 === "" &&
            this.state.registerForceBias1 === "" &&
            this.state.registerForceBias2 === "" &&
            this.state.registerTemperatureOffset === ""
        ) || (
            this.state.registerSignalBias1 !== "" &&
            this.state.registerSignalBias2 !== "" &&
            this.state.registerForceBias1 !== "" &&
            this.state.registerForceBias2 !== "" &&
            this.state.registerTemperatureOffset !== "" &&
            this.state.registerSignalBias1 !== this.state.registerSignalBias2 &&
            this.state.registerForceBias1 !== this.state.registerForceBias2
        )
        return !sequenceNumbersValid ||
            this.state.registerManufacturedAt === null ||
            this.state.registerCharge === "" ||
            this.state.registerMaterial === "" ||
            this.state.registerDiameter === "" ||
            this.state.registerTotalLength === "" ||
            this.state.registerHardwareVersion === "" ||
            !calibrationValid
    }

    onRegisterAnchor(t) {
        this.setState({registerInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                number: this.state.registerFromNumber ? this.state.registerFromNumber : null,
                version: this.state.registerHardwareVersion,
                manufacturedAt: date2ISOString(this.state.registerManufacturedAt),
                charge: this.state.registerCharge,
                articleNumber: this.state.registerArticleNumber !== "" ? this.state.registerArticleNumber : null,
                material: this.state.registerMaterial,
                totalLength: this.state.registerTotalLength,
                diameter: this.state.registerDiameter,
                sensorPosition: this.state.registerSensorPosition ? this.state.registerSensorPosition : null,
                signalBias1: this.state.registerSignalBias1 ? this.state.registerSignalBias1 : null,
                signalBias2: this.state.registerSignalBias2 ? this.state.registerSignalBias2 : null,
                forceBias1: this.state.registerForceBias1 ? this.state.registerForceBias1 : null,
                forceBias2: this.state.registerForceBias2 ? this.state.registerForceBias2 : null,
                temperatureOffset: this.state.registerTemperatureOffset ? this.state.registerTemperatureOffset : null
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/register`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 201) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload =>
                        this.setState({
                            registerInProgress: false,
                            successMessage: t("fe_anchors_reg-success").replace("%", payload.serialNumber),
                            registerFromNumber: "",
                            registerManufacturedAt: null,
                            registerManufacturedAtFormatted: "",
                            registerCharge: "",
                            registerHardwareVersion: "",
                            registerArticleNumber: "",
                            registerTotalLength: "",
                            diameterDropDownOpen: false,
                            registerDiameter: "",
                            materialDropDownOpen: false,
                            registerMaterial: "",
                            registerSensorPosition: "",
                            registerSignalBias1: "",
                            registerSignalBias2: "",
                            registerForceBias1: "",
                            registerForceBias2: "",
                            registerTemperatureOffset: ""
                        })
                    )
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({registerInProgress: false}))
    }

    onRegisterAnchors(t) {
        this.setState({registerInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                numberFrom: this.state.registerFromNumber,
                numberUntil: this.state.registerUntilNumber,
                version: this.state.registerHardwareVersion,
                manufacturedAt: date2ISOString(this.state.registerManufacturedAt),
                charge: this.state.registerCharge,
                articleNumber: this.state.registerArticleNumber !== "" ? this.state.registerArticleNumber : null,
                material: this.state.registerMaterial,
                totalLength: this.state.registerTotalLength,
                diameter: this.state.registerDiameter,
                sensorPosition: this.state.registerSensorPosition ? this.state.registerSensorPosition : null
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/register/many`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 201) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload =>
                        this.setState({
                            registerInProgress: false,
                            successMessage: t("fe_anchors_reg-success").replace("%", payload["serialNumbers"].join(", ")),
                            registerFromNumber: "",
                            registerUntilNumber: "",
                            registerManufacturedAt: null,
                            registerManufacturedAtFormatted: "",
                            registerCharge: "",
                            registerHardwareVersion: "",
                            registerArticleNumber: "",
                            registerTotalLength: "",
                            diameterDropDownOpen: false,
                            registerDiameter: "",
                            materialDropDownOpen: false,
                            registerMaterial: "",
                            registerSensorPosition: ""
                        })
                    )
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({registerInProgress: false}))
    }

    filterClicked(isSelected, event) {
        const id = event.currentTarget.id
        this.setState(prevState => {
            prevState.filter[id] = isSelected
            const anchors = AnchorList.sortAnchors(AnchorList.filterAnchors(this.anchors, prevState.filter), prevState.activeSortIndex, prevState.activeSortDirection)
            return {
                selectedAnchorIndex: anchors.length === 1 ? 0 : -1,
                filter: prevState.filter,
                anchors: anchors,
                testFirmware: false
            }
        })
    }

    onChangeSearchString(value) {
        this.setState(prevState => {
            prevState.filter.searchString = value
            const anchors = AnchorList.sortAnchors(AnchorList.filterAnchors(this.anchors, prevState.filter), prevState.activeSortIndex, prevState.activeSortDirection)
            return {
                selectedAnchorIndex: anchors.length === 1 ? 0 : -1,
                filter: prevState.filter,
                anchors: anchors,
                testFirmware: false
            }
        })
    }

    loadCompensationCoefficients() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/temperatureCompensationCoefficients`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => {
                        this.setState({
                            coefficients: payload,
                            selectedCoefficientsIndex: -1
                        })
                    })
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onChangeAddHardwareVersion(value) {
        this.setState({addHardwareVersion: value})
    }

    onChangeAddA0(value) {
        if (value !== "") {
            this.setState({addA0: value})
        }
    }

    onChangeAddA1(value) {
        if (value !== "") {
            this.setState({addA1: value})
        }
    }

    onChangeAddA2(value) {
        if (value !== "") {
            this.setState({addA2: value})
        }
    }

    onChangeAddA3(value) {
        if (value !== "") {
            this.setState({addA3: value})
        }
    }

    onChangeAddA4(value) {
        if (value !== "") {
            this.setState({addA4: value})
        }
    }

    onChangeAddA5(value) {
        if (value !== "") {
            this.setState({addA5: value})
        }
    }

    isAddHardwareButtonDisabled() {
        return this.state.addHardwareVersion === ""
    }

    onAddHardware() {
        this.setState({addInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                anchorVersion: this.state.addHardwareVersion,
                a0: this.state.addA0,
                a1: this.state.addA1,
                a2: this.state.addA2,
                a3: this.state.addA3,
                a4: this.state.addA4,
                a5: this.state.addA5
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/temperatureCompensationCoefficients`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 201) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({activeTabKey: 2}, () => this.loadCompensationCoefficients())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({addInProgress: false}))
    }

    loadKnownHardwareVersions() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/versions`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => this.setState({knownHardwareVersions: payload.versions}))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    reloadAnchorDetails() {
        this.setState(prevState =>{
            return {reloadTrigger: !prevState.reloadTrigger}
        })
    }
}

export default withRouter(AnchorList)