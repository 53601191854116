import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    Nav,
    NavItem,
    NavList
} from "@patternfly/react-core";

import { AppSettings } from "./AppSettings";

class SideNavigation extends Component {
    render() {
        const location = this.props["location"]
        return (
            <Translation>
                { t =>
                    <Nav>
                        <NavList>
                            <NavItem id="dashboard" itemId={0}
                                     isActive={location.pathname === `${AppSettings.rootPath}/`}>
                                <Link to={`${AppSettings.rootPath}/`}>
                                    {t("fe_nav_dashboard")}
                                </Link>
                            </NavItem>
                            <NavItem id="sensor-gateways" itemId={1}
                                     isActive={location.pathname === `${AppSettings.rootPath}/sensorGateways`}>
                                <Link to={`${AppSettings.rootPath}/sensorGateways`}>
                                    {t("fe_nav_gateways")}
                                </Link>
                            </NavItem>
                            <NavItem id="sensor-anchors" itemId={2}
                                isActive={location.pathname === `${AppSettings.rootPath}/sensorAnchors`}>
                                <Link to={`${AppSettings.rootPath}/sensorAnchors`}>
                                    {t("fe_nav_anchors")}
                                </Link>
                            </NavItem>
                            <NavItem id="sensor-disc" itemId={3}
                                isActive={location.pathname === `${AppSettings.rootPath}/sensorDisc`}>
                                <Link to={`${AppSettings.rootPath}/sensorDisc`}>
                                    {t("fe_nav_disc")}
                                </Link>
                            </NavItem>
                            <NavItem id="sim-cards" itemId={4}
                                isActive={location.pathname === `${AppSettings.rootPath}/simCards`}>
                                <Link to={`${AppSettings.rootPath}/simCards`}>
                                    {t("fe_nav_sim-cards")}
                                </Link>
                            </NavItem>
                            <NavItem id="firmware-management" itemId={5}
                                isActive={location.pathname === `${AppSettings.rootPath}/firmware`}>
                                <Link to={`${AppSettings.rootPath}/firmware`}>
                                    {t("fe_nav_firmware")}
                                </Link>
                            </NavItem>
                            <NavItem id="simulator" itemId={6}
                                isActive={location.pathname === `${AppSettings.rootPath}/simulator`}>
                                <Link to={`${AppSettings.rootPath}/simulator`}>
                                    {t("fe_nav_simulator")}
                                </Link>
                            </NavItem>
                            {
                                this.props.app["role"] === "Admin" && (
                                    <NavItem id="user-management" itemId={7}
                                        isActive={location.pathname === `${AppSettings.rootPath}/users`}>
                                        <Link to={`${AppSettings.rootPath}/users`}>
                                            {t("fe_nav_user")}
                                        </Link>
                                    </NavItem>
                                )
                            }
                        </NavList>
                    </Nav>
                }
            </Translation>
        )
    }
}

export default withRouter(SideNavigation)