const ProdAppSettings = {
    rootPath: "/iot-backoffice",
    simulateTelemetry: false,
    profile: "prod"
}

const TestAppSettings = {
    rootPath: "",
    simulateTelemetry: false,
    profile: "test"
}

const DevAppSettings = {
    rootPath: "",
    simulateTelemetry: false,
    profile: "dev"
}

export const AppSettings = process.env.REACT_APP_QUARKUS_PROFILE === 'prod' ? ProdAppSettings : process.env.REACT_APP_QUARKUS_PROFILE === 'sit' ? TestAppSettings : DevAppSettings