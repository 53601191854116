import i18n from "i18next";
import {
    format,
    isSameDay,
    isSameMonth,
    isSameYear
} from "date-fns";
import { de, enUS, fr } from "date-fns/locale";

export function saveDataToFile(data, fileName, mediaType, elementId = "id_save_as") {
    const url = URL.createObjectURL(new Blob([data], {type: mediaType}))
    const el = document.getElementById(elementId)
    el.href = url
    el.download = fileName
    el.click()
    window.URL.revokeObjectURL(url)
}

export function date2ISOString(dateTime) {
    function format(m) {
        let f = new Intl.DateTimeFormat('en', m)
        return f.format(dateTime)
    }

    return [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}].map(format).join('-')
}

export function formatNumber(value, decimals, t) {
    if ((value | 0) < value) {
        return new Intl.NumberFormat(t("numbers_locale"), {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }).format(value)
    } else {
        return new Intl.NumberFormat(t("numbers_locale"), {
            maximumFractionDigits: 0
        }).format(value)
    }
}

export function formatTime(millis, t) {
    return format(new Date(millis), t("time_pattern"), {locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS})
}

export function formatDate(millis, t) {
    return format(new Date(millis), t("date_pattern"), {locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS})
}

export function formatDateTime(millis, t) {
    return format(new Date(millis), t("datetime_pattern"), {locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS})
}

export function formatDateTimeForChartAxis(millis, t) {
    return format(new Date(millis), t("datetime_chart-axis_pattern"), {locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS})
}

function dp(parts, type) {
    return parts.find(p => p.type===type).value
}

export function formatDateRange(startDate, endDate) {
    const startDateParts = new Intl.DateTimeFormat(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    }).formatToParts(startDate);
    const endDateParts = new Intl.DateTimeFormat(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    }).formatToParts(endDate);

    if (isSameDay(startDate, endDate)) {
        return `${dp(startDateParts, "day")} ${dp(startDateParts, "month")} ${dp(startDateParts, "year")}`
    }
    if (isSameMonth(startDate, endDate)) {
        return `${dp(startDateParts, "day")}-${dp(endDateParts, "day")} ${dp(startDateParts, "month")} ${dp(startDateParts, "year")}`
    }
    if (isSameYear(startDate, endDate)) {
        return `${dp(startDateParts, "day")} ${dp(startDateParts, "month")}-${dp(endDateParts, "day")} ${dp(endDateParts, "month")} ${dp(startDateParts, "year")}`
    }
    return `${dp(startDateParts, "day")} ${dp(startDateParts, "month")} ${dp(startDateParts, "year")}-${dp(endDateParts, "day")} ${dp(endDateParts, "month")} ${dp(endDateParts, "year")}`
}
