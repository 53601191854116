import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    Alert,
    Button,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerActions,
    DrawerCloseButton,
    DrawerContentBody,
    DrawerHead,
    Dropdown,
    DropdownItem,
    DropdownPosition,
    DropdownSeparator,
    DropdownToggle,
    ExpandableSection,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    KebabToggle,
    Modal,
    ModalVariant,
    Text,
    TextInput,
    TextVariants,
    Title
} from "@patternfly/react-core";

import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    ExternalLinkSquareAltIcon,
    QuestionCircleIcon,
    SyncAltIcon
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";

import "./DetailsDrawer.css";
import CurrentForceTemperatureChart from "./CurrentForceTemperatureChart";
import ForceTemperatureChart from "./ForceTemperatureChart";
import streamSaver from "streamsaver";

class AnchorDetails extends Component {
    static HARDWARE_EXPANDABLE = [
        {key:"version", label:"anchor_prop_hardware-version"},
        {key:"manufacturedAt", label:"anchor_prop_date-of-manufacture"},
        {key:"registeredAt", label:"anchor_prop_date-of-reg"},
        {key:"chargeNumber", label:"anchor_prop_charge"},
        {key:"articleNumber", label:"anchor_prop_article-number"},
        {key:"material", label:"anchor_prop_material"},
        {key:"totalLength", label:"anchor_prop_total-length"},
        {key:"diameter", label:"anchor_prop_diameter"},
        {key:"sensorPosition", label:"anchor_prop_sensor-position"}
    ]

    static CALIBRATION_EXPANDABLE = [
        {key:"signalBias1", label:"anchor_prop_factory-cp1-signal"},
        {key:"forceBias1", label:"anchor_prop_factory-cp1-load"},
        {key:"signalBias2", label:"anchor_prop_factory-cp2-signal"},
        {key:"forceBias2", label:"anchor_prop_factory-cp2-load"},
        {key:"temperatureOffset", label:"anchor_prop_temperature-offset"}
    ]

    static CUSTOMER_EXPANDABLE = [
        {key:"name", label:"fe_common_name"},
        {key:"email", label:"fe_common_email"}
    ]

    static USER_EXPANDABLE = [
        {key:"gatewayId", label:"fe_gateway"},
        {key:"port", label:"fe_anchor_port"},
        {key:"name", label:"fe_anchor_name"},
        {key:"localizedMeasureInterval", label:"fe_common_measuring-interval"},
        {key:"environment", label:"fe_anchor_environment"},
        {key:"surface", label:"fe_anchor_surface"},
        {key:"torque", label:"fe_anchor_torque"},
        {key:"signalBias1", label:"anchor_prop_user-cp1-signal"},
        {key:"forceBias1", label:"anchor_prop_user-cp1-load"},
        {key:"signalBias2", label:"anchor_prop_user-cp2-signal"},
        {key:"forceBias2", label:"anchor_prop_user-cp2-load"},
        {key:"lastTaredAt", label:"fe_anchor_tared-at"},
        {key:"minForceThreshold", label:"fe_anchor_load-lower-threshold"},
        {key:"maxForceThreshold", label:"fe_anchor_load-upper-threshold"},
        {key:"minTemperatureThreshold", label:"fe_anchor_temperature-lower-threshold"},
        {key:"maxTemperatureThreshold", label:"fe_anchor_temperature-upper-threshold"},
        {key:"lastSignal", label:"fe_anchor_last-signal"}
    ]

    constructor(props) {
        super(props)

        this.state = {
            anchor: {},
            telemetryId: false,
            toggleMenu: false,
            expandedSections: {
                hardware: true,
                calibration: true,
                customer: true,
                forecasting: true,
                userSettings: true
            },
            calibrateInProgress: false,
            showCalibrationModal: false,
            calibrationSignalBias1: "",
            calibrationSignalBias2: "",
            calibrationForceBias1: "",
            calibrationForceBias2: "",
            calibrationTemperatureOffset: "",
            confirmResetAnchor: false,
            confirmRemoveAnchor: false,
            showForecastingModal: false,
            forecastDurationDropdownOpen: false,
            forecastingConfigInProgress: false,
            showHardwareVersionModal: false,
            editedHardwareVersion: false
        }

        this.loadDetails = this.loadDetails.bind(this)
        this.getStatusAlertIcon = this.getStatusAlertIcon.bind(this)
        this.getStatusAlertVariant = this.getStatusAlertVariant.bind(this)
        this.getStatus = this.getStatus.bind(this)
        this.renderHardwareExpandable = this.renderHardwareExpandable.bind(this)
        this.renderCalibrationExpandable = this.renderCalibrationExpandable.bind(this)
        this.renderUserSettingsExpandable = this.renderUserSettingsExpandable.bind(this)
        this.renderCustomerExpandable = this.renderCustomerExpandable.bind(this)
        this.renderHardwareVersionModal = this.renderHardwareVersionModal.bind(this)
        this.onDropDownMenuSelect = this.onDropDownMenuSelect.bind(this)
        this.onDropDownMenuToggle = this.onDropDownMenuToggle.bind(this)
        this.expandSection = this.expandSection.bind(this)
        this.showCalibrationModal = this.showCalibrationModal.bind(this)
        this.renderCalibrationModal = this.renderCalibrationModal.bind(this)
        this.closeCalibrationModal = this.closeCalibrationModal.bind(this)
        this.confirmCalibrationModal = this.confirmCalibrationModal.bind(this)
        this.onChangeCalibrationSignalBias1 = this.onChangeCalibrationSignalBias1.bind(this)
        this.onChangeCalibrationSignalBias2 = this.onChangeCalibrationSignalBias2.bind(this)
        this.onChangeCalibrationForceBias1 = this.onChangeCalibrationForceBias1.bind(this)
        this.onChangeCalibrationForceBias2 = this.onChangeCalibrationForceBias2.bind(this)
        this.onChangeCalibrationTemperatureOffset = this.onChangeCalibrationTemperatureOffset.bind(this)
        this.isCalibrationButtonDisabled = this.isCalibrationButtonDisabled.bind(this)
        this.showInGatewayList = this.showInGatewayList.bind(this)
        this.isExportButtonDisabled = this.isExportButtonDisabled.bind(this)
        this.exportAnchorTelemetryAsCsv = this.exportAnchorTelemetryAsCsv.bind(this)
        this.renderConfirmResetAnchorModal = this.renderConfirmResetAnchorModal.bind(this)
        this.confirmResetAnchor = this.confirmResetAnchor.bind(this)
        this.resetAnchor = this.resetAnchor.bind(this)
        this.closeConfirmResetAnchorModal = this.closeConfirmResetAnchorModal.bind(this)
        this.confirmRemoveAnchor = this.confirmRemoveAnchor.bind(this)
        this.renderConfirmRemoveAnchorModal = this.renderConfirmRemoveAnchorModal.bind(this)
        this.closeConfirmRemoveAnchorModal = this.closeConfirmRemoveAnchorModal.bind(this)
        this.removeAnchor = this.removeAnchor.bind(this)
        this.editForecasting = this.editForecasting.bind(this)
        this.renderForecastingModal = this.renderForecastingModal.bind(this)
        this.confirmForecastingModal = this.confirmForecastingModal.bind(this)
        this.disableForecasting = this.disableForecasting.bind(this)
        this.setAnchorVersionModal = this.setAnchorVersionModal.bind(this)
        this.setHardwareVersion = this.setHardwareVersion.bind(this)
    }

    componentDidMount() {
        this.loadDetails()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.serialNumber !== prevProps.serialNumber || this.props.reloadTrigger !== prevProps.reloadTrigger) {
            this.loadDetails()
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <>
                        <DrawerHead>
                            <Title headingLevel="h1">{t("anchor")}</Title>
                            <Text component={TextVariants.h6}>{this.props.serialNumber}</Text>
                            <DrawerActions>
                                <Button variant="plain" onClick={this.loadDetails}><SyncAltIcon/></Button>
                                <Dropdown
                                    className="GatewayMenu"
                                    position={DropdownPosition.right}
                                    onSelect={this.onDropDownMenuSelect}
                                    toggle={<KebabToggle onToggle={this.onDropDownMenuToggle} id="toggle-menu-anchor-details" />}
                                    isOpen={this.state.toggleMenu}
                                    isPlain
                                    dropdownItems={[
                                        <DropdownItem key="version" component="button" onClick={this.setAnchorVersionModal}>{t("fe_anchor_change-version")}</DropdownItem>,
                                        <DropdownItem key="calibrate" component="button" onClick={this.showCalibrationModal}>{t("fe_anchor_calibrate")}</DropdownItem>,
                                        <DropdownItem key="reset" component="button" onClick={this.confirmResetAnchor}>{t("fe_action_reset")}</DropdownItem>,
                                        <DropdownSeparator key="separator2" />,
                                        <DropdownItem key="export-csv" component="button" onClick={this.exportAnchorTelemetryAsCsv} isDisabled={this.isExportButtonDisabled()}>{t("fe_action_export-csv")}</DropdownItem>,
                                        <DropdownSeparator key="separator3" />,
                                        <DropdownItem key="remove" component="button" onClick={this.confirmRemoveAnchor}>{t("fe_action_remove")}</DropdownItem>
                                    ]}
                                />

                                <DrawerCloseButton onClick={this.props.closeDrawer}/>
                            </DrawerActions>
                        </DrawerHead>
                        <DrawerContentBody className="DetailsDrawer">
                            <Alert customIcon={this.getStatusAlertIcon()} variant={this.getStatusAlertVariant()} isInline
                                   title={this.getStatus(t)}/>
                            {
                                this.renderHardwareExpandable(t)
                            }
                            {
                                this.renderCalibrationExpandable(t)
                            }
                            {
                                this.renderCustomerExpandable(t)
                            }
                            {
                                this.renderUserSettingsExpandable(t)
                            }
                            {
                                this.state.showCalibrationModal && this.renderCalibrationModal(t)
                            }
                            {
                                this.state.confirmResetAnchor && this.renderConfirmResetAnchorModal(t)
                            }
                            {
                                this.state.confirmRemoveAnchor && this.renderConfirmRemoveAnchorModal(t)
                            }
                            {
                                this.state.showForecastingModal && this.renderForecastingModal(t)
                            }
                            {
                                this.state.showHardwareVersionModal && this.renderHardwareVersionModal(t)
                            }
                        </DrawerContentBody>
                    </>
                }
            </Translation>
        )
    }

    getStatusAlertIcon() {
        if (this.state.anchor) {
            switch (this.state.anchor["statusEnum"]) {
            case "IncompleteAnchorSettings":
            case "NotCalibrated":
                return <ExclamationTriangleIcon className="Warning"/>
            case "Operational":
                return <CheckCircleIcon className="Success"/>
            case "Error":
                return <ExclamationCircleIcon className="Danger"/>
            default:
                return <QuestionCircleIcon className="Unknown"/>
            }
        }
        return <QuestionCircleIcon/>
    }

    getStatusAlertVariant() {
        if (this.state.anchor) {
            switch (this.state.anchor["statusEnum"]) {
            case "Error":
                return "danger"
            case "Operational":
                return "success"
            case "IncompleteAnchorSettings":
            case "NotCalibrated":
                return "warning"
            default:
                return "info"
            }
        }
        return "default"
    }

    getStatus(t) {
        if (this.state.anchor) {
            return this.state.anchor["status"]
        }
        return t("gatewayStatusUnknown")
    }

    renderHardwareExpandable(t) {
        if (this.state.anchor.hardware) {
            const hardware = this.state.anchor.hardware
            return (
                <ExpandableSection toggleText={t("fe_gateway-section_hardware")} isExpanded={this.state.expandedSections.hardware}
                                   onToggle={(isExpanded) => this.expandSection("hardware", isExpanded)}>
                    <DataList isCompact aria-label="hardware">
                        {
                            AnchorDetails.HARDWARE_EXPANDABLE.map((item) => {
                                return (
                                    <DataListItem key={item.key}>
                                        <DataListItemRow>
                                            <DataListItemCells dataListCells={[
                                                <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                                <DataListCell key="value" isFilled={false}
                                                              alignRight className="Value">{hardware[item.key]}</DataListCell>
                                            ]}/>
                                        </DataListItemRow>
                                    </DataListItem>
                                )
                            })
                        }
                    </DataList>
                </ExpandableSection>
            )
        }
        return false
    }

    renderCalibrationExpandable(t) {
        if (this.state.anchor.calibration) {
            const calibration = this.state.anchor.calibration
            return (
                <ExpandableSection toggleText={t("fe_anchor-req_calibration")} isExpanded={this.state.expandedSections.calibration}
                                   onToggle={(isExpanded) => this.expandSection("calibration", isExpanded)}>
                    {
                        <DataList isCompact aria-label="calibration">
                            {
                                AnchorDetails.CALIBRATION_EXPANDABLE.map((item) => {
                                    return (
                                        <DataListItem key={item.key}>
                                            <DataListItemRow>
                                                <DataListItemCells dataListCells={[
                                                    <DataListCell key="label"
                                                                  isFilled={false}>{t(item.label)}</DataListCell>,
                                                    <DataListCell key="value" isFilled={false}
                                                                  alignRight
                                                                  className="Value">{calibration[item.key]}</DataListCell>
                                                ]}/>
                                            </DataListItemRow>
                                        </DataListItem>
                                    )
                                })
                            }
                        </DataList>
                    }
                </ExpandableSection>
            )
        }
        return false
    }

    renderCustomerExpandable(t) {
        if (this.state.anchor.customer) {
            const customer = this.state.anchor.customer
            return (
                <ExpandableSection toggleText={t("fe_common_customer")} isExpanded={this.state.expandedSections.customer}
                                   onToggle={(isExpanded) => this.expandSection("customer", isExpanded)}>
                    <DataList isCompact aria-label="customer">
                        {
                            AnchorDetails.CUSTOMER_EXPANDABLE.map((item) => {
                                return (
                                    <DataListItem key={item.key}>
                                        <DataListItemRow>
                                            <DataListItemCells dataListCells={[
                                                <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                                <DataListCell key="value" isFilled={false}
                                                              alignRight className="Value">{customer[item.key]}</DataListCell>
                                            ]}/>
                                        </DataListItemRow>
                                    </DataListItem>
                                )
                            })
                        }
                    </DataList>
                </ExpandableSection>
            )
        }
        return false
    }

    renderUserSettingsExpandable(t) {
        if (this.state.anchor.userSettings && this.state.telemetryId) {
            const userSettings = this.state.anchor.userSettings
            const haveMeasurements = userSettings["lastSignal"] !== ""
            const isExpanded = this.state.expandedSections.userSettings
            return (
                <ExpandableSection
                    toggleText={t("anchor_sections_user-settings")}
                    isExpanded={isExpanded}
                    onToggle={(isExpanded) => this.expandSection("userSettings", isExpanded)}
                >
                    {
                        isExpanded && haveMeasurements && <>
                            <CurrentForceTemperatureChart
                                t={t}
                                app={this.props.app}
                                force={userSettings["lastForce"]}
                                temperature={userSettings["lastTemperature"]}
                            />
                            <div className="TopExtraSpacer">
                                <ForceTemperatureChart
                                    t={t}
                                    app={this.props.app}
                                    telemetryId={this.state.telemetryId}
                                    reloadTrigger={`${this.state.telemetryId}:${this.props.reloadTrigger}`}
                                />
                            </div>
                        </>
                    }
                    <DataList isCompact aria-label="userSettings">
                        {
                            AnchorDetails.USER_EXPANDABLE.map((item) => {
                                return (
                                    <DataListItem key={item.key}>
                                        <DataListItemRow>
                                            <DataListItemCells dataListCells={[
                                                <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                                <DataListCell key="value" isFilled={false}
                                                              alignRight className="Value">
                                                    {userSettings[item.key]}
                                                    {item.key === "gatewayId" && <Button variant="link" isInline iconPosition="right" icon={<ExternalLinkSquareAltIcon />} onClick={() => this.showInGatewayList(userSettings[item.key])}/>}
                                                </DataListCell>
                                            ]}/>
                                        </DataListItemRow>
                                    </DataListItem>
                                )
                            })
                        }
                    </DataList>
                </ExpandableSection>
            )
        }
        return false
    }

    loadDetails() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/details/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({
                            anchor: data,
                            telemetryId: data.telemetryId || false
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onDropDownMenuSelect() {
        this.setState(prevState => {
            return {toggleMenu: !prevState.toggleMenu}
        });
        document.getElementById("toggle-menu-anchor-details").focus();
    }

    onDropDownMenuToggle(isOpen) {
        this.setState({toggleMenu: isOpen})
    }

    expandSection(sectionId, isExpanded) {
        this.setState(prevState => {
            prevState.expandedSections[sectionId] = isExpanded
            return {expandedSections: prevState.expandedSections}
        })
    }

    setAnchorVersionModal() {
        this.setState(prevState => {
            return {
                editedHardwareVersion: prevState.anchor.hardware["version"],
                showHardwareVersionModal: true
            }
        })
    }

    showCalibrationModal() {
        this.setState({showCalibrationModal: true})
    }

    renderCalibrationModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_anchor_calibrate")}
                isOpen={this.state.showCalibrationModal}
                onClose={this.closeCalibrationModal}
                actions={[
                    <Button
                        key="confirm"
                        variant="primary"
                        form="modal-with-form-form"
                        isDisabled={this.isCalibrationButtonDisabled()}
                        onClick={() => this.confirmCalibrationModal(false, t)}>
                        {t("fe_anchor_calibrate")}
                    </Button>
                ]}
            >
                <Form id="modal-with-form-form" onSubmit={(event) => this.confirmCalibrationModal(event, t)}>
                    <FormGroup label={t("fe_anchor-req_signal1-with-unit")} fieldId="signal-bias-1" className="TopSpacer">
                        <TextInput type="number" id="signal-bias-1"
                                   name="signal-bias-1"
                                   value={this.state.calibrationSignalBias1}
                                   onChange={this.onChangeCalibrationSignalBias1}/>
                    </FormGroup>
                    <FormGroup label={t("fe_anchor-req_load1-with-unit")} fieldId="force-bias-1" className="TopSpacer">
                        <TextInput type="number" id="force-bias-1"
                                   name="force-bias-1"
                                   value={this.state.calibrationForceBias1}
                                   onChange={this.onChangeCalibrationForceBias1}/>
                    </FormGroup>
                    <FormGroup label={t("fe_anchor-req_signal2-with-unit")} fieldId="signal-bias-2" className="TopSpacer">
                        <TextInput type="number" id="signal-bias-2"
                                   name="signal-bias-2"
                                   value={this.state.calibrationSignalBias2}
                                   onChange={this.onChangeCalibrationSignalBias2}/>
                    </FormGroup>
                    <FormGroup label={t("fe_anchor-req_load2-with-unit")} fieldId="force-bias-2" className="TopSpacer">
                        <TextInput type="number" id="force-bias-2"
                                   name="force-bias-2"
                                   value={this.state.calibrationForceBias2}
                                   onChange={this.onChangeCalibrationForceBias2}/>
                    </FormGroup>
                    <FormGroup label={t("fe_anchor-req_temperature-offset-with-unit")} fieldId="temperature-offset" className="TopSpacer">
                        <TextInput type="number" id="temperature-offset"
                                   name="temperature-offset"
                                   value={this.state.calibrationTemperatureOffset}
                                   onChange={this.onChangeCalibrationTemperatureOffset}/>
                    </FormGroup>
                </Form>
            </Modal>
        )
    }

    renderHardwareVersionModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_anchor_change-version")}
                isOpen={this.state.showHardwareVersionModal}
                onClose={() => this.setState({showHardwareVersionModal: false})}
                actions={[
                    <Button
                        key="confirm"
                        variant="primary"
                        form="modal-with-form-form"
                        onClick={() => this.setHardwareVersion(false)}>
                        {t("fe_anchor_change-version")}
                    </Button>
                ]}>
                <Form id="modal-with-form-form" onSubmit={event => this.setHardwareVersion(event)}>
                    <FormGroup label={t("anchor_prop_hardware-version")} fieldId="hardware-version" className="TopSpacer">
                        <TextInput type="text" id="hardware-version"
                                   name="hardware-version"
                                   value={this.state.editedHardwareVersion}
                                   onChange={value => this.setState({editedHardwareVersion: value})}/>
                    </FormGroup>
                </Form>
            </Modal>
        )
    }

    closeCalibrationModal() {
        this.setState({showCalibrationModal: false})
    }

    renderConfirmResetAnchorModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_confirm-title")}
                isOpen={true}
                onClose={this.closeConfirmResetAnchorModal}
                actions={[
                    <Button key="confirm" variant="primary" onClick={this.resetAnchor}>{t("fe_action_reset")}</Button>,
                    <Button key="cancel" variant="link" onClick={this.closeConfirmResetAnchorModal}>{t("fe_common_cancel")}</Button>
                ]}
            >
                {t("fe_gateway_reset-description")}
            </Modal>
        )
    }

    closeConfirmResetAnchorModal() {
        this.setState({confirmResetAnchor: false})
    }

    isCalibrationButtonDisabled() {
        return (
            this.state.calibrateInProgress ||
            this.state.calibrationSignalBias1 === "" ||
            this.state.calibrationSignalBias2 === "" ||
            this.state.calibrationForceBias1 === "" ||
            this.state.calibrationForceBias2 === "" ||
            this.state.calibrationTemperatureOffset === "" ||
            this.state.calibrationSignalBias1 === this.state.calibrationSignalBias2 ||
            this.state.calibrationForceBias1 === this.state.calibrationForceBias2
        )
    }

    confirmCalibrationModal(event) {
        event && event.preventDefault()
        this.setState({calibrateInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                signalBias1: this.state.calibrationSignalBias1,
                signalBias2: this.state.calibrationSignalBias2,
                forceBias1: this.state.calibrationForceBias1,
                forceBias2: this.state.calibrationForceBias2,
                temperatureOffset: this.state.calibrationTemperatureOffset
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/calibrate/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({showCalibrationModal: false}, () => this.loadDetails())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({calibrateInProgress: false}))
    }

    onChangeCalibrationSignalBias1(value) {
        this.setState({calibrationSignalBias1: value})
    }

    onChangeCalibrationSignalBias2(value) {
        this.setState({calibrationSignalBias2: value})
    }

    onChangeCalibrationForceBias1(value) {
        this.setState({calibrationForceBias1: value})
    }

    onChangeCalibrationForceBias2(value) {
        this.setState({calibrationForceBias2: value})
    }

    onChangeCalibrationTemperatureOffset(value) {
        this.setState({calibrationTemperatureOffset: value})
    }

    showInGatewayList(gatewayId) {
        this.props["history"].push(`${AppSettings.rootPath}/sensorGateways?filter=q:${gatewayId}`)
    }

    isExportButtonDisabled() {
        return this.state.anchor.telemetryId === null
    }

    async exportAnchorTelemetryAsCsv() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const response = await fetch(`${AppSettings.rootPath}/v1/telemetry/${this.state.anchor.telemetryId}/csv`, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream(`${this.props.serialNumber}.csv`)

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    confirmResetAnchor() {
        this.setState({confirmResetAnchor: true})
    }

    resetAnchor() {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/reset/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.loadDetails()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({confirmResetAnchor: false}))
    }

    confirmRemoveAnchor() {
        this.setState({confirmRemoveAnchor: true})
    }

    renderConfirmRemoveAnchorModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_confirm-title")}
                isOpen={true}
                onClose={this.closeConfirmRemoveAnchorModal}
                actions={[
                    <Button key="confirm" variant="primary" onClick={this.removeAnchor}>{t("fe_action_remove")}</Button>,
                    <Button key="cancel" variant="link" onClick={this.closeConfirmRemoveAnchorModal}>{t("fe_common_cancel")}</Button>
                ]}
            >
                {t("fe_anchor_remove-description")}
            </Modal>
        )
    }

    closeConfirmRemoveAnchorModal() {
        this.setState({confirmRemoveAnchor: false})
    }

    removeAnchor() {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/remove/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.reloadAndClose()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({confirmRemoveAnchor: false}))
    }

    editForecasting() {
        this.setState(prevState => {
            return {
                showForecastingModal: true,
                forecastingDurationValue: prevState.anchor.forecasting ? prevState.anchor.forecasting.durationValue : 30,
                forecastingDurationUnit: prevState.anchor.forecasting ? prevState.anchor.forecasting.durationUnit : "d",
                forecastingGranularityValue: prevState.anchor.forecasting ? prevState.anchor.forecasting.granularityValue : 1,
                forecastingGranularityUnit: prevState.anchor.forecasting ? prevState.anchor.forecasting.granularityUnit : "h",
            }
        })
    }

    renderForecastingModal(t) {
        const isUpdate = this.state.anchor.forecasting !== null
        const actions = [
            <Button
                key="confirm"
                variant="primary"
                form="modal-with-form-form"
                onClick={() => this.confirmForecastingModal(false, t)}
                isDisabled={this.state.forecastingConfigInProgress}
                icon={this.state.forecastingConfigInProgress && <SyncAltIcon className="ProgressAnimation"/>}
            >
                {t(this.state.forecastingConfigInProgress ? "fe_action_forecasting-model-generating" : isUpdate ? "fe_common_update" : "fe_common_ok")}
            </Button>
        ]
        if (isUpdate && !this.state.forecastingConfigInProgress) {
            actions.push(
                <Button
                    key="disable"
                    variant="danger"
                    onClick={() => this.disableForecasting()}
                >
                    {t("fe_action_forecasting-disable")}
                </Button>
            )
        }
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_forecasting")}
                isOpen={this.state.showForecastingModal}
                onClose={() => this.setState({showForecastingModal: false})}
                actions={actions}
            >
                <Form id="modal-with-form-form" onSubmit={(event) => this.confirmForecastingModal(event, t)}>
                    <FormGroup label={t("fe_anchor_forecasting-duration")} fieldId="duration-value" className="TopSpacer">
                        <Flex>
                            <FlexItem>
                                <TextInput type="number" id="duration-value"
                                           name="duration-value"
                                           value={this.state.forecastingDurationValue}
                                           onChange={(value) => this.setState({forecastingDurationValue: value})}
                                           style={{width:"400px"}}
                                />
                            </FlexItem>
                            <FlexItem>
                                <Dropdown
                                    onSelect={() => this.setState({forecastDurationDropdownOpen: false})}
                                    toggle={
                                        <DropdownToggle
                                            id="duration-unit"
                                            onToggle={() => this.setState(prevState => {return {forecastDurationDropdownOpen: !prevState.forecastDurationDropdownOpen}})}
                                        >
                                            {this.state.forecastingDurationUnit}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.forecastDurationDropdownOpen}
                                    dropdownItems={[
                                        <DropdownItem key="m" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "m"})}>m</DropdownItem>,
                                        <DropdownItem key="h" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "h"})}>h</DropdownItem>,
                                        <DropdownItem key="d" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "d"})}>d</DropdownItem>
                                    ]}
                                    menuAppendTo="parent"
                                />
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                    <FormGroup label={t("fe_anchor_forecasting-granularity")} fieldId="granularity-value">
                        <Flex>
                            <FlexItem>
                                <TextInput type="number" id="granularity-value"
                                           name="granularity-value"
                                           value={this.state.forecastingGranularityValue}
                                           onChange={(value) => this.setState({forecastingGranularityValue: value})}
                                           style={{width:"400px"}}
                                />
                            </FlexItem>
                            <FlexItem>
                                <Dropdown
                                    onSelect={() => this.setState({forecastGranularityDropdownOpen: false})}
                                    toggle={
                                        <DropdownToggle
                                            id="granularity-unit"
                                            onToggle={() => this.setState(prevState => {return {forecastGranularityDropdownOpen: !prevState.forecastGranularityDropdownOpen}})}
                                        >
                                            {this.state.forecastingGranularityUnit}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.forecastGranularityDropdownOpen}
                                    dropdownItems={[
                                        <DropdownItem key="m" component="button"
                                                      onClick={() => this.setState({forecastingGranularityUnit: "m"})}>m</DropdownItem>,
                                        <DropdownItem key="h" component="button"
                                                      onClick={() => this.setState({forecastingGranularityUnit: "h"})}>h</DropdownItem>,
                                        <DropdownItem key="d" component="button"
                                                      onClick={() => this.setState({forecastingGranularityUnit: "d"})}>d</DropdownItem>
                                    ]}
                                    menuAppendTo="parent"
                                />
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                </Form>
            </Modal>
        )
    }

    confirmForecastingModal(event) {
        event && event.preventDefault()
        this.setState({forecastingConfigInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                durationValue: this.state.forecastingDurationValue,
                durationUnit: this.state.forecastingDurationUnit,
                granularityValue: this.state.forecastingGranularityValue,
                granularityUnit: this.state.forecastingGranularityUnit
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/forecasting/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({showForecastingModal: false}, () => this.loadDetails())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({forecastingConfigInProgress: false}))
    }

    disableForecasting() {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/forecasting/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({showForecastingModal: false}, () => this.loadDetails())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    setHardwareVersion(event) {
        event && event.preventDefault()
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                version: this.state.editedHardwareVersion
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/version/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({showHardwareVersionModal: false}, () => {
                        this.loadDetails()
                        this.props.reload()
                    })
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default withRouter(AnchorDetails)