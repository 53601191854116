import React from "react";

import { Bar } from "react-chartjs-2";
import { formatNumber } from "./Utils";

import './UsageChart.css';

const UsageChart = ({t, chartLabel, chartData, unit}) => {
    return <div className="UsageChart">
        <Bar
            data={{
                labels: chartData.map(d => d["label"]),
                datasets: [{
                    label: chartLabel,
                    data: chartData.map(d => d["value"])
                }]
            }}
            options={{
                backgroundColor: '#0066cc',
                borderWidth: 0,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: context => formatNumber(context.parsed.y, 4, t) + ' ' + unit
                        },
                        position: 'nearest',
                        mode: 'x',
                        intersect: false
                    }
                }
            }}
        />
    </div>
}

export default UsageChart