import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerContentBody,
    Form,
    FormGroup,
    Text,
    TextArea
} from "@patternfly/react-core";

import {
    CheckCircleIcon,
    MinusCircleIcon,
    TrashIcon,
    WrenchIcon
} from "@patternfly/react-icons";

import "./DetailsDrawer.css";
import "./DetailsForm.css";
import { AppSettings } from "./AppSettings";

class FirmwareDetails extends Component {
    static FIRMWARE_DETAILS = [
        {key:"id", label:"UUID"},
        {key:"hardwareVersion", label:"fe_firmware_hw-version"},
        {key:"firmwareVersion", label:"fe_firmware_version"},
        {key:"size", label:"fe_firmware_size"},
        {key:"uploadedAt", label:"fe_firmware_uploaded-on"},
        {key:"uploadedBy", label:"fe_firmware_uploaded-by"},
        {key:"releasedAt", label:"fe_firmware_released-on"},
        {key:"releasedBy", label:"fe_firmware_released-by"},
        {key:"updatedDevices", label:"fe_firmware_updated-devices"},
        {key:"pendingDevices", label:"fe_firmware_pending-devices"}
    ]

    constructor(props) {
        super(props)

        this.state = {
            details: {},
            notesChanged: false
        }

        this.getStatusAlertIcon = this.getStatusAlertIcon.bind(this)
        this.getStatusAlertVariant = this.getStatusAlertVariant.bind(this)
        this.onNotesChanged = this.onNotesChanged.bind(this)
        this.saveNotes = this.saveNotes.bind(this)
        this.releaseFirmware = this.releaseFirmware.bind(this)
        this.removeFirmware = this.removeFirmware.bind(this)
    }

    loadDetails() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/${this.props["firmwareId"]}/details`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({
                            details: data,
                            notesChanged: false
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    componentDidMount() {
        this.loadDetails()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props["firmwareId"] !== prevProps["firmwareId"]) {
            this.loadDetails()
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <DrawerContentBody className="DetailsDrawer">
                        <Alert variant={this.getStatusAlertVariant()} isInline
                               title={this.state.details["statusLabel"] || ""} customIcon={this.getStatusAlertIcon()}/>
                        <DataList isCompact aria-label="details">
                            {
                                FirmwareDetails.FIRMWARE_DETAILS.map((item) => {
                                    return (
                                        <DataListItem key={item.key}>
                                            <DataListItemRow>
                                                <DataListItemCells dataListCells={[
                                                    <DataListCell key="label"
                                                                  isFilled={false}>{t(item.label)}</DataListCell>,
                                                    <DataListCell key="value" isFilled={false} alignRight
                                                                  className="Value">{this.state.details[item.key]}</DataListCell>
                                                ]}/>
                                            </DataListItemRow>
                                        </DataListItem>
                                    )
                                })
                            }
                        </DataList>
                        <Form className="DetailsForm">
                            {
                                this.state.details["released"] ?
                                    <Text>{this.state.details["notes"]}</Text> :
                                    <FormGroup label={t("fe_common_note")} fieldId="notes"
                                               helperText={t("fe_firmware_notes-hint")}>
                                        <TextArea id="notes" name="notes" autoResize
                                                  onChange={this.onNotesChanged} resizeOrientation="vertical"
                                                  value={this.state.details["notes"] || ""}/>
                                    </FormGroup>
                            }
                            <ActionGroup className="DetailsActionGroup">
                                <Button isDisabled={!this.state.notesChanged} variant="primary"
                                        onClick={this.saveNotes}>{t("fe_common_save")}</Button>
                                <Button isDisabled={this.state.details["released"]} variant="primary"
                                        onClick={this.releaseFirmware}>{t("fe_common_publish")}</Button>
                                <Button isDisabled={!this.state.details["removable"]} variant="danger"
                                        icon={<TrashIcon/>} onClick={this.removeFirmware}>{t("fe_common_delete")}</Button>
                            </ActionGroup>
                        </Form>
                    </DrawerContentBody>
                }
            </Translation>
        )
    }

    getStatusAlertVariant() {
        if (this.state.details["released"]) {
            if (this.state.details["removable"]) {
                return "danger"
            } else {
                return "success"
            }
        } else {
            return "info"
        }
    }

    getStatusAlertIcon() {
        if (this.state.details["released"]) {
            if (this.state.details["removable"]) {
                return <MinusCircleIcon/>
            } else {
                return <CheckCircleIcon/>
            }
        } else {
            return <WrenchIcon/>
        }
    }

    onNotesChanged(value) {
        this.setState(prevState => {
            return {
                details: {...prevState.details, notes: value},
                notesChanged: true
            }
        })
    }

    saveNotes() {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "text/plain"
            },
            body: this.state.details["notes"]
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/${this.props["firmwareId"]}/notes`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({notesChanged: false})
                    this.props.onDetailsChanged()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    releaseFirmware() {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "text/plain"
            },
            body: this.state.details["notes"]
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/${this.props["firmwareId"]}/release`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.setState({
                                details: data,
                                notesChanged: false
                            })
                            this.props.onDetailsChanged()
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    removeFirmware() {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/${this.props["firmwareId"]}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.onDetailsChanged()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default FirmwareDetails