import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    Card,
    Drawer,
    DrawerActions,
    DrawerCloseButton,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelContent,
    FileUpload,
    Form,
    FormGroup,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Spinner,
    Tab,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    TextArea,
    TextInput,
    Title
} from "@patternfly/react-core";

import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";

import {
    CheckCircleIcon,
    ListIcon,
    SyncAltIcon,
    MinusCircleIcon,
    UploadIcon,
    WrenchIcon,
    PlusIcon
} from "@patternfly/react-icons";

import "./TableIcons.css";
import FirmwareDetails from "./FirmwareDetails";
import { AppSettings } from "./AppSettings";
import ModemUrlDetails from "./ModemUrlDetails";

class FirmwareList extends Component {
    static FIRMWARE_COLUMNS = [
        {field: "hardwareVersion", label: "fe_firmware_hw-version", width: 15},
        {field: "firmwareVersion", label: "fe_firmware_version", width: 15},
        {field: "localizedUploadedAt", label: "fe_firmware_uploaded", width: 15},
        {field: "localizedReleasedAt", label: "fe_firmware_released", width: 15},
        {field: "summary", label: "fe_common_note", width: 40}
    ]
    static MODEM_URL_COLUMNS = [
        {field: "updatesVersion", label: "fe_common_modem-version", width: 20},
        {field: "localizedStatus", label: "fe_common_status", width: 15},
        {field: "localizedCreatedAt", label: "fe_common_created", width: 15},
        {field: "localizedReleasedAt", label: "fe_firmware_released", width: 15},
        {field: "summary", label: "fe_common_note", width: 35}
    ]

    static sortRows(rows, index, direction) {
        const field = FirmwareList.FIRMWARE_COLUMNS[index].field === "localizedUploadedAt" ? "uploadedAt" :
            FirmwareList.FIRMWARE_COLUMNS[index].field === "localizedReleasedAt" ? "releasedAt" :
            FirmwareList.FIRMWARE_COLUMNS[index].field
        return rows.sort((g1, g2) => {
            if (direction === "asc") {
                if (field === "uploadedAt" || field === "releasedAt") {
                    return g1[field] - g2[field]
                }
                return g1[field].localeCompare(g2[field])
            }
            if (field === "uploadedAt" || field === "releasedAt") {
                return g2[field] - g1[field]
            }
            return g2[field].localeCompare(g1[field])
        })
    }

    static sortModemUrlRows(rows, index, direction) {
        const field = FirmwareList.MODEM_URL_COLUMNS[index].field === "localizedCreatedAt" ? "createdAt" :
            FirmwareList.MODEM_URL_COLUMNS[index].field === "localizedReleasedAt" ? "releasedAt" :
                FirmwareList.MODEM_URL_COLUMNS[index].field
        return rows.sort((g1, g2) => {
            if (direction === "asc") {
                if (field === "createdAt" || field === "releasedAt") {
                    return g1[field] - g2[field]
                }
                return g1[field].localeCompare(g2[field])
            }
            if (field === "createdAt" || field === "releasedAt") {
                return g2[field] - g1[field]
            }
            return g2[field].localeCompare(g1[field])
        })
    }

    static status2Icon(dto) {
        if (dto["released"]) {
            if (dto["removable"]) {
                return <MinusCircleIcon className="Danger"/>
            } else {
                return <CheckCircleIcon className="Success"/>
            }
        } else {
            return <WrenchIcon className="Info"/>
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            firmwares: [],
            activeTabKey: 0,
            selectedRowIndex: -1,
            activeSortIndex: 0,
            activeSortDirection: "asc",
            uploadImage: false,
            uploadFileName: "",
            uploadNotes: "",
            uploadFirmwareVersion: "",
            uploadHardwareVersion: "",
            uploadInProgress: false,
            modemUrls: [],
            activeModemUrlSortIndex: 0,
            activeModemUrlSortDirection: "asc",
            successMessage2: false,
            modemUrlUpdatesVersion: "",
            modemUrl: "",
            modemUrlNotes: ""
        }

        this.loadFirmwareList = this.loadFirmwareList.bind(this)
        this.onTabSelect = this.onTabSelect.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onSort = this.onSort.bind(this)
        this.drawerCloseClicked = this.drawerCloseClicked.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onModemUrlSort = this.onModemUrlSort.bind(this)
        this.loadModemUrlList = this.loadModemUrlList.bind(this)
        this.onModemUrlSubmit = this.onModemUrlSubmit.bind(this)
    }

    componentDidMount() {
        this.loadFirmwareList()
    }

    loadFirmwareList() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.setState(prevState => {
                                return {
                                    selectedRowIndex: -1,
                                    firmwares: FirmwareList.sortRows(data, prevState.activeSortIndex, prevState.activeSortDirection)
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    render() {
        const drawerExpanded = this.state.selectedRowIndex !== -1

        return (
            <Translation>
                { t =>
                    <>
                        <Drawer isExpanded={drawerExpanded} position="right">
                            <DrawerContent panelContent={
                                <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                    <DrawerHead>
                                        <Title headingLevel="h1">{this.state.activeTabKey === 0 ? t("fe_firmware_details") : t("fe_modem-url_details")}</Title>
                                        <DrawerActions>
                                            <DrawerCloseButton onClick={this.drawerCloseClicked}/>
                                        </DrawerActions>
                                    </DrawerHead>
                                    {
                                        drawerExpanded && this.state.activeTabKey === 0 && (
                                            <FirmwareDetails
                                                app={this.props.app}
                                                firmwareId={this.state.firmwares[this.state.selectedRowIndex].id}
                                                onDetailsChanged={this.loadFirmwareList}
                                            />
                                        )
                                    }
                                    {
                                        drawerExpanded && this.state.activeTabKey === 2 && (
                                            <ModemUrlDetails
                                                app={this.props.app}
                                                updatesVersion={this.state.modemUrls[this.state.selectedRowIndex].updatesVersion}
                                                onDetailsChanged={this.loadModemUrlList}
                                            />
                                        )
                                    }
                                </DrawerPanelContent>
                            }>
                                <DrawerContentBody>
                                    <PageGroup>
                                        <PageSection variant={PageSectionVariants.light}>
                                            <Title className="Title" headingLevel="h1">
                                                {t("fe_firmware_title")}
                                            </Title>
                                            <Tabs activeKey={this.state.activeTabKey} onSelect={this.onTabSelect}
                                                  isBox={true}>
                                                <Tab eventKey={0} title={
                                                    <>
                                                        <TabTitleIcon><ListIcon/></TabTitleIcon>
                                                        <TabTitleText>{t("fe_firmware_list")}</TabTitleText>
                                                    </>}>
                                                    <Card component="div">
                                                        <TableComposable variant="compact" borders={true} isStickyHeader>
                                                            <Thead>
                                                                <Tr>
                                                                    {
                                                                        FirmwareList.FIRMWARE_COLUMNS.map((column, index) => {
                                                                            const sortParams = {
                                                                                sort: {
                                                                                    sortBy: {
                                                                                        index: this.state.activeSortIndex,
                                                                                        direction: this.state.activeSortDirection
                                                                                    },
                                                                                    onSort: this.onSort,
                                                                                    columnIndex: index
                                                                                }
                                                                            };
                                                                            return <Th key={index} {...sortParams}
                                                                                       width={column.width}>{t(column.label)}</Th>
                                                                        })
                                                                    }
                                                                    <Th key="refresh" className="RefreshButton">
                                                                        <Button variant="link" isInline
                                                                                aria-label="Refresh"
                                                                                onClick={this.loadFirmwareList}><SyncAltIcon/></Button>
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    this.state.firmwares.map((row, rowIndex) => {
                                                                        return (
                                                                            <Tr isHoverable key={rowIndex}
                                                                                onRowClick={event => this.onRowClick(event, rowIndex)}
                                                                                isRowSelected={this.state.selectedRowIndex === rowIndex}>
                                                                                <Td key={`${rowIndex}_0`}
                                                                                    dataLabel={t(FirmwareList.FIRMWARE_COLUMNS[0].label)}>
                                                                                    {row["hardwareVersion"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_1`}
                                                                                    dataLabel={t(FirmwareList.FIRMWARE_COLUMNS[1].label)}>
                                                                                    {FirmwareList.status2Icon(row)}
                                                                                    {row["firmwareVersion"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_2`}
                                                                                    dataLabel={t(FirmwareList.FIRMWARE_COLUMNS[2].label)}>
                                                                                    {row["localizedUploadedAt"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_3`}
                                                                                    dataLabel={t(FirmwareList.FIRMWARE_COLUMNS[3].label)}>
                                                                                    {row["localizedReleasedAt"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_4`}
                                                                                    dataLabel={t(FirmwareList.FIRMWARE_COLUMNS[4].label)}>
                                                                                    {row["summary"]}
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                                }
                                                            </Tbody>
                                                        </TableComposable>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey={1}
                                                     title={<><TabTitleIcon><UploadIcon/></TabTitleIcon> <TabTitleText>
                                                         {t("fe_firmware_upload-new")}
                                                     </TabTitleText></>}>
                                                    {
                                                        this.state.successMessage ?
                                                            <Alert isInline className="TopSpacer" variant="success"
                                                                   title={this.state.successMessage}/> : false
                                                    }
                                                    <Form className="TopSpacer">
                                                        <FormGroup
                                                            label={t("fe_firmware_image-file")}
                                                            isRequired
                                                            fieldId="image-file"
                                                            helperText={t("fe_firmware_file-hint")}
                                                        >
                                                            <FileUpload
                                                                id="image-file"
                                                                hideDefaultPreview={true}
                                                                value={this.state.uploadImage}
                                                                filename={this.state.uploadFileName}
                                                                filenamePlaceholder={t("fe_firmware_file-placeholder")}
                                                                browseButtonText={t("fe_firmware_select-file")}
                                                                onChange={this.onFileChange}
                                                                clearButtonText={t("fe_firmware_reset-file")}
                                                                dropzoneProps={{accept: '.bin'}}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label={t("fe_firmware_version")}
                                                            fieldId="firmware-version"
                                                            helperText={t("fe_firmware_detected-from-file")}
                                                        >
                                                            <TextInput
                                                                isDisabled
                                                                type="text"
                                                                id="firmware-version"
                                                                name="firmware-version"
                                                                value={this.state.uploadFirmwareVersion}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label={t("fe_firmware_hw-version")}
                                                            isRequired
                                                            fieldId="hardware-version"
                                                            helperText={t("fe_firmware_detected-from-file")}
                                                        >
                                                            <TextInput
                                                                isDisabled
                                                                type="number"
                                                                id="hardware-version"
                                                                name="hardware-version"
                                                                value={this.state.uploadHardwareVersion}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label={t("fe_common_note")}
                                                            fieldId="notes"
                                                            helperText={t("fe_firmware_notes-hint")}
                                                        >
                                                            <TextArea
                                                                id="notes"
                                                                name="notes"
                                                                autoResize
                                                                resizeOrientation="vertical"
                                                                style={{minHeight: "6em"}}
                                                                onChange={(value) => this.setState({uploadNotes: value})}
                                                                value={this.state.uploadNotes}
                                                            />
                                                        </FormGroup>
                                                        <ActionGroup>
                                                            {
                                                                this.state.uploadInProgress ? <Spinner isSVG/> : (
                                                                    <Button
                                                                        isDisabled={!(this.state.uploadImage && this.state.uploadFirmwareVersion && this.state.uploadHardwareVersion)}
                                                                        variant="primary"
                                                                        onClick={() => this.onSubmit(t)}>
                                                                        {t("fe_action_upload")}
                                                                    </Button>
                                                                )
                                                            }
                                                        </ActionGroup>
                                                    </Form>
                                                </Tab>
                                                <Tab eventKey={2} title={
                                                    <>
                                                        <TabTitleIcon><ListIcon/></TabTitleIcon>
                                                        <TabTitleText>{t("fe_modem-urls_list")}</TabTitleText>
                                                    </>}>
                                                    <Card component="div">
                                                        <TableComposable variant="compact" borders={true} isStickyHeader>
                                                            <Thead>
                                                                <Tr>
                                                                    {
                                                                        FirmwareList.MODEM_URL_COLUMNS.map((column, index) => {
                                                                            const sortParams = {
                                                                                sort: {
                                                                                    sortBy: {
                                                                                        index: this.state.activeModemUrlSortIndex,
                                                                                        direction: this.state.activeModemUrlSortDirection
                                                                                    },
                                                                                    onSort: this.onModemUrlSort,
                                                                                    columnIndex: index
                                                                                }
                                                                            };
                                                                            return <Th key={index} {...sortParams}
                                                                                       width={column.width}>{t(column.label)}</Th>
                                                                        })
                                                                    }
                                                                    <Th key="refresh" className="RefreshButton">
                                                                        <Button variant="link" isInline
                                                                                aria-label="Refresh"
                                                                                onClick={this.loadModemUrlList}><SyncAltIcon/></Button>
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    this.state.modemUrls.map((row, rowIndex) => {
                                                                        return (
                                                                            <Tr isHoverable key={rowIndex}
                                                                                onRowClick={event => this.onRowClick(event, rowIndex)}
                                                                                isRowSelected={this.state.selectedRowIndex === rowIndex}>
                                                                                <Td key={`${rowIndex}_0`}
                                                                                    dataLabel={t(FirmwareList.MODEM_URL_COLUMNS[0].label)}>
                                                                                    {row["updatesVersion"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_1`}
                                                                                    dataLabel={t(FirmwareList.MODEM_URL_COLUMNS[1].label)}>
                                                                                    {FirmwareList.status2Icon(row)}
                                                                                    {row["localizedStatus"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_2`}
                                                                                    dataLabel={t(FirmwareList.MODEM_URL_COLUMNS[2].label)}>
                                                                                    {row["localizedCreatedAt"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_3`}
                                                                                    dataLabel={t(FirmwareList.MODEM_URL_COLUMNS[3].label)}>
                                                                                    {row["localizedReleasedAt"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_4`}
                                                                                    dataLabel={t(FirmwareList.MODEM_URL_COLUMNS[4].label)}>
                                                                                    {row["summary"]}
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                                }
                                                            </Tbody>
                                                        </TableComposable>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey={3}
                                                     title={<><TabTitleIcon><PlusIcon/></TabTitleIcon> <TabTitleText>
                                                         {t("fe_modem-urls_add")}
                                                     </TabTitleText></>}>
                                                    {
                                                        this.state.successMessage2 ?
                                                            <Alert isInline className="TopSpacer" variant="success"
                                                                   title={this.state.successMessage2}/> : false
                                                    }
                                                    <Form className="TopSpacer">
                                                        <FormGroup
                                                            label={t("fe_modem-urls_updates-version")}
                                                            isRequired
                                                            fieldId="updates-version"
                                                            helperText="fe_modem-urls_updates-version-hint">
                                                            <TextInput
                                                                type="text"
                                                                id="updates-version"
                                                                name="updates-version"
                                                                placeholder={t("fe_common_eg").replace("%", "BG95M3LAR02A03_01.017.01.017")}
                                                                value={this.state.modemUrlUpdatesVersion}
                                                                onChange={(value) => this.setState({modemUrlUpdatesVersion: value})}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label={t("fe_common_url")}
                                                            isRequired
                                                            fieldId="url"
                                                            helperText={t("fe_modem-urls_url-hint")}>
                                                            <TextInput
                                                                type="text"
                                                                id="url"
                                                                name="url"
                                                                value={this.state.modemUrl}
                                                                onChange={(value) => this.setState({modemUrl: value})}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label={t("fe_common_note")}
                                                            fieldId="modem-firmware-notes"
                                                            helperText={t("fe_firmware_notes-hint")}>
                                                            <TextArea
                                                                style={{minHeight: "6em"}}
                                                                id="modem-firmware-notes"
                                                                name="modem-firmware-notes"
                                                                autoResize
                                                                onChange={(value) => this.setState({modemUrlNotes: value})}
                                                                resizeOrientation="vertical"
                                                                value={this.state.modemUrlNotes}
                                                            />
                                                        </FormGroup>
                                                        <ActionGroup>
                                                            <Button
                                                                isDisabled={!(this.state.modemUrlUpdatesVersion && this.state.modemUrl)}
                                                                variant="primary"
                                                                onClick={() => this.onModemUrlSubmit(t)}
                                                            >
                                                                {t("fe_common_add")}
                                                            </Button>
                                                        </ActionGroup>
                                                    </Form>
                                                </Tab>
                                            </Tabs>
                                        </PageSection>
                                    </PageGroup>
                                </DrawerContentBody>
                            </DrawerContent>
                        </Drawer>
                    </>
                }
            </Translation>
        )
    }

    onTabSelect(_, tabIndex) {
        this.setState({
            activeTabKey: tabIndex,
            selectedRowIndex: -1
        })
        switch (tabIndex) {
        case 0:
            this.loadFirmwareList()
            break
        case 2:
            this.loadModemUrlList()
            break
        default:
        }
    }

    onRowClick(_, rowIndex) {
        this.setState(prevState => {
            return {
                selectedRowIndex: prevState.selectedRowIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    drawerCloseClicked() {
        this.setState({selectedRowIndex: -1});
    }

    onSort(_, index, direction) {
        this.setState(prevState => {
            return {
                selectedRowIndex: -1,
                activeSortIndex: index,
                activeSortDirection: direction,
                firmwares: FirmwareList.sortRows(prevState.firmwares, index, direction)
            }
        })
    }

    onFileChange(file, name) {
        const match = name.match(/FSG-([0-9]{2})-([0-9]{2}[.][0-9]{2}[.][0-9a-fA-F+#]{8})\.bin/)
        if (match) {
            const hardwareVersion = match[1];
            const firmwareVersion = match[2];
            const fileName = file ? `${file.name}, ${file.size || "?"} Bytes` : ""
            this.setState({
                uploadImage: file,
                uploadFileName: fileName,
                uploadFirmwareVersion: firmwareVersion,
                uploadHardwareVersion : hardwareVersion
            })
        }
    }

    async onSubmit(t) {
        this.setState({uploadInProgress: true})

        const hardwareVersion = this.state.uploadHardwareVersion
        const firmwareVersion = this.state.uploadFirmwareVersion
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/octet-stream"
            },
            body: this.state.uploadImage
        }
        try {
            const response = await fetch(`${AppSettings.rootPath}/v1/firmware/upload?hardwareVersion=${encodeURIComponent(hardwareVersion)}&firmwareVersion=${encodeURIComponent(firmwareVersion)}`, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                if (this.state.uploadNotes) {
                    const imageId = await response.text()
                    const requestInit2 = {
                        cache: "no-cache",
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${this.props.app["jwt"]}`,
                            "Content-Type": "text/plain"
                        },
                        body: this.state.uploadNotes
                    }
                    const response2 = await fetch(`${AppSettings.rootPath}/v1/firmware/${imageId}/notes`, requestInit2)
                    if (!response2.ok) {
                        response2.text().then(message => this.props.app.showErrorMessage(message || response2.statusText))
                        return
                    }
                }
                this.setState({
                    uploadImage: false,
                    uploadFileName: "",
                    uploadNotes: "",
                    uploadFirmwareVersion: "",
                    uploadHardwareVersion: "",
                    successMessage: t("fe_firmware_upload-succeeded")
                })
            }
        } catch(error) {
            this.setState({
                successMessage: false
            }, () => this.props.app.showErrorMessage(error.message))
        } finally {
            this.setState({uploadInProgress: false})
        }
    }

    onModemUrlSort(_, index, direction) {
        this.setState(prevState => {
            return {
                selectedModemUrlRowIndex: -1,
                activeModemUrlSortIndex: index,
                activeModemUrlSortDirection: direction,
                modemUrls: FirmwareList.sortModemUrlRows(prevState.modemUrls, index, direction)
            }
        })
    }

    loadModemUrlList() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.setState(prevState => {
                                return {
                                    selectedRowIndex: -1,
                                    modemUrls: FirmwareList.sortModemUrlRows(data, prevState.activeModemUrlSortIndex, prevState.activeModemUrlSortDirection)
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    async onModemUrlSubmit(t) {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                updatesVersion: this.state.modemUrlUpdatesVersion,
                url: this.state.modemUrl,
                notes: this.state.modemUrlNotes
            })
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 201) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({
                        successMessage2: t("fe_modem-urls_add-succeeded"),
                        modemUrlUpdatesVersion: "",
                        modemUrl: "",
                        modemUrlNotes: ""
                    })
                }
            })
            .catch(error => this.setState({successMessage2: false}, () => this.props.app.showErrorMessage(error.message)))
    }
}

export default withRouter(FirmwareList)