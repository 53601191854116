import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import queryString from "query-string";

import {
    ActionGroup,
    Alert,
    Button,
    CalendarMonth,
    Card,
    Divider,
    Drawer,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerPanelContent,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    InputGroup,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Popover,
    SearchInput,
    Spinner,
    Tab,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    TextInput,
    Title,
    TitleSizes,
    ToggleGroup,
    ToggleGroupItem
} from "@patternfly/react-core";

import {
    TableComposable,
    Thead,
    Td,
    Tbody,
    Tr,
    Th
} from "@patternfly/react-table";

import {
    AsleepIcon,
    CheckCircleIcon,
    CogIcon,
    DisconnectedIcon,
    DownloadIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    HistoryIcon,
    ListIcon,
    OutlinedCalendarAltIcon,
    OutlinedClockIcon,
    PlusIcon,
    QuestionCircleIcon,
    SyncAltIcon
} from "@patternfly/react-icons";

import GatewayDetails from "./GatewayDetails";

import {
    date2ISOString,
    formatDate
} from "./Utils";

import { AppSettings } from "./AppSettings";

import "./TableIcons.css";
import "./DetailsDrawer.css";

class GatewayList extends Component {
    static COLUMNS = [
        {field: "serialNumber", key: "fe_common_serial-number", width: 10},
        {field: "status", key: "fe_common_status", width: 15},
        {field: "firmware", key: "fe_common_firmware", width: 8},
        {field: "modem", key: "fe_common_modem", width: 8},
        {field: "numAnchors", key: "fe_common_anchor", width: 9},
        {field: "location", key: "fe_common_location", width: 15},
        {field: "network", key: "fe_common_network", width: 10},
        {field: "name", key: "fe_common_name", width: 15},
        {field: "customer", key: "fe_common_customer", width: 10}
    ]

    static sortGateways(gateways, index, direction) {
        const field = GatewayList.COLUMNS[index].field
        return gateways.sort((g1, g2) => {
            if (direction === 'asc') {
                if (field === "numAnchors") {
                    return g1[field] - g2[field]
                }
                return g1[field].localeCompare(g2[field])
            }
            if (field === "numAnchors") {
                return g2[field] - g1[field]
            }
            return g2[field].localeCompare(g1[field])
        })
    }

    static filterGateways(gateways, filter) {
        return gateways.filter(g => {
            return (
                (!filter.notProvisioned || g["statusEnum"] === "NotProvisioned") &&
                (!filter.provisioned || g["statusEnum"] !== "NotProvisioned") &&
                (!filter.outdatedFirmware || g["firmware"] === "Veraltet") &&
                (!filter.error || g["statusEnum"] === "Error" || g["statusEnum"] === "Offline") &&
                (!filter.warnings || g["statusEnum"] === "Warning" || g["statusEnum"] === "IncompleteAnchorSettings") &&
                (!filter.searchString || g.serialNumber.includes(filter.searchString))
            )
        })
    }

    static status2Icon(statusEnum) {
        switch (statusEnum) {
        case "WaitingForFeedback":
            return <OutlinedClockIcon className="Info"/>
        case "SleepDesired":
            return <AsleepIcon className="Info"/>
        case "Sleep":
            return <AsleepIcon className="Success"/>
        case "Setup":
            return <CogIcon className="Info"/>
        case "FirmwareUpgrade":
            return <DownloadIcon className="Info"/>
        case "IncompleteAnchorSettings":
        case "Warning":
            return <ExclamationTriangleIcon className="Warning"/>
        case "Operational":
            return <CheckCircleIcon className="Success"/>
        case "RebootDesired":
            return <HistoryIcon className="Info"/>
        case "Offline":
            return <DisconnectedIcon className="Danger"/>
        case "Error":
            return <ExclamationCircleIcon className="Danger"/>
        default:
            return <QuestionCircleIcon className="Unknown"/>
        }
    }

    constructor(props) {
        super(props)

        this.gateways = []

        const filter = queryString.parse(this.props["location"].search).filter || false

        this.state = {
            activeTabKey: 0,
            gateways: [],
            selectedRowIndex: -1,
            activeSortIndex: 0,
            activeSortDirection: 'asc',
            filter: {
                notProvisioned: false,
                provisioned: filter === "provisioned",
                outdatedFirmware: filter === "outdated",
                error: filter === "errors",
                warnings: filter === "warnings",
                searchString: ""
            },
            toggleMenus: [],
            successMessage: false,
            registerSerialNumber: "",
            registerManufacturedAt: null,
            registerManufacturedAtFormatted: "",
            registerCharge: "",
            registerHardwareVersion: "",
            registerConnectors: "",
            registerSimCardNumber: "",
            registerSimCardProvider: "",
            providersDropDownOpen: false,
            registerSimCardType: "",
            registerSimCardExpiresAt: null,
            registerSimCardExpiresAtFormatted: "",
            manufacturedAtOpen: false,
            connectorsDropDownOpen: false,
            expiresAtOpen: false,
            typesDropDownOpen: false,
            registerInProgress: false,
            detailsReloadTrigger: false
        }

        this.onTabSelect = this.onTabSelect.bind(this)
        this.reloadGatewayList = this.reloadGatewayList.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onSort = this.onSort.bind(this)
        this.filterClicked = this.filterClicked.bind(this)
        this.onChangeSearchString = this.onChangeSearchString.bind(this)
        this.drawerCloseClicked = this.drawerCloseClicked.bind(this)
        this.onChangeSerialNumber = this.onChangeSerialNumber.bind(this)
        this.onSelectManufacturedAt = this.onSelectManufacturedAt.bind(this)
        this.onToggleManufacturedAt = this.onToggleManufacturedAt.bind(this)
        this.onChangeCharge = this.onChangeCharge.bind(this)
        this.onSelectHardwareVersion = this.onSelectHardwareVersion.bind(this)
        this.onToggleHardwareVersionDropDown = this.onToggleHardwareVersionDropDown.bind(this)
        this.onChangeHardwareVersion = this.onChangeHardwareVersion.bind(this)
        this.onSelectConnectors = this.onSelectConnectors.bind(this)
        this.onToggleConnectorsDropDown = this.onToggleConnectorsDropDown.bind(this)
        this.onChangeConnectors = this.onChangeConnectors.bind(this)
        this.onChangeSimCardNumber = this.onChangeSimCardNumber.bind(this)
        this.onSelectProvider = this.onSelectProvider.bind(this)
        this.onChangeProvider = this.onChangeProvider.bind(this)
        this.onToggleProvidersDropDown = this.onToggleProvidersDropDown.bind(this)
        this.onSelectSimCardType = this.onSelectSimCardType.bind(this)
        this.onToggleSimCardTypeDropDown = this.onToggleSimCardTypeDropDown.bind(this)
        this.onChangeSimCardType = this.onChangeSimCardType.bind(this)
        this.onSelectExpiresAt = this.onSelectExpiresAt.bind(this)
        this.onToggleExpiresAt = this.onToggleExpiresAt.bind(this)
        this.isRegisterButtonDisabled = this.isRegisterButtonDisabled.bind(this)
        this.onRegister = this.onRegister.bind(this)
        this.reloadGatewayDetails = this.reloadGatewayDetails.bind(this)
        this.refreshGatewayList = this.refreshGatewayList.bind(this)
    }

    componentDidMount() {
        const filter = queryString.parse(this.props["location"].search).filter || false
        this.setState({
            filter: {
                notProvisioned: false,
                provisioned: filter === "provisioned",
                outdatedFirmware: filter === "outdated",
                error: filter === "errors",
                warnings: filter === "warnings",
                searchString: filter && filter.startsWith("q:") ? filter.substring(2) : ""
            }
        })

        this.reloadGatewayList()

        this.refreshTimerId = window.setInterval(this.refreshGatewayList, 60 * 1_000)
    }

    componentWillUnmount() {
        if (this.refreshTimerId) {
            window.clearInterval(this.refreshTimerId)
            this.refreshTimerId = false
        }
    }

    render() {
        const drawerExpanded = this.state.selectedRowIndex !== -1
        const serialNumber = drawerExpanded ? this.state.gateways[this.state.selectedRowIndex].serialNumber : ""

        return (
            <Translation>
                { t =>
                    <>
                        <Drawer isExpanded={drawerExpanded} position="right">
                            <DrawerContent panelContent={
                                <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                    {
                                        drawerExpanded && <GatewayDetails
                                            app={this.props.app}
                                            serialNumber={serialNumber}
                                            reloadTrigger={this.state.detailsReloadTrigger}
                                            refreshList={this.reloadGatewayList}
                                            closeDrawer={this.drawerCloseClicked}
                                            addGatewayToStickyCharts={this.props.addGatewayToStickyCharts}
                                        />
                                    }
                                </DrawerPanelContent>
                            }>
                                <DrawerContentBody>
                                    <PageGroup>
                                        <PageSection variant={PageSectionVariants.light}>
                                            <Title className="Title" headingLevel="h1">
                                                {t("fe_gateways_title")}
                                            </Title>
                                            <Tabs activeKey={this.state.activeTabKey} onSelect={this.onTabSelect}
                                                  isBox={true}>
                                                <Tab eventKey={0}
                                                     title={<><TabTitleIcon><ListIcon/></TabTitleIcon> <TabTitleText>
                                                         {t("fe_common_overview")}
                                                     </TabTitleText></>}>
                                                    <ToggleGroup aria-label="Filter items" className="TopSpacer">
                                                        <ToggleGroupItem text={t("fe_gateways_filter-not-operational")}
                                                                         buttonId="notProvisioned"
                                                                         isSelected={this.state.filter.notProvisioned}
                                                                         onChange={this.filterClicked}/>
                                                        <ToggleGroupItem text={t("fe_gateways_filter-operational")}
                                                                         buttonId="provisioned"
                                                                         isSelected={this.state.filter.provisioned}
                                                                         onChange={this.filterClicked}/>
                                                        <ToggleGroupItem text={t("fe_gateways_filter-outdated")}
                                                                         buttonId="outdatedFirmware"
                                                                         isSelected={this.state.filter.outdatedFirmware}
                                                                         onChange={this.filterClicked}/>
                                                        <ToggleGroupItem text={t("fe_gateways_filter-error")} buttonId="error"
                                                                         isSelected={this.state.filter.error}
                                                                         onChange={this.filterClicked}/>
                                                        <ToggleGroupItem text={t("fe_gateways_filter-warning")} buttonId="warnings"
                                                                         isSelected={this.state.filter.warnings}
                                                                         onChange={this.filterClicked}/>
                                                        <SearchInput
                                                            className="SearchInput"
                                                            placeholder={t("fe_common_serial-number")}
                                                            value={this.state.filter.searchString}
                                                            onChange={this.onChangeSearchString}
                                                            onClear={e => this.onChangeSearchString('', e)}
                                                        />
                                                    </ToggleGroup>
                                                    <Card component="div">
                                                        <TableComposable variant="compact" borders={true} isStickyHeader>
                                                            <Thead>
                                                                <Tr>
                                                                    {
                                                                        GatewayList.COLUMNS.map((column, index) => {
                                                                            const sortParams = {
                                                                                sort: {
                                                                                    sortBy: {
                                                                                        index: this.state.activeSortIndex,
                                                                                        direction: this.state.activeSortDirection
                                                                                    },
                                                                                    onSort: this.onSort,
                                                                                    columnIndex: index
                                                                                }
                                                                            };
                                                                            return <Th key={index} {...sortParams}
                                                                                       width={column.width}>{t(column.key)}</Th>
                                                                        })
                                                                    }
                                                                    <Th key="refresh" className="RefreshButton">
                                                                        <Button variant="plain" isInline
                                                                                aria-label="Refresh"
                                                                                onClick={this.reloadGatewayList}><SyncAltIcon/></Button>
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    this.state.gateways.map((row, rowIndex) => {
                                                                        return (
                                                                            <Tr isHoverable key={rowIndex}
                                                                                onRowClick={event => this.onRowClick(event, rowIndex)}
                                                                                isRowSelected={this.state.selectedRowIndex === rowIndex}>
                                                                                <Td key={0}
                                                                                    dataLabel={t(GatewayList.COLUMNS[0].key)}>
                                                                                    {row["serialNumber"]}
                                                                                </Td>
                                                                                <Td key={1}
                                                                                    dataLabel={t(GatewayList.COLUMNS[1].key)}>
                                                                                    {GatewayList.status2Icon(row["statusEnum"])}
                                                                                    {row["status"]}
                                                                                </Td>
                                                                                <Td key={2}
                                                                                    dataLabel={t(GatewayList.COLUMNS[2].key)}>
                                                                                    {row["firmware"]}
                                                                                </Td>
                                                                                <Td key={3}
                                                                                    dataLabel={t(GatewayList.COLUMNS[3].key)}>
                                                                                    {row["modem"]}
                                                                                </Td>
                                                                                <Td key={4}
                                                                                    dataLabel={t(GatewayList.COLUMNS[3].key)}>
                                                                                    {row["numAnchors"]}
                                                                                </Td>
                                                                                <Td key={5}
                                                                                    dataLabel={t(GatewayList.COLUMNS[4].key)}>
                                                                                    {row["location"]}
                                                                                </Td>
                                                                                <Td key={6}
                                                                                    dataLabel={t(GatewayList.COLUMNS[5].key)}>
                                                                                    {row["network"]}
                                                                                </Td>
                                                                                <Td key={7}
                                                                                    dataLabel={t(GatewayList.COLUMNS[6].key)}>
                                                                                    {row["name"]}
                                                                                </Td>
                                                                                <Td key={8}
                                                                                    dataLabel={t(GatewayList.COLUMNS[7].key)}>
                                                                                    {row["customer"]}
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                                }
                                                            </Tbody>
                                                        </TableComposable>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey={1}
                                                     title={<><TabTitleIcon><PlusIcon/></TabTitleIcon> <TabTitleText>
                                                         {t("fe_gateways_register")}
                                                     </TabTitleText></>}>
                                                    {
                                                        this.state.successMessage ?
                                                            <Alert
                                                                isInline
                                                                className="TopSpacer"
                                                                variant="success"
                                                                title={this.state.successMessage}
                                                            /> : false
                                                    }
                                                    <Form>
                                                        <Flex>
                                                            <FlexItem style={{width: '400px'}}>
                                                                <Title
                                                                    headingLevel="h3"
                                                                    size={TitleSizes['2xl']}
                                                                    className="TopSpacer"
                                                                >
                                                                    {t("fe_gateway-reg_hardware")}
                                                                </Title>
                                                                <FormGroup
                                                                    label={t("fe_common_seq-number")}
                                                                    fieldId="serial-number"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="number"
                                                                        id="serial-number"
                                                                        name="serial-number"
                                                                        value={this.state.registerSerialNumber}
                                                                        placeholder={t("fe_common_auto-on-empty-placeholder")}
                                                                        onChange={this.onChangeSerialNumber}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_date-of-manufacture")}
                                                                    isRequired
                                                                    fieldId="manufactured-at"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Popover
                                                                        position="bottom"
                                                                        bodyContent={
                                                                            <CalendarMonth
                                                                                locale={t("language")}
                                                                                date={this.state.registerManufacturedAt}
                                                                                onChange={(value) => this.onSelectManufacturedAt(value, t)}
                                                                            />
                                                                        }
                                                                        showClose={false}
                                                                        isVisible={this.state.manufacturedAtOpen}
                                                                        hasNoPadding
                                                                        hasAutoWidth
                                                                    >
                                                                        <InputGroup>
                                                                            <TextInput
                                                                                type="text"
                                                                                id="manufactured-at"
                                                                                name="manufactured-at"
                                                                                isReadOnly
                                                                                value={this.state.registerManufacturedAtFormatted || t("fe_common_select-placeholder")}
                                                                            />
                                                                            <Button variant="control"
                                                                                    onClick={this.onToggleManufacturedAt}>
                                                                                <OutlinedCalendarAltIcon/>
                                                                            </Button>
                                                                        </InputGroup>
                                                                    </Popover>
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_charge")}
                                                                    isRequired
                                                                    fieldId="charge"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="number"
                                                                        id="charge"
                                                                        name="charge"
                                                                        value={this.state.registerCharge}
                                                                        onChange={this.onChangeCharge}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_version")}
                                                                    isRequired
                                                                    fieldId="hardware-version"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectHardwareVersion}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="hardware-version"
                                                                                onToggle={this.onToggleHardwareVersionDropDown}
                                                                                style={{width: "500px"}}>{this.state.registerHardwareVersion || t("fe_common_select-placeholder")}</DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.hardwareVersionDropDownOpen}
                                                                        dropdownItems={[
                                                                            <DropdownItem key="1" component="button"
                                                                                          onClick={() => this.onChangeHardwareVersion("1")}>1</DropdownItem>,
                                                                            <DropdownItem key="2" component="button"
                                                                                          onClick={() => this.onChangeHardwareVersion("2")}>2</DropdownItem>
                                                                        ]}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_ports")}
                                                                    isRequired
                                                                    fieldId="connectors"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectConnectors}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="hardware-version"
                                                                                onToggle={this.onToggleConnectorsDropDown}
                                                                                style={{width: "500px"}}>{this.state.registerConnectors || t("fe_common_select-placeholder")}</DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.connectorsDropDownOpen}
                                                                        dropdownItems={[
                                                                            <DropdownItem key="1" component="button"
                                                                                          onClick={() => this.onChangeConnectors("1")}>1</DropdownItem>,
                                                                            <DropdownItem key="2" component="button"
                                                                                          onClick={() => this.onChangeConnectors("2")}>2</DropdownItem>,
                                                                            <DropdownItem key="3" component="button"
                                                                                          onClick={() => this.onChangeConnectors("3")}>3</DropdownItem>,
                                                                            <DropdownItem key="4" component="button"
                                                                                          onClick={() => this.onChangeConnectors("4")}>4</DropdownItem>
                                                                        ]}
                                                                    />
                                                                </FormGroup>
                                                            </FlexItem>
                                                            <Divider isVertical/>
                                                            <FlexItem style={{width: '400px'}}>
                                                                <Title
                                                                    headingLevel="h3"
                                                                    size={TitleSizes['2xl']}
                                                                    className="TopSpacer"
                                                                >
                                                                    {t("fe_gateway-req_sim-card")}
                                                                </Title>
                                                                <FormGroup
                                                                    label={t("gateway_prop_simcard-provider")}
                                                                    isRequired
                                                                    fieldId="simcard-provider"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectProvider}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="simcard-provider"
                                                                                onToggle={this.onToggleProvidersDropDown}
                                                                                style={{width: "500px"}}
                                                                            >
                                                                                {this.state.registerSimCardProvider || t("fe_common_select-placeholder")}
                                                                            </DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.providersDropDownOpen}
                                                                        dropdownItems={[
                                                                            <DropdownItem
                                                                                key="1"
                                                                                component="button"
                                                                                onClick={() => this.onChangeProvider("1NCE")}
                                                                            >
                                                                                1NCE
                                                                            </DropdownItem>
                                                                        ]}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_simcard-number")}
                                                                    isRequired
                                                                    fieldId="iccid"
                                                                    className="TopSpacer"
                                                                >
                                                                    <TextInput
                                                                        type="text"
                                                                        id="iccid"
                                                                        name="iccid"
                                                                        value={this.state.registerSimCardNumber}
                                                                        onChange={this.onChangeSimCardNumber}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_simcard-type")}
                                                                    isRequired
                                                                    fieldId="simcard-type"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Dropdown
                                                                        onSelect={this.onSelectSimCardType}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="simcard-type"
                                                                                onToggle={this.onToggleSimCardTypeDropDown}
                                                                                style={{width: "500px"}}
                                                                            >
                                                                                {this.state.registerSimCardType || t("fe_common_select-placeholder")}
                                                                            </DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.typesDropDownOpen}
                                                                        dropdownItems={[
                                                                            <DropdownItem
                                                                                key="1"
                                                                                component="button"
                                                                                onClick={() => this.onChangeSimCardType("Mini")}
                                                                            >
                                                                                Mini
                                                                            </DropdownItem>
                                                                        ]}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup
                                                                    label={t("gateway_prop_simcard-valid-until")}
                                                                    isRequired
                                                                    fieldId="expires-at"
                                                                    className="TopSpacer"
                                                                >
                                                                    <Popover
                                                                        position="bottom"
                                                                        bodyContent={
                                                                            <CalendarMonth
                                                                                locale={t("language")}
                                                                                date={this.state.registerSimCardExpiresAt}
                                                                                onChange={(value) => this.onSelectExpiresAt(value, t)}
                                                                            />
                                                                        }
                                                                        showClose={false}
                                                                        isVisible={this.state.expiresAtOpen}
                                                                        hasNoPadding
                                                                        hasAutoWidth
                                                                    >
                                                                        <InputGroup>
                                                                            <TextInput
                                                                                type="text"
                                                                                id="expires-at"
                                                                                name="expires-at"
                                                                                isReadOnly
                                                                                value={this.state.registerSimCardExpiresAtFormatted || t("fe_common_select-placeholder")}
                                                                            />
                                                                            <Button variant="control"
                                                                                    onClick={this.onToggleExpiresAt}>
                                                                                <OutlinedCalendarAltIcon/>
                                                                            </Button>
                                                                        </InputGroup>
                                                                    </Popover>
                                                                </FormGroup>
                                                            </FlexItem>
                                                        </Flex>
                                                        <ActionGroup>
                                                            {
                                                                this.state.registerInProgress ? <Spinner isSVG/> : (
                                                                    <Button
                                                                        isDisabled={this.isRegisterButtonDisabled()}
                                                                        variant="primary"
                                                                        onClick={() => this.onRegister(t)}
                                                                    >
                                                                        {t("fe_common_register")}
                                                                    </Button>
                                                                )
                                                            }
                                                        </ActionGroup>
                                                    </Form>
                                                </Tab>
                                            </Tabs>
                                        </PageSection>
                                    </PageGroup>
                                </DrawerContentBody>
                            </DrawerContent>
                        </Drawer>
                    </>
                }
            </Translation>
        )
    }


    onTabSelect(event, tabIndex) {
        this.setState({
            activeTabKey: tabIndex,
            selectedRowIndex: -1
        }, () => {
            if (tabIndex === 0) {
                this.reloadGatewayList()
                this.refreshTimerId = window.setInterval(this.refreshGatewayList, 60 * 1_000)
            } else if (this.refreshTimerId) {
                window.clearInterval(this.refreshTimerId)
                this.refreshTimerId = false
            }
        })

    }

    refreshGatewayList() {
        const selectedGatewayId = this.state.selectedRowIndex !== -1 ? this.state.gateways[this.state.selectedRowIndex].serialNumber : false
        this.reloadGatewayList(selectedGatewayId)
    }

    reloadGatewayList(selectedGatewayId) {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.gateways = data
                            this.setState(prevState => {
                                const filteredAndSortedGateways = GatewayList.sortGateways(GatewayList.filterGateways(data, prevState.filter), prevState.activeSortIndex, prevState.activeSortDirection)
                                return {
                                    selectedRowIndex: filteredAndSortedGateways.length === 1 ? 0 : selectedGatewayId ? filteredAndSortedGateways.findIndex(g => g.serialNumber === selectedGatewayId) : -1,
                                    gateways: filteredAndSortedGateways,
                                    detailsReloadTrigger: !prevState.detailsReloadTrigger
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onRowClick(event, rowIndex) {
        this.setState(prevState => {
            return {
                selectedRowIndex: prevState.selectedRowIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    drawerCloseClicked() {
        this.setState({selectedRowIndex: -1});
    }

    onSort(event, index, direction) {
        this.setState(prevState => {
            return {
                selectedRowIndex: -1,
                activeSortIndex: index,
                activeSortDirection: direction,
                gateways: GatewayList.sortGateways(prevState.gateways, index, direction)
            }
        })
    }

    filterClicked(isSelected, event) {
        const id = event.currentTarget.id
        this.setState(prevState => {
            prevState.filter[id] = isSelected
            const filteredAndSortedGateways = GatewayList.sortGateways(GatewayList.filterGateways(this.gateways, prevState.filter), prevState.activeSortIndex, prevState.activeSortDirection)
            return {
                selectedRowIndex: filteredAndSortedGateways.length === 1 ? 0 : -1,
                filter: prevState.filter,
                gateways: filteredAndSortedGateways
            }
        })
    }

    onChangeSearchString(value) {
        this.setState(prevState => {
            prevState.filter.searchString = value
            const filteredAndSortedGateways = GatewayList.sortGateways(GatewayList.filterGateways(this.gateways, prevState.filter), prevState.activeSortIndex, prevState.activeSortDirection)
            return {
                selectedRowIndex: filteredAndSortedGateways.length === 1 ? 0 : -1,
                filter: prevState.filter,
                gateways: filteredAndSortedGateways
            }
        })
    }

    onChangeSerialNumber(value) {
        if (value === "" || value > 0) {
            this.setState({registerSerialNumber: value})
        }
    }

    onSelectManufacturedAt(newValueDate, t) {
        this.setState({
            registerManufacturedAt: newValueDate,
            registerManufacturedAtFormatted: formatDate(newValueDate, t),
            manufacturedAtOpen: false
        })
    }

    onToggleManufacturedAt() {
        this.setState(prevState => {
            return {
                manufacturedAtOpen: !prevState.manufacturedAtOpen
            }
        })
    }

    onChangeCharge(value) {
        if (value > 0) {
            this.setState({registerCharge: value})
        }
    }

    onSelectHardwareVersion() {
        this.setState({
            hardwareVersionDropDownOpen: false
        })
    }

    onToggleHardwareVersionDropDown() {
        this.setState(prevState => {
            return {
                hardwareVersionDropDownOpen: !prevState.hardwareVersionDropDownOpen
            }
        })
    }

    onChangeHardwareVersion(value) {
        this.setState({registerHardwareVersion: value})
    }

    onSelectConnectors() {
        this.setState({
            connectorsDropDownOpen: false
        })
    }

    onToggleConnectorsDropDown() {
        this.setState(prevState => {
            return {
                connectorsDropDownOpen: !prevState.connectorsDropDownOpen
            }
        })
    }

    onChangeConnectors(value) {
        this.setState({
            registerConnectors: value
        })
    }

    onChangeSimCardNumber(value) {
        this.setState({
            registerSimCardNumber: value
        })
    }

    onSelectProvider() {
        this.setState({
            providersDropDownOpen: false
        })
    }

    onToggleProvidersDropDown() {
        this.setState(prevState => {
            return {
                providersDropDownOpen: !prevState.providersDropDownOpen
            }
        })
    }

    onChangeProvider(value) {
        this.setState({
            registerSimCardProvider: value
        })
    }

    onSelectSimCardType() {
        this.setState({
            typesDropDownOpen: false
        })
    }

    onToggleSimCardTypeDropDown() {
        this.setState(prevState => {
            return {
                typesDropDownOpen: !prevState.typesDropDownOpen
            }
        })
    }

    onChangeSimCardType(value) {
        this.setState({
            registerSimCardType: value
        })
    }

    onSelectExpiresAt(newValueDate, t) {
        this.setState({
            registerSimCardExpiresAt: newValueDate,
            registerSimCardExpiresAtFormatted: formatDate(newValueDate, t),
            expiresAtOpen: false
        })
    }

    onToggleExpiresAt() {
        this.setState(prevState => {
            return {
                expiresAtOpen: !prevState.expiresAtOpen
            }
        })
    }

    isRegisterButtonDisabled() {
        return this.state.registerManufacturedAt === null ||
            this.state.registerCharge === "" ||
            this.state.registerHardwareVersion === "" ||
            this.state.registerConnectors === "" ||
            this.state.registerSimCardExpiresAt === null ||
            this.state.registerSimCardType === "" ||
            this.state.registerSimCardProvider === "" ||
            this.state.registerSimCardNumber === ""
    }

    onRegister(t) {
        this.setState({registerInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                number: this.state.registerSerialNumber ? this.state.registerSerialNumber : null,
                connectors: this.state.registerConnectors,
                manufacturedAt: date2ISOString(this.state.registerManufacturedAt),
                charge: this.state.registerCharge,
                version: this.state.registerHardwareVersion,
                simCardNumber: this.state.registerSimCardNumber,
                simProvider: this.state.registerSimCardProvider,
                simType: this.state.registerSimCardType,
                simExpiry: date2ISOString(this.state.registerSimCardExpiresAt)
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/register`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 201) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(payload => this.setState({
                            successMessage: t("fe_gateways_config-success").replace("%", payload.serialNumber),
                            registerSerialNumber: "",
                            registerManufacturedAt: null,
                            registerManufacturedAtFormatted: "",
                            registerCharge: "",
                            registerHardwareVersion: "",
                            registerConnectors: "",
                            registerSimCardNumber: "",
                            registerSimCardProvider: "",
                            registerSimCardType: "",
                            registerSimCardExpiresAt: null,
                            registerSimCardExpiresAtFormatted: ""
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.setState({successMessage: false}, () => this.props.app.showErrorMessage(error.message)))
            .finally(() => this.setState({registerInProgress: false}))
    }

    reloadGatewayDetails() {
        this.setState(prevState => {
            return {detailsReloadTrigger: !prevState.detailsReloadTrigger}
        })
    }
}

export default withRouter(GatewayList)