import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    Button,
    Card,
    CardBody,
    Drawer,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerPanelContent,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Text,
    Title,
    TitleSizes
} from "@patternfly/react-core";

import { SyncAltIcon } from "@patternfly/react-icons";

import MapContainer from "./MapContainer";
import GatewayDetails from "./GatewayDetails";
import { AppSettings } from "./AppSettings";

import "./Dashboard.css";

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            overview: {
                total: 0,
                provisioned: 0,
                outdated: 0,
                errors: 0
            },
            selectedMarkerId: false,
            refreshMarkers: 0,
            detailsReloadTrigger: false
        }

        this.reloadPageData = this.reloadPageData.bind(this)
        this.onCardClick = this.onCardClick.bind(this)
        this.onMarkerClicked = this.onMarkerClicked.bind(this)
        this.drawerCloseClicked = this.drawerCloseClicked.bind(this)
        this.showInGatewayList = this.showInGatewayList.bind(this)
    }

    componentDidMount() {
        this.reloadPageData()
        this.refreshTimerId = window.setInterval(this.reloadPageData, 60 * 1_000)
    }

    componentWillUnmount() {
        window.clearInterval(this.refreshTimerId)
    }

    render() {
        const drawerExpanded = this.state.selectedMarkerId !== false

        return (
            <Translation>
                { t =>
                    <Drawer isExpanded={drawerExpanded} position="right">
                        <DrawerContent panelContent={
                            <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                {
                                    drawerExpanded && <GatewayDetails
                                        app={this.props.app}
                                        serialNumber={this.state.selectedMarkerId}
                                        reloadTrigger={this.state.detailsReloadTrigger}
                                        closeDrawer={this.drawerCloseClicked}
                                        showInGatewayList={this.showInGatewayList}
                                        addGatewayToStickyCharts={this.props.addGatewayToStickyCharts}
                                    />
                                }
                            </DrawerPanelContent>
                        }>
                            <DrawerContentBody>
                                <PageGroup>
                                    <PageSection variant={PageSectionVariants.light}>
                                        <Flex justifyContent={{default: "justifyContentSpaceBetween"}}>
                                            <FlexItem>
                                                <Title className="Title" headingLevel="h1">
                                                    {t("fe_welcome")}
                                                </Title>
                                            </FlexItem>
                                            <FlexItem>
                                                <Button variant="plain" aria-label="Refresh"
                                                        onClick={this.reloadPageData}><SyncAltIcon/></Button>
                                            </FlexItem>
                                        </Flex>
                                        <Grid hasGutter>
                                            <GridItem span={2} rowSpan={2}>
                                                <Card id="total" className="Card" isSelectable isFullHeight
                                                      onClick={this.onCardClick}>
                                                    <CardBody>
                                                        <Text>
                                                            {t("fe_dashboard_registered-gateways")}
                                                        </Text>
                                                        <Title headingLevel="h1" size={TitleSizes["4xl"]}>
                                                            {this.state.overview.total}
                                                        </Title>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem span={2} rowSpan={2}>
                                                <Card id="provisioned" className="Card" isSelectable isFullHeight
                                                      onClick={this.onCardClick}>
                                                    <CardBody>
                                                        <Text>
                                                            {t("fe_dashboard_operational-gateways")}
                                                        </Text>
                                                        <Title headingLevel="h1" size={TitleSizes["4xl"]}>
                                                            {this.state.overview.provisioned}
                                                        </Title>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem span={2} rowSpan={2}>
                                                <Card id="outdated" className="Card" isSelectable isFullHeight
                                                      onClick={this.onCardClick}>
                                                    <CardBody>
                                                        <Text>
                                                            {t("fe_dashboard_outdated-gateways")}
                                                        </Text>
                                                        <Title headingLevel="h1" size={TitleSizes["4xl"]}>
                                                            {this.state.overview.outdated}
                                                        </Title>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem span={2} rowSpan={2}>
                                                <Card id="warnings" className="Card" isSelectable isFullHeight
                                                      onClick={this.onCardClick}>
                                                    <CardBody>
                                                        <Text>{t("fe_dashboard_warning-gateways")}</Text>
                                                        <Title headingLevel="h1" size={TitleSizes["4xl"]}>
                                                            {this.state.overview.warnings}
                                                        </Title>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem span={2} rowSpan={2}>
                                                <Card id="errors" className="Card" isSelectable isFullHeight
                                                      onClick={this.onCardClick}>
                                                    <CardBody>
                                                        <Text>{t("fe_dashboard_error-gateways")}</Text>
                                                        <Title headingLevel="h1" size={TitleSizes["4xl"]}>
                                                            {this.state.overview.errors}
                                                        </Title>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem span={12} rowSpan={10}>
                                                <Card isFlat className="MapCard" isFullHeight>
                                                    <CardBody className="MapBody">
                                                        <MapContainer
                                                            app={this.props.app}
                                                            selectedMarkerId={this.state.selectedMarkerId}
                                                            refreshMarkers={this.state.refreshMarkers}
                                                            onMarkerClicked={this.onMarkerClicked}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                        </Grid>
                                    </PageSection>
                                </PageGroup>
                            </DrawerContentBody>
                        </DrawerContent>
                    </Drawer>
                }
            </Translation>
        )
    }

    onMarkerClicked(serialNumber) {
        this.setState({selectedMarkerId: serialNumber})
    }

    drawerCloseClicked() {
        this.setState({selectedMarkerId: false});
    }

    reloadPageData() {
        this.setState(prevState => {
            return {refreshMarkers: prevState.refreshMarkers+1}
        })

        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/overview`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState(prevState => {
                            return {
                                overview: data,
                                detailsReloadTrigger: !prevState.detailsReloadTrigger
                            }
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onCardClick(event) {
        const id = event.currentTarget.id
        this.props["history"].push(`${AppSettings.rootPath}/sensorGateways?filter=${id}`)
    }

    showInGatewayList() {
        const id = this.state.selectedMarkerId
        this.props["history"].push(`${AppSettings.rootPath}/sensorGateways?filter=q:${id}`)
    }
}

export default withRouter(Dashboard)