import React, { Component } from "react";

import { Translation } from "react-i18next";

import {
    Alert,
    Brand,
    Bullseye,
    Button,
    Card,
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardHeaderMain,
    CardTitle,
    Form,
    FormGroup,
    Hint,
    HintBody, Modal, ModalVariant,
    Spinner,
    TextInput
} from "@patternfly/react-core";

import { UserIcon } from "@patternfly/react-icons";

import {
    bake_cookie,
    delete_cookie,
    read_cookie
} from "sfcookies";

import { AppSettings } from "./AppSettings";
import App from "./App";

import logo from "./images/fischer_logo.svg";

import "./Authenticate.css";

class AuthenticatedApp extends Component {
    static COOKIE_NAME = "iot-backoffice"

    static readCookie() {
        const cookie = read_cookie(AuthenticatedApp.COOKIE_NAME)
        if (Array.isArray(cookie)) {
            return false
        }
        return cookie
    }

    constructor(props) {
        super(props)

        this.emailAddressRef = React.createRef()

        this.state = {
            emailAddress: "",
            password: "",
            userLogin: false,
            errorMessage: false,
            authInProgress: false,
            sessionExpired: false
        }

        this.isLoginButtonDisabled = this.isLoginButtonDisabled.bind(this)
        this.signIn = this.signIn.bind(this)
        this.signOut = this.signOut.bind(this)
        this.sessionExpired = this.sessionExpired.bind(this)
        this.onChangeEmailAddress = this.onChangeEmailAddress.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.renderErrorMessageModal = this.renderErrorMessageModal.bind(this)
        this.showErrorMessage = this.showErrorMessage.bind(this)
    }

    componentDidMount() {
        const userLogin = AuthenticatedApp.readCookie()
        if (userLogin) {
            userLogin.signOut = this.signOut
            userLogin.sessionExpired = this.sessionExpired
            userLogin.showErrorMessage = this.showErrorMessage
        }
        this.setState({
            userLogin: userLogin
        }, () => {
            if (!this.state.userLogin && this.emailAddressRef && this.emailAddressRef.current) {
                document.addEventListener('keydown', this.onKeyDown)
                this.emailAddressRef.current.focus()
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.userLogin && !prevState.errorMessage && this.state.errorMessage) {
            this.emailAddressRef.current.select()
            this.emailAddressRef.current.focus()
        }
    }

    signIn() {
        this.setState({authInProgress: true})

        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                emailAddress: this.state.emailAddress,
                password: this.state.password
            })
        }
        fetch(`${AppSettings.rootPath}/v1/users/login`, requestInit)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status === 409 ? "fe_login_authorization-error" : "fe_login_authentication-error")
                }
                return response.json()
            })
            .then(data => {
                bake_cookie(AuthenticatedApp.COOKIE_NAME, data)
                data.signOut = this.signOut
                data.sessionExpired = this.sessionExpired
                data.showErrorMessage = this.showErrorMessage
                this.setState({
                    authInProgress: false,
                    sessionExpired: false,
                    errorMessage: false,
                    userLogin: data,
                    emailAddress: "",
                    password: ""
                })
            })
            .catch((error) => this.setState({
                authInProgress: false,
                errorMessage: error.message || "fe_login_error",
                password: ""
            }, () => {
                document.addEventListener('keydown', this.onKeyDown)
            }))
    }

    signOut(expired) {
        localStorage.clear()
        delete_cookie(AuthenticatedApp.COOKIE_NAME)
        this.setState(
            {
                userLogin: false,
                sessionExpired: expired
            }, () => {
            document.addEventListener('keydown', this.onKeyDown)
            this.emailAddressRef.current.focus()
        })
    }

    sessionExpired() {
        this.signOut(true)
    }

    render() {
        if (this.state.userLogin) {
            return (
                <>
                    <App app={this.state.userLogin}/>
                    {
                        this.state.errorMessage && this.renderErrorMessageModal()
                    }
                </>
            )
        }

        return (
            <Translation>
                { t =>
                    <Bullseye className="LoginPage">
                        <Card isHoverable={true}>
                            <CardHeader>
                                <CardHeaderMain>
                                    <Brand src={logo} alt="fischer Logo"/>
                                </CardHeaderMain>
                                <CardActions>
                                </CardActions>
                            </CardHeader>
                            <CardTitle>{t("fe_welcome")}</CardTitle>
                            {
                                this.state.authInProgress ? (
                                    <CardBody><Bullseye><Spinner isSVG/></Bullseye></CardBody>
                                ) : (
                                    <>
                                        <CardBody>
                                            {
                                                this.state.errorMessage ?
                                                    <Alert variant="danger" isInline
                                                           title={t(this.state.errorMessage)}/> :
                                                    <Hint><HintBody style={{whiteSpace: "pre-line"}}>
                                                        {this.state.sessionExpired ? t("fe_common_auto-signed-out") : t("fe_login_hint")}
                                                    </HintBody></Hint>
                                            }
                                            <Form className="TopSpacer">
                                                <FormGroup label={t("fe_login_email")} isRequired fieldId="email-address">
                                                    <TextInput
                                                        type="text" id="email-address"
                                                        name="email-address"
                                                        value={this.state.emailAddress}
                                                        onChange={this.onChangeEmailAddress}
                                                        ref={this.emailAddressRef}
                                                    />
                                                </FormGroup>
                                                <FormGroup label={t("fe_login_password")} isRequired fieldId="password">
                                                    <TextInput
                                                        type="password" id="password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={this.onChangePassword}
                                                    />
                                                </FormGroup>

                                            </Form>
                                        </CardBody>
                                        <CardFooter>
                                            <Button
                                                isDisabled={this.isLoginButtonDisabled()}
                                                onClick={this.signIn}><UserIcon/>{t("fe_login")}</Button>
                                        </CardFooter>
                                    </>
                                )
                            }
                        </Card>
                    </Bullseye>
                }
            </Translation>
        )
    }

    renderErrorMessageModal() {
        return (
            <Translation>
                { t =>
                    <Modal
                        variant={ModalVariant.small}
                        isOpen={true}
                        onClose={() => this.setState({errorMessage: false})}
                        title={t("fe_common_error-title")}
                        actions={[
                            <Button
                                key="confirm"
                                variant="primary"
                                onClick={() => this.setState({errorMessage: false})}
                            >
                                {t("fe_common_ok")}
                            </Button>
                        ]}
                    >
                        {this.state.errorMessage}
                    </Modal>
                }
            </Translation>
        )
    }

    isLoginButtonDisabled() {
        return this.state.authInProgress || !this.state.emailAddress || !this.state.password
    }

    onChangeEmailAddress(value) {
        this.setState({emailAddress: value})
    }

    onChangePassword(value) {
        this.setState({password: value})
    }

    onKeyDown(event) {
        if (event.key === 'Enter' && !this.isLoginButtonDisabled()) {
            event.preventDefault()
            document.removeEventListener('keydown', this.onKeyDown)
            this.signIn()
        }
    }

    showErrorMessage(message) {
        // console.trace(message)
        this.setState({
            errorMessage: message
        })
    }
}

export default AuthenticatedApp
