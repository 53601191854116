import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    Button,
    Card,
    Drawer,
    DrawerActions,
    DrawerCloseButton,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelContent,
    PageGroup,
    PageSection,
    PageSectionVariants,
    SearchInput,
    Text,
    TextVariants,
    Title,
    ToggleGroup,
    ToggleGroupItem
} from "@patternfly/react-core";

import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";

import {
    BanIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    SyncAltIcon,
    UnknownIcon
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";
import SimCardDetails from "./SimCardDetails";
import queryString from "query-string";

import "./TableIcons.css";

class SimCardList extends Component {
    static COLUMNS = [
        {field: "cardNumber", label: "simcard_number", width: 10},
        {field: "status", label: "simcard_data-usage", width: 20},
        {field: "localizedExpiry", label: "simcard_expiry", width: 10},
        {field: "provider", label: "simcard_provider", width: 10},
        {field: "type", label: "simcard_type", width: 10},
        {field: "gatewayId", label: "fe_gateway", width: 10},
        {field: "customer", label: "fe_common_customer", width: 30}
    ]

    static sortCards(simCards, providerStatus, index, direction) {
        const sortKey = SimCardList.COLUMNS[index].field === "localizedExpiry" ? "isoExpiry" : SimCardList.COLUMNS[index].field
        if (sortKey === "status") {
            return simCards.sort((g1, g2) => {
                const s1 = (providerStatus[g1["cardNumber"]] || {})["label"] || ""
                const s2 = (providerStatus[g2["cardNumber"]] || {})["label"] || ""
                if (direction === "asc") {
                    return s1.localeCompare(s2)
                }
                return s2.localeCompare(s1)
            })
        }
        return simCards.sort((g1, g2) => {
            if (direction === "asc") {
                return g1[sortKey].localeCompare(g2[sortKey])
            }
            return g2[sortKey].localeCompare(g1[sortKey])
        })
    }

    static filterCards(simCards, providerStatus, filter) {
        return simCards.filter(card => {
            const cardNumber = card["cardNumber"]
            return (
                (!filter.critical || (providerStatus[cardNumber] && providerStatus[cardNumber]["enum"] === "QuotaExceeded")) &&
                (!filter.warning || (providerStatus[cardNumber] && providerStatus[cardNumber]["enum"] === "QuotaWarning")) &&
                (!filter.searchString || cardNumber.includes(filter.searchString))
            )
        })
    }

    static status2Icon(status) {
        if (status) {
            switch (status["enum"]) {
            case "Disabled":
                return <BanIcon className="Unknown"/>
            case "QuotaWarning":
                return <ExclamationTriangleIcon className="Warning"/>
            case "QuotaExceeded":
                return <ExclamationCircleIcon className="Danger"/>
            case "Error":
                return <UnknownIcon className="Unknown"/>
            case "Operational":
                return <CheckCircleIcon className="Success"/>
            default:
                return <UnknownIcon className="Unknown"/>
            }
        }
        return ""
    }

    constructor(props) {
        super(props)

        this.simCards = []

        this.state = {
            simCards: [],
            providerStatus: {},
            selectedRowIndex: -1,
            activeSortIndex: 0,
            activeSortDirection: 'asc',
            filter: {
                critical: false,
                warning: false,
                searchString: ""
            },
            reloadTrigger: false
        }

        this.reloadPageData = this.reloadPageData.bind(this)
        this.reloadSimCards = this.reloadSimCards.bind(this)
        this.reloadProviderStatus = this.reloadProviderStatus.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onSort = this.onSort.bind(this)
        this.filterClicked = this.filterClicked.bind(this)
        this.drawerCloseClicked = this.drawerCloseClicked.bind(this)
        this.onChangeSearchString = this.onChangeSearchString.bind(this)
        this.reloadSimCardDetails = this.reloadSimCardDetails.bind(this)
    }

    componentDidMount() {
        const filter = queryString.parse(this.props["location"].search).filter || false
        this.setState({
            filter: {
                critical: false,
                warning: false,
                searchString: filter && filter.startsWith("q:") ? filter.substring(2) : ""
            }
        })

        this.reloadPageData()
    }

    render() {
        const drawerExpanded = this.state.selectedRowIndex !== -1
        const cardProvider = drawerExpanded ? this.state.simCards[this.state.selectedRowIndex]["provider"] : ""
        const cardNumber = drawerExpanded ? this.state.simCards[this.state.selectedRowIndex]["cardNumber"] : ""

        return (
            <Translation>
                { t =>
                    <Drawer isExpanded={drawerExpanded} position="right">
                        <DrawerContent panelContent={
                            <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                <DrawerHead>
                                    <Title headingLevel="h1">{t("fe_gateway-section_sim-card")}</Title>
                                    <Text component={TextVariants.h6}>{cardProvider} {cardNumber}</Text>
                                    <DrawerActions>
                                        <Button variant="plain" onClick={this.reloadSimCardDetails}><SyncAltIcon/></Button>
                                        <DrawerCloseButton onClick={this.drawerCloseClicked}/>
                                    </DrawerActions>
                                </DrawerHead>
                                {
                                    drawerExpanded && <SimCardDetails app={this.props.app} cardNumber={cardNumber} reloadTrigger={this.state.reloadTrigger} />
                                }
                            </DrawerPanelContent>
                        }>
                            <DrawerContentBody>
                                <PageGroup>
                                    <PageSection variant={PageSectionVariants.light}>
                                        <Title className="Title" headingLevel="h1">SIM Karten</Title>
                                        <ToggleGroup aria-label="Filter items">
                                            <ToggleGroupItem text={t("simcard_status_no-credit")} buttonId="critical"
                                                             isSelected={this.state.filter.critical}
                                                             onChange={this.filterClicked}/>
                                            <ToggleGroupItem text={t("simcard_filter_low-credit")} buttonId="warning"
                                                             isSelected={this.state.filter.warning}
                                                             onChange={this.filterClicked}/>
                                            <SearchInput
                                                className="SearchInput"
                                                placeholder={t("simcard_number")}
                                                value={this.state.filter.searchString}
                                                onChange={this.onChangeSearchString}
                                                onClear={e => this.onChangeSearchString('', e)}
                                            />
                                        </ToggleGroup>
                                        <Card component="div">
                                            <TableComposable variant="compact" borders={true} isStickyHeader>
                                                <Thead>
                                                    <Tr>
                                                        {
                                                            SimCardList.COLUMNS.map((column, index) => {
                                                                const sortParams = {
                                                                    sort: {
                                                                        sortBy: {
                                                                            index: this.state.activeSortIndex,
                                                                            direction: this.state.activeSortDirection
                                                                        },
                                                                        onSort: this.onSort,
                                                                        columnIndex: index
                                                                    }
                                                                };
                                                                return <Th key={index} {...sortParams}
                                                                           width={column.width}>{t(column.label)}</Th>
                                                            })
                                                        }
                                                        <Th key="refresh" className="RefreshButton">
                                                            <Button variant="plain" isInline aria-label="Refresh"
                                                                    onClick={this.reloadPageData}><SyncAltIcon/></Button>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        this.state.simCards.map((row, rowIndex) => {
                                                            return (
                                                                <Tr isHoverable key={rowIndex}
                                                                    onRowClick={event => this.onRowClick(event, rowIndex)}
                                                                    isRowSelected={this.state.selectedRowIndex === rowIndex}>
                                                                    <Td key={`${rowIndex}_0`}
                                                                        dataLabel={t(SimCardList.COLUMNS[0].label)}>
                                                                        {row["cardNumber"]}
                                                                    </Td>
                                                                    <Td key={`${rowIndex}_1`}
                                                                        dataLabel={t(SimCardList.COLUMNS[1].label)}>
                                                                        {SimCardList.status2Icon(this.state.providerStatus[row["cardNumber"]])}
                                                                        {this.state.providerStatus[row["cardNumber"]] && this.state.providerStatus[row["cardNumber"]].label}
                                                                    </Td>
                                                                    <Td key={`${rowIndex}_2`}
                                                                        dataLabel={t(SimCardList.COLUMNS[2].label)}>
                                                                        {row["localizedExpiry"]}
                                                                    </Td>
                                                                    <Td key={`${rowIndex}_3`}
                                                                        dataLabel={t(SimCardList.COLUMNS[3].label)}>
                                                                        {row["provider"]}
                                                                    </Td>
                                                                    <Td key={`${rowIndex}_4`}
                                                                        dataLabel={t(SimCardList.COLUMNS[4].label)}>
                                                                        {row["type"]}
                                                                    </Td>
                                                                    <Td key={`${rowIndex}_5`}
                                                                        dataLabel={t(SimCardList.COLUMNS[5].label)}>
                                                                        {row["gatewayId"]}
                                                                    </Td>
                                                                    <Td key={`${rowIndex}_6`}
                                                                        dataLabel={t(SimCardList.COLUMNS[6].label)}>
                                                                        {row["customer"]}
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })
                                                    }
                                                </Tbody>
                                            </TableComposable>
                                        </Card>
                                    </PageSection>
                                </PageGroup>
                            </DrawerContentBody>
                        </DrawerContent>
                    </Drawer>
                }
            </Translation>
        )
    }

    reloadPageData() {
        this.reloadSimCards()
        this.reloadProviderStatus()
    }

    reloadSimCards() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simCards/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.simCards = data
                            this.setState(prevState => {
                                const filteredAndSortedSimCards = SimCardList.sortCards(SimCardList.filterCards(data, prevState.providerStatus, prevState.filter), prevState.providerStatus, prevState.activeSortIndex, prevState.activeSortDirection)
                                return {
                                    selectedRowIndex: filteredAndSortedSimCards.length === 1 ? 0 : -1,
                                    simCards: filteredAndSortedSimCards
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    reloadProviderStatus() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simCards/list/status/1nce`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState(prevState => {
                            return {
                                simCards: SimCardList.sortCards(SimCardList.filterCards(this.simCards, data, prevState.filter), data, prevState.activeSortIndex, prevState.activeSortDirection),
                                providerStatus: data
                            }
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onRowClick(event, rowIndex) {
        this.setState(prevState => {
            return {
                selectedRowIndex: prevState.selectedRowIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    drawerCloseClicked() {
        this.setState({selectedRowIndex: -1});
    }

    onSort(event, index, direction) {
        this.setState(prevState => {
            return {
                selectedRowIndex: -1,
                activeSortIndex: index,
                activeSortDirection: direction,
                simCards: SimCardList.sortCards(prevState.simCards, prevState.providerStatus, index, direction)
            }
        })
    }

    filterClicked(isSelected, event) {
        const id = event.currentTarget.id
        this.setState(prevState => {
            prevState.filter[id] = isSelected
            const filteredAndSortedSimCards = SimCardList.sortCards(SimCardList.filterCards(this.simCards, prevState.providerStatus, prevState.filter), prevState.providerStatus, prevState.activeSortIndex, prevState.activeSortDirection)
            return {
                selectedRowIndex: filteredAndSortedSimCards.length === 1 ? 0 : -1,
                filter: prevState.filter,
                simCards: filteredAndSortedSimCards
            }
        })
    }

    onChangeSearchString(value) {
        this.setState(prevState => {
            prevState.filter.searchString = value
            const filteredAndSortedSimCards = SimCardList.sortCards(SimCardList.filterCards(this.simCards, prevState.providerStatus, prevState.filter), this.state.providerStatus, prevState.activeSortIndex, prevState.activeSortDirection)
            return {
                selectedRowIndex: filteredAndSortedSimCards.length === 1 ? 0 : -1,
                filter: prevState.filter,
                simCards: filteredAndSortedSimCards
            }
        })
    }

    reloadSimCardDetails() {
        this.setState(prevState => {
            return {reloadTrigger: !prevState.reloadTrigger}
        })
    }
}

export default withRouter(SimCardList)