import React from "react";

import {
    GoogleMap,
    Marker,
    useJsApiLoader
} from '@react-google-maps/api';

import { AppSettings } from "./AppSettings";

const containerStyle = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 368px)"
}

const initialCenter= {
    lat: 30,
    lng: 0
}

const mapStyle = [
    {
        "elementType": "geometry",
        "stylers": [{"color": "#f5f5f5"}]
    },
    {
        "elementType": "labels",
        "stylers": [{"visibility": "off"}]
    },
    {
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#616161"}]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [{"color": "#f5f5f5"}]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#989898"}, {"visibility": "on"}, {"weight": 0.5}]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#bdbdbd"}]
    },
    {
        "featureType": "administrative.neighborhood",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "poi",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#eeeeee"}]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#757575"}]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{"color": "#e5e5e5"}]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#9e9e9e"}]
    },
    {
        "featureType": "road",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{"color": "#ffffff"}]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#757575"}]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{"color": "#dadada"}]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#616161"}]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#9e9e9e"}]
    },
    {
        "featureType": "transit",
        "stylers": [{"visibility": "off"}]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{"color": "#e5e5e5"}]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [{"color": "#eeeeee"}]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{"color": "#c9c9c9"}]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#bfbfbf"}, {"visibility": "on"}]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#9e9e9e"}]
    }
]

const STATUS_ICONS_COLOR_SELECTED = "black"
const STATUS_ICONS_UNSELECTED = {
    "WaitingForFeedback": pinSymbol(status2Color("WaitingForFeedback")),
    "SleepDesired": pinSymbol(status2Color("SleepDesired")),
    "Setup": pinSymbol(status2Color("Setup")),
    "FirmwareUpgrade": pinSymbol(status2Color("FirmwareUpgrade")),
    "RebootDesired": pinSymbol(status2Color("RebootDesired")),
    "Sleep": pinSymbol(status2Color("Sleep")),
    "Operational": pinSymbol(status2Color("Operational")),
    "IncompleteAnchorSettings": pinSymbol(status2Color("IncompleteAnchorSettings")),
    "Warning": pinSymbol(status2Color("Warning")),
    "Offline": pinSymbol(status2Color("Offline")),
    "Error": pinSymbol(status2Color("Error"))
}
const STATUS_ICONS_SELECTED = {
    "WaitingForFeedback": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "SleepDesired": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "Setup": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "FirmwareUpgrade": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "RebootDesired": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "Sleep": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "Operational": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "IncompleteAnchorSettings": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "Warning": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "Offline": pinSymbol(STATUS_ICONS_COLOR_SELECTED),
    "Error": pinSymbol(STATUS_ICONS_COLOR_SELECTED)
}

function status2Color(statusEnum) {
    switch (statusEnum) {
    case "WaitingForFeedback":
    case "SleepDesired":
    case "Setup":
    case "FirmwareUpgrade":
    case "RebootDesired":
        return "#73bcf7"
    case "Sleep":
    case "Operational":
        return "#6ec664"
    case "IncompleteAnchorSettings":
        return "#f6d173"
    case "Warning":
        return "#ec7a08"
    case "Offline":
    case "Error":
        return "#c9190b"
    default:
        return "#b8bbbe"
    }
}

function pinSymbol(color) {
    return {
        path: "M 0 0 C -7 -10 -10 -13 -10 -18 A 10 10 0 1 1 10 -18 C 10 -13 7 -10 0 0 z",
        fillColor: color,
        fillOpacity: 1,
        strokeColor: "white",
        strokeWeight: 1,
        scale: 1
    }
}

function MapContainer({app, selectedMarkerId, refreshMarkers, onMarkerClicked}) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA-CVUOAPKNApL92JRr7hRicGr8UyfN4tU"
    })

    // const [map, setMap] = React.useState(null)
    const [markers, setMarkers] = React.useState([])

    const reloadMapMarkers = React.useCallback(() => {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/mapMarkers`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            setMarkers(data)
                            if (selectedMarkerId !== false && data.find(i => i.serialNumber) === null) {
                                onMarkerClicked(false)
                            }
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => app.showErrorMessage(error.message))
    }, [app, selectedMarkerId, onMarkerClicked])

    const onLoad = React.useCallback(function callback(map) {
        map.setOptions({
            center: initialCenter,
            styles: mapStyle
        })
    }, [])

    React.useEffect(() => {
        reloadMapMarkers()
    }, [refreshMarkers, reloadMapMarkers])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={initialCenter}
            zoom={3}
            onLoad={onLoad}
            options={{streetViewControl: false}}
        >
            {
                markers.map(iotDeviceInfo => (
                    <Marker
                        key={iotDeviceInfo["serialNumber"]}
                        position={iotDeviceInfo}
                        icon={selectedMarkerId === iotDeviceInfo.serialNumber ? STATUS_ICONS_SELECTED[iotDeviceInfo["statusEnum"]] : STATUS_ICONS_UNSELECTED[iotDeviceInfo["statusEnum"]]}
                        onClick={() => {
                            if (selectedMarkerId === iotDeviceInfo.serialNumber) {
                                onMarkerClicked(false)
                            } else {
                                onMarkerClicked(iotDeviceInfo.serialNumber)
                            }
                        }}
                        zIndex={selectedMarkerId === iotDeviceInfo.serialNumber ? 2 : 1}
                    />
                ))
            }
        </GoogleMap>
    ) : <div>Loading...</div>
}

export default React.memo(MapContainer)