import React from "react";

import { Doughnut } from "react-chartjs-2";

const Gauge = ({diameter, color, rangeStart, rangeEnd, value, formattedValue, label}) => {
    const [gaugeValue, setGaugeValue] = React.useState(Math.min(Math.max(value, rangeStart), rangeEnd))
    const [chartData, setChartData] = React.useState({
        labels: [],
        datasets: [{
            data: []
        }]
    })

    React.useEffect(() => {
        setGaugeValue(Math.min(Math.max(value, rangeStart), rangeEnd))
    }, [value, setGaugeValue, rangeStart, rangeEnd])

    React.useEffect(() => {
        setChartData({
            labels: [],
            datasets: [{
                label: label,
                data: [gaugeValue - rangeStart, rangeEnd - gaugeValue],
                backgroundColor: [color, 'rgb(222,222,222)']
            }]
        })
    }, [color, rangeStart, rangeEnd, gaugeValue, label])

    const gaugeNeedle = {
        id: "gaugeNeedle",
        afterDatasetDraw(chart, args) {
            const {
                ctx,
                chartArea: { width, height }
            } = chart

            const cx = width / 2
            const cy = height - height / 4
            const fontSize = Math.round(width / 8)

            const { meta } = args
            const [ firstArc ] = meta.data

            if (firstArc === undefined) return

            if (value >= rangeStart && value <= rangeEnd) {
                const needleAngle = firstArc.endAngle
                const needleOffset = width / 3.5
                const needleThickness = width / 100

                ctx.save()
                ctx.translate(cx, cy)
                ctx.rotate(needleAngle)
                ctx.lineWidth = needleThickness
                ctx.strokeStyle = color
                ctx.lineCap = "butt"
                ctx.lineJoin = "miter"
                ctx.miterLimit = 20
                ctx.beginPath()
                ctx.moveTo(needleOffset, needleThickness)
                ctx.lineTo(width / 2.6, 0)
                ctx.lineTo(needleOffset, -needleThickness)
                ctx.closePath()
                ctx.stroke()
                ctx.restore()
            }

            ctx.save()
            ctx.textAlign = "center"
            ctx.font = `${fontSize}px Helvetica`
            ctx.fillStyle = color
            ctx.fillText(formattedValue, cx, cy - fontSize/2);
            ctx.restore()
        }
    }

    return <div style={{maxWidth: `${diameter}px`, height: `${diameter/2}px`, marginTop: `${-diameter/4}px`}}>
        <Doughnut
            data={chartData}
            options={{
                borderWidth: 0,
                circumference: 180,
                rotation: 270,
                cutout: "90%",
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            }}
            plugins={[gaugeNeedle]}
        />
    </div>
}

export default Gauge