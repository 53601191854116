import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next';

import {
    Button,
    Card,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    Dropdown,
    DropdownItem,
    DropdownPosition,
    EmptyState,
    EmptyStateIcon,
    Flex,
    FlexItem,
    Menu,
    MenuItem,
    MenuList,
    MenuToggle,
    Modal,
    ModalVariant,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Popper,
    SearchInput,
    Tab,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    Title,
    TitleSizes,
    ToggleGroup,
    ToggleGroupItem,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem
} from "@patternfly/react-core";
import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";
import {
    BanIcon,
    CheckCircleIcon,
    CogIcon,
    EnterpriseIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    ListIcon,
    OutlinedCalendarAltIcon,
    PlayIcon,
    SyncAltIcon,
    TimesIcon,
    UserIcon
} from "@patternfly/react-icons";

import { DateRangePicker } from "react-date-range";
import { de, enUS, fr } from "date-fns/locale";
import {
    startOfDay,
    endOfDay
} from "date-fns";
import {
    stickyChartsDateRanges,
    stickyChartsInputRanges
} from "./StickyChartsDateRanges";
import {
    date2ISOString,
    formatDateRange,
    formatNumber
} from "./Utils";
import streamSaver from "streamsaver";
import { AppSettings } from "./AppSettings";
import SensorDiscChart from "./SensorDiscChart";
import Gauge from "./Gauge";
import {
    LS_KEY_CHART_CONTENT,
    LS_KEY_FILL_AREA,
    LS_KEY_NATURAL_SCALING,
    LS_KEY_SHOW_DOTS,
    LS_KEY_SHOW_TEMPERATURES,
    LS_KEY_SMOOTH_LINES
} from "./LocalStorageKeys";

import "./SensorDiscPage.css";

class SensorDiscPage extends Component {
    static SUMMARY_COLUMNS = [
        {key: "discId", label: "fe_common_serial-number"},
        {key: "readyToShip", label: "fe_disc_ready-to-ship"},
        {key: "sapOrderNumber", label: "fe_common_order-number"},
        {key: "size", label: "fe_disc_size"},
        {key: "assemblyMeasurement", label: "fe_disc_assembly-measurement"},
        {key: "temperatureProfile", label: "fe_disc_temperature-profile"},
        {key: "finalCalibration", label: "fe_disc_final-calibration"},
        {key: "discOwner", label: "fe_disc_owner"},
        {key: "calibrationDate", label: "fe_disc_calibration-date"},
        {key: "operational", label: "fe_disc_operational"}
    ]

    static FIRST_PROVISIONING = [
        {key:"userName", label:"fe_nav_user"},
        {key:"userEmail", label:"fe_common_email"},
        {key:"companyName", label:"fe_common_company"},
        {key:"at", label:"fe_common_datetime"}
    ]

    static CURRENT_OWNER = [
        {key:"companyName", label:"fe_common_company"},
        {key:"customerId", label:"fe_common_customer-id"},
        {key:"userName", label:"fe_nav_user"},
        {key:"userEmail", label:"fe_common_email"}
    ]

    static CURRENT_SETTINGS = [
        {key:"discName", label:"fe_common_name"},
        {key:"torque", label:"fe_anchor_torque"},
        {key:"zeroSignal", label:"fe_disc_signal-load-free", statusKey:"zeroStatus"},
        {key:"loadSignal", label:"fe_disc_signal-nominal-load", statusKey:"loadStatus"},
        {key:"provisionedAt", label:"fe_common_created-on"},
        {key:"provisionedBy", label:"fe_common_created-by"}
    ]

    static LAST_MEASUREMENT = [
        {key:"lastSignal", label:"fe_disc_last-signal-load"},
        {key:"lastPercent", label:"fe_disc_last-percent-load"},
        {key:"lastTemperature", label:"fe_disc_last-temperature"},
        {key:"lastMeasuredAt", label:"fe_disc_measured-at"},
        {key:"lastMeasuredBy", label:"fe_disc_measured-by"}
    ]

    static ASSEMBLY_MEASUREMENT = [
        {key:"signal", label:"fe_disc_signal", okayKey:"signalOkay"},
        {key:"temperature", label:"fe_disc_temperature", okayKey:"temperatureOkay"},
        {key:"measuredAt", label:"fe_disc_measured-at"},
        {key:"measuredBy", label:"fe_disc_measured-by"},
        {key:"comment", label:"fe_disc_comment"}
    ]

    static TEMPERATURE_PROFILE_CALCULATED_VALUES = [
        {key:"uncompensatedDriftValue", label:"fe_disc_uncompensated-drift", okayKey:"uncompensatedDriftOkay"},
        {key:"compensatedDriftValue", label:"fe_disc_compensated-drift", okayKey:"compensatedDriftOkay"}
    ]

    static TEMPERATURE_PROFILE_MEASUREMENT = [
        {key:"signal", label:"fe_disc_signal", okayKey:"signalOkay"},
        {key:"temperature", label:"fe_disc_temperature"},
        {key:"measuredAt", label:"fe_disc_measured-at"},
        {key:"measuredBy", label:"fe_disc_measured-by"}
    ]

    static FINAL_CALIBRATION_METADATA = [
        {key:"size", label:"fe_disc_size"},
        {key:"nominalLoad", label:"fe_disc_nominal-load"},
        {key:"measuredAt", label:"fe_disc_measured-at"},
        {key:"measuredBy", label:"fe_disc_measured-by"}
    ]

    static FINAL_CALIBRATION_CALCULATED_VALUES = [
        {key:"nominalSignalDelta", label:"fe_disc_nominal-signal-delta"},
        {key:"zeroOffsetAfterCascade", label:"fe_disc_zero-offset-after-cascade"},
        {key:"linearDeviationCascade3", label:"fe_disc_linear-deviation-cascade-3"},
        {key:"linearDeviationCascade2", label:"fe_disc_linear-deviation-cascade-2"},
        {key:"linearDeviationCascade1", label:"fe_disc_linear-deviation-cascade-1"},
        {key:"linearDeviationAverage", label:"fe_disc_linear-deviation-average"}
    ]

    static FINAL_CALIBRATION_MEASUREMENT = [
        {key:"signal", label:"fe_disc_signal", okayKey:"signalOkay"},
        {key:"temperature", label:"fe_disc_temperature", okayKey:"temperatureOkay"}
    ]

    constructor(props) {
        super(props)

        this.discIdAutocompleteRef = React.createRef()
        this.discIdSearchInputRef = React.createRef()
        this.ownerAutocompleteRef = React.createRef()
        this.ownerSearchInputRef = React.createRef()
        this.orderNumberSearchInputRef = React.createRef()
        this.orderNumberAutocompleteRef = React.createRef()
        this.exportCsvActionRef = React.createRef()

        this.state = {
            discDataLoaded: false,
            serialNumber: false,
            selectedOwner: false,
            discSummaries: [],
            selectedSummaryIndex: -1,

            discIdSearchValue: "",
            discIdAutocompletePending: false,
            discIdAutocompleteOptions: [],
            discIdAutocompleteOpen: false,

            matchingOwners: [],
            ownerSearchValue: "",
            ownerAutocompletePending: false,
            ownerAutocompleteOptions: [],
            ownerAutocompleteOpen: false,

            matchingOrderNumbers: [],
            orderNumberSearchValue: "",
            orderNumberAutocompletePending: false,
            orderNumberAutocompleteOptions: [],
            orderNumberAutocompleteOpen: false,

            dateFilterLabel: "",
            showDateRangeModal: false,
            dateRangeModalRanges: [{
                startDate: startOfDay(new Date()),
                endDate: endOfDay(new Date()),
                key: "selection"
            }],
            dateFilterStart: false,
            dateFilterEnd: false,

            exportCsvMenuOpen: false,
            activeTabKey: 0,
            metaData: {},
            calibrationData: {},
            operationData: {},
            confirmResetFirstProvisioning: false,
            confirmDecommission: false,
            chartReloadTrigger: false,
            chartContent: localStorage.getItem(LS_KEY_CHART_CONTENT) || "percent",
            chartShowTemperature: localStorage.getItem(LS_KEY_SHOW_TEMPERATURES) !== "n",
            chartNaturalScaling: localStorage.getItem(LS_KEY_NATURAL_SCALING) === "y",
            chartSmoothLines: localStorage.getItem(LS_KEY_SMOOTH_LINES) === "y",
            chartFillArea: localStorage.getItem(LS_KEY_FILL_AREA) !== "n",
            chartShowDots: localStorage.getItem(LS_KEY_SHOW_DOTS) !== "n",
            chartResetZoomTrigger: false,
            chartZoomDateRange: false,
            contentWidth: 0
        }

        this.onDiscIdSearchInputChange = this.onDiscIdSearchInputChange.bind(this)
        this.onDiscIdAutocompleteSelect = this.onDiscIdAutocompleteSelect.bind(this)
        this.loadDiscIdsForAutocompletion = this.loadDiscIdsForAutocompletion.bind(this)
        this.handleDiscIdAutoCompletionKeys = this.handleDiscIdAutoCompletionKeys.bind(this)
        this.handleClickOutsideDiscIdAutocompletion = this.handleClickOutsideDiscIdAutocompletion.bind(this)
        this.onSummaryRowClick = this.onSummaryRowClick.bind(this)

        this.onOwnerSearchInputChange = this.onOwnerSearchInputChange.bind(this)
        this.onOwnerAutocompleteSelect = this.onOwnerAutocompleteSelect.bind(this)
        this.loadOwnersForAutocompletion = this.loadOwnersForAutocompletion.bind(this)
        this.handleOwnerAutoCompletionKeys = this.handleOwnerAutoCompletionKeys.bind(this)
        this.handleClickOutsideOwnerAutocompletion = this.handleClickOutsideOwnerAutocompletion.bind(this)

        this.onOrderNumberSearchInputChange = this.onOrderNumberSearchInputChange.bind(this)
        this.onOrderNumberAutocompleteSelect = this.onOrderNumberAutocompleteSelect.bind(this)
        this.loadOrderNumbersForAutocompletion = this.loadOrderNumbersForAutocompletion.bind(this)
        this.handleOrderNumberAutoCompletionKeys = this.handleOrderNumberAutoCompletionKeys.bind(this)
        this.handleClickOutsideOrderNumberAutocompletion = this.handleClickOutsideOrderNumberAutocompletion.bind(this)

        this.closeDateRangeModal = this.closeDateRangeModal.bind(this)
        this.editDateRange = this.editDateRange.bind(this)
        this.setDateRange = this.setDateRange.bind(this)

        this.exportSettingsAsCsv = this.exportSettingsAsCsv.bind(this)
        this.exportMeasurementsAsCsv = this.exportMeasurementsAsCsv.bind(this)
        this.exportAssemblyMeasurementsAsCsv = this.exportAssemblyMeasurementsAsCsv.bind(this)
        this.exportTemperatureProfilesAsCsv = this.exportTemperatureProfilesAsCsv.bind(this)
        this.exportFinalCalibrationsAsCsv = this.exportFinalCalibrationsAsCsv.bind(this)
        this.onTabSelect = this.onTabSelect.bind(this)
        this.loadDiscSummaries = this.loadDiscSummaries.bind(this)
        this.loadCalibrationData = this.loadCalibrationData.bind(this)
        this.loadOperationData = this.loadOperationData.bind(this)
        this.renderOperationDataList = this.renderOperationDataList.bind(this)
        this.renderTemperatureProfile = this.renderTemperatureProfile.bind(this)
        this.renderFinalCalibrationMetadata = this.renderFinalCalibrationMetadata.bind(this)
        this.renderFinalCalibrationMeasurement = this.renderFinalCalibrationMeasurement.bind(this)
        this.renderAssemblyMeasurement = this.renderAssemblyMeasurement.bind(this)
        this.renderTemperatureProfileCalculations = this.renderTemperatureProfileCalculations.bind(this)
        this.renderFinalCalibrationCalculations = this.renderFinalCalibrationCalculations.bind(this)
        this.renderDateRangeModal = this.renderDateRangeModal.bind(this)
        this.resetFirstProvisioning = this.resetFirstProvisioning.bind(this)
        this.decommissionDisc = this.decommissionDisc.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.handleWindowResize = this.handleWindowResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleDiscIdAutoCompletionKeys)
        window.addEventListener("click", this.handleClickOutsideDiscIdAutocompletion)
        window.addEventListener("keydown", this.handleOwnerAutoCompletionKeys)
        window.addEventListener("click", this.handleClickOutsideOwnerAutocompletion)
        window.addEventListener("keydown", this.handleOrderNumberAutoCompletionKeys)
        window.addEventListener("click", this.handleClickOutsideOrderNumberAutocompletion)
        window.addEventListener("resize", this.handleWindowResize)
        this.handleWindowResize()
    }
5
    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleDiscIdAutoCompletionKeys)
        window.removeEventListener("click", this.handleClickOutsideDiscIdAutocompletion)
        window.removeEventListener("keydown", this.handleOwnerAutoCompletionKeys)
        window.removeEventListener("click", this.handleClickOutsideOwnerAutocompletion)
        window.removeEventListener("keydown", this.handleOrderNumberAutoCompletionKeys)
        window.removeEventListener("click", this.handleClickOutsideOrderNumberAutocompletion)
        window.removeEventListener("resize", this.handleWindowResize)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isNavOpen !== prevProps.isNavOpen) {
            this.handleWindowResize()
        }
        if (this.state.serialNumber !== prevState.serialNumber) {
            if (!this.state.selectedOwner && !this.state.selectedOrderNumber && !this.state.dateFilterLabel) {
                if (this.state.serialNumber) {
                    this.setState({discDataLoaded: false}, () => {
                        switch (this.state.activeTabKey) {
                        case 0:
                            this.loadDiscSummaries()
                            break
                        case 1:
                            this.loadCalibrationData()
                            break;
                        default:
                            this.loadOperationData()
                        }
                    })
                } else {
                    this.setState({
                        discSummaries: [],
                        activeTabKey: 0,
                        selectedSummaryIndex: -1,
                        operationData: {},
                        calibrationData: {}
                    })
                }
            } else if (!this.state.serialNumber) {
                this.setState({activeTabKey: 0})
            }
        } else if (this.state.selectedOwner !== prevState.selectedOwner) {
            this.setState({
                serialNumber: false,
                activeTabKey: 0,
                discSummaries: [],
                selectedSummaryIndex: -1,
                discDataLoaded: false
            }, () => {
                if (this.state.selectedOwner || this.state.selectedOrderNumber || this.state.dateFilterLabel) {
                    this.loadDiscSummaries()
                }
            })
        } else if (this.state.selectedOrderNumber !== prevState.selectedOrderNumber) {
            this.setState({
                serialNumber: false,
                activeTabKey: 0,
                discSummaries: [],
                selectedSummaryIndex: -1,
                discDataLoaded: false
            }, () => {
                if (this.state.selectedOwner || this.state.selectedOrderNumber || this.state.dateFilterLabel) {
                    this.loadDiscSummaries()
                }
            })
        } else if (this.state.dateFilterLabel !== prevState.dateFilterLabel) {
            this.setState({
                serialNumber: false,
                activeTabKey: 0,
                discSummaries: [],
                selectedSummaryIndex: -1,
                discDataLoaded: false
            }, () => {
                if (this.state.selectedOwner || this.state.selectedOrderNumber || this.state.dateFilterLabel) {
                    this.loadDiscSummaries()
                }
            })
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <>
                        <PageGroup>
                            <PageSection variant={PageSectionVariants.light}>
                                <Panel className="PageNavigation">
                                    <PanelHeader>
                                        <Title
                                            className="Title"
                                            headingLevel="h1"
                                        >
                                            {t("fe_disc_title")}
                                        </Title>
                                        <Button
                                            className="UpperRight"
                                            variant="plain"
                                            aria-label="Refresh"
                                            onClick={this.refreshData}
                                            isDisabled={this.state.discSummaries.length === 0}>
                                            <SyncAltIcon/>
                                        </Button>
                                        <Toolbar className="Toolbar">
                                            <ToolbarContent>
                                                <ToolbarGroup alignment={{default: 'alignLeft'}}>
                                                    <ToolbarItem variant="search-filter">
                                                        <Popper
                                                            trigger={
                                                                <SearchInput
                                                                    id="disc-id-autocomplete-search"
                                                                    className="SearchInput"
                                                                    style={{maxWidth: "260px"}}
                                                                    ref={this.discIdSearchInputRef}
                                                                    value={this.state.discIdSearchValue}
                                                                    onChange={this.onDiscIdSearchInputChange}
                                                                    onClear={() => this.setState({
                                                                        discIdSearchValue: "",
                                                                        selectedSummaryIndex: -1,
                                                                        serialNumber: false
                                                                    })}
                                                                    placeholder={t("fe_disc_search-placeholder")}
                                                                />
                                                            }
                                                            triggerRef={this.discIdSearchInputRef}
                                                            popper={
                                                                <Menu ref={this.discIdAutocompleteRef} onSelect={this.onDiscIdAutocompleteSelect}>
                                                                    <MenuList>{this.state.discIdAutocompleteOptions}</MenuList>
                                                                </Menu>
                                                            }
                                                            popperRef={this.discIdAutocompleteRef}
                                                            isVisible={this.state.discIdAutocompleteOpen}
                                                            enableFlip={false}
                                                            appendTo={() => document.querySelector("#disc-id-autocomplete-search")}
                                                        />
                                                    </ToolbarItem>
                                                    <ToolbarItem variant="search-filter">
                                                        <Popper
                                                            trigger={
                                                                <SearchInput
                                                                    id="owner-autocomplete-search"
                                                                    className="SearchInput"
                                                                    style={{maxWidth: "260px"}}
                                                                    ref={this.ownerSearchInputRef}
                                                                    value={this.state.ownerSearchValue}
                                                                    onChange={this.onOwnerSearchInputChange}
                                                                    onClear={() => this.setState({
                                                                        ownerSearchValue: "",
                                                                        selectedOwner: false
                                                                    })}
                                                                    placeholder={t("fe_owner_search-placeholder")}
                                                                    isDisabled={this.state.serialNumber}
                                                                />
                                                            }
                                                            triggerRef={this.ownerSearchInputRef}
                                                            popper={
                                                                <Menu ref={this.ownerAutocompleteRef} style={{minWidth: "600px"}} onSelect={this.onOwnerAutocompleteSelect}>
                                                                    <MenuList style={{minWidth: "600px"}}>{this.state.ownerAutocompleteOptions}</MenuList>
                                                                </Menu>
                                                            }
                                                            popperRef={this.ownerAutocompleteRef}
                                                            isVisible={this.state.ownerAutocompleteOpen}
                                                            enableFlip={false}
                                                            appendTo={() => document.querySelector("#owner-autocomplete-search")}
                                                        />
                                                    </ToolbarItem>
                                                    <ToolbarItem variant="search-filter">
                                                        <Popper
                                                            trigger={
                                                                <SearchInput
                                                                    id="order-number-autocomplete-search"
                                                                    className="SearchInput"
                                                                    style={{maxWidth: "180px"}}
                                                                    ref={this.orderNumberSearchInputRef}
                                                                    value={this.state.orderNumberSearchValue}
                                                                    onChange={this.onOrderNumberSearchInputChange}
                                                                    onClear={() => this.setState({
                                                                        orderNumberSearchValue: "",
                                                                        selectedOrderNumber: false
                                                                    })}
                                                                    placeholder={t("fe_common_order-number")}
                                                                    isDisabled={this.state.serialNumber}
                                                                />
                                                            }
                                                            triggerRef={this.orderNumberSearchInputRef}
                                                            popper={
                                                                <Menu ref={this.orderNumberAutocompleteRef} onSelect={this.onOrderNumberAutocompleteSelect}>
                                                                    <MenuList>{this.state.orderNumberAutocompleteOptions}</MenuList>
                                                                </Menu>
                                                            }
                                                            popperRef={this.orderNumberAutocompleteRef}
                                                            isVisible={this.state.orderNumberAutocompleteOpen}
                                                            enableFlip={false}
                                                            appendTo={() => document.querySelector("#order-number-autocomplete-search")}
                                                        />
                                                    </ToolbarItem>
                                                    <ToolbarItem variant="search-filter">
                                                        <>
                                                            <Button
                                                                variant="control"
                                                                aria-label="date-range"
                                                                icon={<OutlinedCalendarAltIcon/>}
                                                                onClick={()=>this.setState({showDateRangeModal: true})}
                                                                className={this.state.dateFilterLabel === "" ? "Inactive" : ""}
                                                                isDisabled={this.state.serialNumber}
                                                            >
                                                                {this.state.dateFilterLabel === "" ? t("fe_common_date") : this.state.dateFilterLabel}
                                                            </Button>
                                                            {
                                                                this.state.dateFilterLabel !== "" &&
                                                                <Button
                                                                    variant="control"
                                                                    aria-label="date-range"
                                                                    onClick={()=>this.setState({
                                                                        dateFilterLabel: "",
                                                                        dateFilterStart: false,
                                                                        dateFilterEnd: false
                                                                    })}
                                                                >
                                                                    <TimesIcon />
                                                                </Button>
                                                            }
                                                        </>
                                                    </ToolbarItem>
                                                </ToolbarGroup>
                                                <ToolbarGroup alignment={{default: 'alignRight'}}>
                                                    <ToolbarItem>
                                                        <Dropdown
                                                            isOpen={this.state.exportCsvMenuOpen}
                                                            position={DropdownPosition.right}
                                                            toggle={
                                                                <MenuToggle
                                                                    ref={this.exportCsvActionRef}
                                                                    isDisabled={this.state.discSummaries.length === 0}
                                                                    onClick={() => this.setState(prevState => {return {exportCsvMenuOpen: !prevState.exportCsvMenuOpen}})}
                                                                    isExpanded={this.state.exportCsvMenuOpen}
                                                                >
                                                                    {t("fe_action_export-csv")}
                                                                </MenuToggle>
                                                            }
                                                            onSelect={() => this.setState({exportCsvMenuOpen: false})}
                                                            dropdownItems={[
                                                                <DropdownItem
                                                                    key="export-csv-settings"
                                                                    component="button"
                                                                    onClick={this.exportSettingsAsCsv}>
                                                                    {t("fe_common_settings")}
                                                                </DropdownItem>,
                                                                <DropdownItem
                                                                    key="export-csv-measurements"
                                                                    component="button"
                                                                    onClick={this.exportMeasurementsAsCsv}>
                                                                    {t("fe_disc_measurements")}
                                                                </DropdownItem>,
                                                                <DropdownItem
                                                                    key="export-csv-assembly-measurements"
                                                                    component="button"
                                                                    onClick={this.exportAssemblyMeasurementsAsCsv}>
                                                                    {t("fe_disc_assembly-measurements")}
                                                                </DropdownItem>,
                                                                <DropdownItem
                                                                    key="export-csv-temperature-profiles"
                                                                    component="button"
                                                                    onClick={this.exportTemperatureProfilesAsCsv}>
                                                                    {t("fe_disc_temperature-profiles")}
                                                                </DropdownItem>,
                                                                <DropdownItem
                                                                    key="export-csv-final-calibrations"
                                                                    component="button"
                                                                    onClick={this.exportFinalCalibrationsAsCsv}>
                                                                    {t("fe_disc_final-calibrations")}
                                                                </DropdownItem>
                                                            ]}
                                                        />
                                                    </ToolbarItem>
                                                </ToolbarGroup>
                                            </ToolbarContent>
                                        </Toolbar>
                                    </PanelHeader>
                                    <PanelMain>
                                        <PanelMainBody className="SensorDiscPage">
                                        {
                                            this.state.discSummaries.length === 0 && (this.state.selectedOwner || this.state.selectedOrderNumber || this.state.dateFilterLabel) && this.state.discDataLoaded &&
                                            <EmptyState isFullHeight>
                                                <EmptyStateIcon icon={BanIcon} />
                                                <Title headingLevel="h5" size="4xl">{t("fe_disc_no-match")}</Title>
                                            </EmptyState>
                                        }
                                        {
                                            this.state.discSummaries.length > 0 && (
                                                <Tabs
                                                    activeKey={this.state.activeTabKey}
                                                    onSelect={this.onTabSelect}
                                                    isBox={true}
                                                >
                                                    <Tab eventKey={0}
                                                         title={<>
                                                             <TabTitleIcon><ListIcon/></TabTitleIcon>
                                                             <TabTitleText>{t("fe_disc_overview-title")}</TabTitleText>
                                                         </>}>
                                                        {
                                                            this.state.discDataLoaded && this.state.activeTabKey === 0 &&
                                                            this.renderDiscSummaryList(t, this.state.discSummaries)
                                                        }
                                                    </Tab>
                                                    {
                                                        this.state.serialNumber &&
                                                        <Tab eventKey={1}
                                                            title={<>
                                                                <TabTitleIcon><CogIcon/></TabTitleIcon>
                                                                <TabTitleText>{t("fe_disc_calibration-title")}</TabTitleText>
                                                            </>}>
                                                            {
                                                                this.state.discDataLoaded && this.state.activeTabKey === 1 &&
                                                                <Flex>
                                                                    <FlexItem grow={{default: 'grow'}}>
                                                                        <Title headingLevel="h2" className="TopSpacer">
                                                                            {t("fe_disc_assembly-measurements")}
                                                                        </Title>
                                                                        {
                                                                            this.state.calibrationData["assemblyMeasurements"] &&
                                                                            this.state.calibrationData["assemblyMeasurements"].length > 0 &&
                                                                            this.state.calibrationData["assemblyMeasurements"].map((measurement, index) =>
                                                                                this.renderAssemblyMeasurement(t, measurement, index)
                                                                            )
                                                                        }
                                                                        {
                                                                            (
                                                                                !this.state.calibrationData["assemblyMeasurements"] ||
                                                                                this.state.calibrationData["assemblyMeasurements"].length === 0
                                                                            ) &&
                                                                            <EmptyState isFullHeight>
                                                                                <EmptyStateIcon icon={BanIcon} />
                                                                                <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                            </EmptyState>
                                                                        }
                                                                    </FlexItem>
                                                                    <FlexItem grow={{default: 'grow'}}>
                                                                        <Title headingLevel="h2" className="TopSpacer">
                                                                            {t("fe_disc_temperature-profile")}
                                                                        </Title>
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            this.state.calibrationData["temperatureProfile"]["high"] &&
                                                                            this.state.calibrationData["temperatureProfile"]["low"] &&
                                                                            this.state.calibrationData["temperatureProfile"]["room"] &&
                                                                            this.renderTemperatureProfileCalculations(
                                                                                t,
                                                                                this.state.calibrationData["temperatureProfile"],
                                                                                "temperature-profile-validity"
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            this.state.calibrationData["temperatureProfile"]["high"] &&
                                                                            this.renderTemperatureProfile(
                                                                                t,
                                                                                this.state.calibrationData["temperatureProfile"]["high"],
                                                                                "high-temperature-profile",
                                                                                t("fe_disc_high-temperature-profile")
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            !this.state.calibrationData["temperatureProfile"]["high"] &&
                                                                            <>
                                                                                <Title
                                                                                    className="TopSpacer"
                                                                                    headingLevel="h5"
                                                                                    size={TitleSizes['lg']}
                                                                                >
                                                                                    {t("fe_disc_high-temperature-profile")}
                                                                                </Title>
                                                                                <EmptyState isFullHeight>
                                                                                    <EmptyStateIcon icon={BanIcon} />
                                                                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                                </EmptyState>
                                                                            </>
                                                                        }
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            this.state.calibrationData["temperatureProfile"]["low"] &&
                                                                            this.renderTemperatureProfile(
                                                                                t,
                                                                                this.state.calibrationData["temperatureProfile"]["low"],
                                                                                "low-temperature-profile",
                                                                                t("fe_disc_low-temperature-profile")
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            !this.state.calibrationData["temperatureProfile"]["low"] &&
                                                                            <>
                                                                                <Title
                                                                                    className="TopSpacer"
                                                                                    headingLevel="h5"
                                                                                    size={TitleSizes['lg']}
                                                                                >
                                                                                    {t("fe_disc_low-temperature-profile")}
                                                                                </Title>
                                                                                <EmptyState isFullHeight>
                                                                                    <EmptyStateIcon icon={BanIcon} />
                                                                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                                </EmptyState>
                                                                            </>
                                                                        }
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            this.state.calibrationData["temperatureProfile"]["room"] &&
                                                                            this.renderTemperatureProfile(
                                                                                t,
                                                                                this.state.calibrationData["temperatureProfile"]["room"],
                                                                                "room-temperature-profile",
                                                                                t("fe_disc_room-temperature-profile")
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.calibrationData["temperatureProfile"] &&
                                                                            !this.state.calibrationData["temperatureProfile"]["room"] &&
                                                                            <>
                                                                                <Title
                                                                                    className="TopSpacer"
                                                                                    headingLevel="h5"
                                                                                    size={TitleSizes['lg']}
                                                                                >
                                                                                    {t("fe_disc_room-temperature-profile")}
                                                                                </Title>
                                                                                <EmptyState isFullHeight>
                                                                                    <EmptyStateIcon icon={BanIcon} />
                                                                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                                </EmptyState>
                                                                            </>
                                                                        }
                                                                        {
                                                                            !this.state.calibrationData["temperatureProfile"] &&
                                                                            <EmptyState isFullHeight>
                                                                                <EmptyStateIcon icon={BanIcon} />
                                                                                <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                            </EmptyState>
                                                                        }
                                                                    </FlexItem>
                                                                    <FlexItem grow={{default: 'grow'}}>
                                                                        <Title
                                                                            headingLevel="h4"
                                                                            size={TitleSizes['xl']}
                                                                            className="TopSpacer"
                                                                        >
                                                                            {t("fe_disc_final-calibration")}
                                                                        </Title>
                                                                        {
                                                                            this.state.calibrationData["finalCalibration"] &&
                                                                            <>
                                                                                {this.renderFinalCalibrationCalculations(t, this.state.calibrationData["finalCalibration"])}
                                                                                {this.renderFinalCalibrationMetadata(t, this.state.calibrationData["finalCalibration"], "final-calibration-metadata", t("fe_disc_metadata"), "")}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["loadFree1"], "load-free-1", `${t("fe_disc_load-free")} (1) → `, <var>0*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["preLoad1"], "pre-load-1", `${t("fe_disc_pre-load")} (1) → `, <var>1,2*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["loadFree2"], "load-free-2", `${t("fe_disc_load-free")} (2) → `, <var>0*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["preLoad2"], "pre-load-2", `${t("fe_disc_pre-load")} (2) → `, <var>1,2*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["loadFree3"], "load-free-3", `${t("fe_disc_load-free")} (3) → `, <var>0*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["cascade1"], "pre-cascade-1", `${t("fe_disc_cascade")} (1) → `, <var>1*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["cascade2"], "pre-cascade-2", `${t("fe_disc_cascade")} (2) → `, <var>0,75*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["cascade3"], "pre-cascade-3", `${t("fe_disc_cascade")} (3) → `, <var>0,5*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["cascade4"], "pre-cascade-4", `${t("fe_disc_cascade")} (4) → `, <var>0,25*F<sub>nom</sub></var>)}
                                                                                {this.renderFinalCalibrationMeasurement(t, this.state.calibrationData["finalCalibration"]["loadFree4"], "load-free-4", `${t("fe_disc_load-free")} (4) → `, <var>0*F<sub>nom</sub></var>)}
                                                                            </>
                                                                        }
                                                                        {
                                                                            !this.state.calibrationData["finalCalibration"] &&
                                                                            <EmptyState isFullHeight>
                                                                                <EmptyStateIcon icon={BanIcon} />
                                                                                <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                            </EmptyState>
                                                                        }
                                                                    </FlexItem>
                                                                </Flex>
                                                            }
                                                        </Tab>
                                                    }
                                                    {
                                                        this.state.serialNumber &&
                                                        <Tab eventKey={2}
                                                             title={<>
                                                                 <TabTitleIcon><PlayIcon/></TabTitleIcon>
                                                                 <TabTitleText>{t("fe_disc_provisioning-title")}</TabTitleText>
                                                             </>}>
                                                            {
                                                                this.state.discDataLoaded && this.state.activeTabKey === 2 &&
                                                                <>
                                                                    <Flex flexWrap={{default: 'nowrap'}}>
                                                                        <FlexItem grow={{default: 'grow'}}>
                                                                            <Title
                                                                                headingLevel="h4"
                                                                                size={TitleSizes['xl']}
                                                                                className="TopSpacer"
                                                                            >
                                                                                {t("fe_disc_first-provisioning")}
                                                                            </Title>
                                                                            {
                                                                                !this.state.operationData["firstProvisioning"] &&
                                                                                <EmptyState isFullHeight>
                                                                                    <EmptyStateIcon icon={BanIcon} />
                                                                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                                </EmptyState>
                                                                            }
                                                                            {
                                                                                this.state.operationData["firstProvisioning"] &&
                                                                                this.renderOperationDataList(
                                                                                    t,
                                                                                    false,
                                                                                    "first-provisioning",
                                                                                    SensorDiscPage.FIRST_PROVISIONING,
                                                                                    this.state.operationData["firstProvisioning"]
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.operationData["firstProvisioning"] &&
                                                                                <Button
                                                                                    variant="secondary"
                                                                                    isDanger
                                                                                    className="TopSpacer"
                                                                                    onClick={() => this.setState({confirmResetFirstProvisioning: true})}
                                                                                >
                                                                                    {t("fe_disc_reset-first-provisioning")}
                                                                                </Button>
                                                                            }
                                                                        </FlexItem>
                                                                        <FlexItem grow={{default: 'grow'}}>
                                                                            <Title headingLevel="h2" className="TopSpacer">
                                                                                {t("fe_disc_current-owner")}
                                                                            </Title>
                                                                            {
                                                                                !this.state.operationData["currentOperation"] &&
                                                                                <EmptyState isFullHeight>
                                                                                    <EmptyStateIcon icon={BanIcon} />
                                                                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                                </EmptyState>
                                                                            }
                                                                            {
                                                                                this.state.operationData["currentOperation"] &&
                                                                                this.renderOperationDataList(
                                                                                    t,
                                                                                    false,
                                                                                    "current-owner",
                                                                                    SensorDiscPage.CURRENT_OWNER,
                                                                                    this.state.operationData["currentOperation"]
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.operationData["currentOperation"] &&
                                                                                this.renderOperationDataList(
                                                                                    t,
                                                                                    t("fe_common_settings"),
                                                                                    "current-settings",
                                                                                    SensorDiscPage.CURRENT_SETTINGS,
                                                                                    this.state.operationData["currentOperation"]
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.operationData["currentOperation"] &&
                                                                                <Button
                                                                                    variant="secondary"
                                                                                    isDanger
                                                                                    className="TopSpacer"
                                                                                    onClick={() => this.setState({confirmDecommission: true})}
                                                                                >
                                                                                    {t("fe_gateway_decommission")}
                                                                                </Button>
                                                                            }
                                                                        </FlexItem>
                                                                        <FlexItem grow={{default: 'grow'}}>
                                                                            <Title headingLevel="h2" className="TopSpacer">
                                                                                {t("fe_disc_last-measurement")}
                                                                            </Title>
                                                                            {
                                                                                !this.state.operationData["currentOperation"] &&
                                                                                <EmptyState isFullHeight>
                                                                                    <EmptyStateIcon icon={BanIcon} />
                                                                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                                                                </EmptyState>
                                                                            }
                                                                            {
                                                                                this.state.operationData["currentOperation"] &&
                                                                                this.renderOperationDataList(
                                                                                    t,
                                                                                    false,
                                                                                    "last-measurement",
                                                                                    SensorDiscPage.LAST_MEASUREMENT,
                                                                                    this.state.operationData["currentOperation"]
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.operationData["currentOperation"] && this.state.operationData["currentOperation"]['lastPercentValue'] !== undefined &&
                                                                                <Flex justifyContent={{default: "justifyContentSpaceEvenly"}} flexWrap={{default: 'nowrap'}}>
                                                                                    <FlexItem>
                                                                                        <div className="TopSpacer">
                                                                                            <Gauge
                                                                                                diameter={Math.min(this.state.contentWidth / 6, 300)}
                                                                                                color='#c9190b'
                                                                                                rangeStart={0}
                                                                                                rangeEnd={100}
                                                                                                value={this.state.operationData["currentOperation"]['lastPercentValue']}
                                                                                                formattedValue={
                                                                                                    `${formatNumber(this.state.operationData["currentOperation"]['lastPercentValue'], 1, t)} %`
                                                                                                }
                                                                                                label={t('fe_disc_last-percent-load')}
                                                                                            />
                                                                                        </div>
                                                                                    </FlexItem>
                                                                                    <FlexItem>
                                                                                        <div className="TopSpacer">
                                                                                            <Gauge
                                                                                                diameter={Math.min(this.state.contentWidth / 6, 300)}
                                                                                                color='#0066cc'
                                                                                                rangeStart={-25}
                                                                                                rangeEnd={50}
                                                                                                value={this.state.operationData["currentOperation"]['lastTemperatureValue']}
                                                                                                formattedValue={
                                                                                                    `${formatNumber(this.state.operationData["currentOperation"]['lastTemperatureValue'], 1, t)} °C`
                                                                                                }
                                                                                                label={t('fe_disc_last-temperature')}
                                                                                            />
                                                                                        </div>
                                                                                    </FlexItem>
                                                                                </Flex>
                                                                            }
                                                                        </FlexItem>
                                                                    </Flex>
                                                                    <SensorDiscChart
                                                                        t={t}
                                                                        app={this.props.app}
                                                                        discId={this.state.serialNumber}
                                                                        chartContent={this.state.chartContent}
                                                                        showTemperature={this.state.chartShowTemperature}
                                                                        naturalScaling={this.state.chartNaturalScaling}
                                                                        smoothLines={this.state.chartSmoothLines}
                                                                        fillArea={this.state.chartFillArea}
                                                                        showDots={this.state.chartShowDots}
                                                                        setZoomDateRange={range => this.setState({chartZoomDateRange: range})}
                                                                        reloadTrigger={`${this.state.serialNumber}:${this.state.chartReloadTrigger}`}
                                                                        resetZoomTrigger={this.state.chartResetZoomTrigger}
                                                                    />
                                                                    <Flex className="Distinguished" justifyContent={{default: "justifyContentSpaceBetween"}}>
                                                                        <Flex>
                                                                            <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                                                                                <ToggleGroup>
                                                                                    <ToggleGroupItem
                                                                                        className="LoadSwitch"
                                                                                        isSelected={this.state.chartContent === "percent"}
                                                                                        onChange={show => {
                                                                                            localStorage.setItem(LS_KEY_CHART_CONTENT, show ? "percent" : "none")
                                                                                            this.setState({chartContent: show ? "percent" : false})
                                                                                        }}
                                                                                        aria-label={`${t("fe_chart_load")} [%]`}
                                                                                        text={`${t("fe_chart_load")} [%]`}
                                                                                    />
                                                                                    <ToggleGroupItem
                                                                                        className="LoadSwitch"
                                                                                        isSelected={this.state.chartContent === "signal"}
                                                                                        onChange={show => {
                                                                                            localStorage.setItem(LS_KEY_CHART_CONTENT, show ? "signal" : "none")
                                                                                            this.setState({chartContent: show ? "signal" : false})
                                                                                        }}
                                                                                        aria-label={`${t("fe_disc_signal")} [µV/V]`}
                                                                                        text={`${t("fe_disc_signal")} [µV/V]`}
                                                                                    />
                                                                                    <ToggleGroupItem
                                                                                        className="LoadSwitch"
                                                                                        isSelected={this.state.chartContent === "signal-compensated"}
                                                                                        onChange={show => {
                                                                                            localStorage.setItem(LS_KEY_CHART_CONTENT, show ? "signal-compensated" : "none")
                                                                                            this.setState({chartContent: show ? "signal-compensated" : false})
                                                                                        }}
                                                                                        aria-label={`${t("fe_chart_compensated-signal")} [µV/V]`}
                                                                                        text={`${t("fe_chart_compensated-signal")} [µV/V]`}
                                                                                    />
                                                                                </ToggleGroup>
                                                                            </FlexItem>
                                                                            <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                                                                                <ToggleGroup>
                                                                                    <ToggleGroupItem
                                                                                        className="TemperatureSwitch"
                                                                                        isSelected={this.state.chartShowTemperature}
                                                                                        onChange={show => {
                                                                                            localStorage.setItem(LS_KEY_SHOW_TEMPERATURES, show ? "y" : "n")
                                                                                            this.setState({chartShowTemperature: show})
                                                                                        }}
                                                                                        aria-label={t("fe_chart_temperature-with-unit")}
                                                                                        text={t("fe_chart_temperature-with-unit")}
                                                                                    />
                                                                                </ToggleGroup>
                                                                            </FlexItem>
                                                                            <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                                                                                <ToggleGroup>
                                                                                    <ToggleGroupItem
                                                                                        isSelected={this.state.chartNaturalScaling}
                                                                                        onChange={enabled => {
                                                                                            localStorage.setItem(LS_KEY_NATURAL_SCALING, enabled ? "y" : "n")
                                                                                            this.setState({chartNaturalScaling: enabled})
                                                                                        }}
                                                                                        aria-label={t("fe_chart_natural-scale")}
                                                                                        text={t("fe_chart_natural-scale")}
                                                                                    />
                                                                                    <ToggleGroupItem
                                                                                        isSelected={this.state.chartSmoothLines}
                                                                                        onChange={enabled => {
                                                                                            localStorage.setItem(LS_KEY_SMOOTH_LINES, enabled ? "y" : "n")
                                                                                            this.setState({chartSmoothLines: enabled})
                                                                                        }}
                                                                                        aria-label={t("fe_chart_smoothing")}
                                                                                        text={t("fe_chart_smoothing")}
                                                                                    />
                                                                                    <ToggleGroupItem
                                                                                        isSelected={this.state.chartFillArea}
                                                                                        onChange={enabled => {
                                                                                            localStorage.setItem(LS_KEY_FILL_AREA, enabled ? "y" : "n")
                                                                                            this.setState({chartFillArea: enabled})
                                                                                        }}
                                                                                        aria-label={t("fe_chart_fill")}
                                                                                        text={t("fe_chart_fill")}
                                                                                    />
                                                                                    <ToggleGroupItem
                                                                                        isSelected={this.state.chartShowDots}
                                                                                        onChange={enabled => {
                                                                                            localStorage.setItem(LS_KEY_SHOW_DOTS, enabled ? "y" : "n")
                                                                                            this.setState({chartShowDots: enabled})
                                                                                        }}
                                                                                        aria-label={t("fe_chart_dots")}
                                                                                        text={t("fe_chart_dots")}
                                                                                    />
                                                                                </ToggleGroup>
                                                                            </FlexItem>
                                                                        </Flex>
                                                                        <Flex>
                                                                            <FlexItem>
                                                                                <Button
                                                                                    variant="secondary"
                                                                                    onClick={() => this.setState(prevState => ({
                                                                                        chartResetZoomTrigger: !prevState.chartResetZoomTrigger,
                                                                                        chartZoomDateRange: false
                                                                                    }))}
                                                                                    isDisabled={!this.state.chartZoomDateRange}
                                                                                >
                                                                                    {this.state.chartZoomDateRange && <>{this.state.chartZoomDateRange}&nbsp;|&nbsp;</>}Reset zoom
                                                                                </Button>
                                                                            </FlexItem>
                                                                        </Flex>
                                                                    </Flex>
                                                                </>
                                                            }
                                                        </Tab>
                                                    }
                                                </Tabs>
                                            )
                                        }
                                        </PanelMainBody>
                                    </PanelMain>
                                </Panel>
                            </PageSection>
                        </PageGroup>
                        {
                            this.state.confirmDecommission &&
                            <Modal
                                variant={ModalVariant.small}
                                title={t("fe_common_confirm-title")}
                                isOpen={true}
                                onClose={() => this.setState({confirmDecommission: false})}
                                actions={[
                                    <Button key="confirm" variant="primary" onClick={this.decommissionDisc}>{t("fe_gateway_decommission")}</Button>,
                                    <Button key="cancel" variant="link" onClick={() => this.setState({confirmDecommission: false})}>{t("fe_common_cancel")}</Button>
                                ]}
                            >
                                {t("fe_disc_decommission-description")}
                            </Modal>
                        }
                        {
                            this.state.confirmResetFirstProvisioning &&
                            <Modal
                                variant={ModalVariant.small}
                                title={t("fe_common_confirm-title")}
                                isOpen={true}
                                onClose={() => this.setState({confirmResetFirstProvisioning: false})}
                                actions={[
                                    <Button key="confirm" variant="primary" onClick={this.resetFirstProvisioning}>{t("fe_disc_reset-first-provisioning")}</Button>,
                                    <Button key="cancel" variant="link" onClick={() => this.setState({confirmResetFirstProvisioning: false})}>{t("fe_common_cancel")}</Button>
                                ]}
                            >
                                {t("fe_disc_reset-first-provisioning-description")}
                            </Modal>
                        }
                        {
                            this.state.showDateRangeModal && this.renderDateRangeModal(t)
                        }
                    </>
                }
            </Translation>
        )
    }

    renderDiscSummaryList(t, summaries) {
        return (
            <div>
                <Card>
                    <TableComposable variant="compact" borders={true} isStickyHeader>
                        <Thead>
                            <Tr>
                                {
                                    SensorDiscPage.SUMMARY_COLUMNS.map((column, index) => {
                                        return <Th key={index}>{t(column.label)}</Th>
                                    })
                                }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                summaries.map((row, rowIndex) => {
                                    return (
                                        <Tr isHoverable key={rowIndex}
                                            onRowClick={event => this.onSummaryRowClick(event, rowIndex)}
                                            isRowSelected={this.state.selectedSummaryIndex === rowIndex}>
                                            <Td key={`${rowIndex}_0`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[0].label)}>
                                                {row["discId"]}
                                            </Td>
                                            <Td key={`${rowIndex}_2`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[1].label)}>
                                                {this.renderOkayIcon(row, "readyToShip")}
                                            </Td>
                                            <Td key={`${rowIndex}_3`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[2].label)}>
                                                {row["sapOrderNumber"]}
                                            </Td>
                                            <Td key={`${rowIndex}_4`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[3].label)}>
                                                {row["size"]}
                                            </Td>
                                            <Td key={`${rowIndex}_5`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[4].label)}>
                                                {row["assemblyMeasurement"]}
                                            </Td>
                                            <Td key={`${rowIndex}_6`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[5].label)}>
                                                {row["temperatureProfile"]}
                                            </Td>
                                            <Td key={`${rowIndex}_7`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[6].label)}>
                                                {row["finalCalibration"]}
                                            </Td>
                                            <Td key={`${rowIndex}_8`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[7].label)}>
                                                {row["discOwner"]}
                                            </Td>
                                            <Td key={`${rowIndex}_9`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[8].label)}>
                                                {row["calibrationDate"]}
                                            </Td>
                                            <Td key={`${rowIndex}_10`}
                                                dataLabel={t(SensorDiscPage.SUMMARY_COLUMNS[9].label)}>
                                                {row["operational"] ? t("simulation_yes") : t("simulation_no")}
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </TableComposable>
                </Card>
            </div>
        )
    }

    renderOperationDataList(t, title, key, fields, source) {
        return (
            <>
                {
                    title && <Title className="TopSpacer" headingLevel="h5">{title}</Title>
                }
                <DataList
                    key={key}
                    isCompact
                    aria-label={key.replace("-", " ")}
                >
                    {
                        fields.filter(item => source[item.key]).map(item =>
                            <DataListItem key={`${key}-${item.key}`}>
                                <DataListItemRow>
                                    <DataListItemCells dataListCells={[
                                        <DataListCell
                                            key="label"
                                            isFilled={false}>
                                            {t(item.label)}
                                        </DataListCell>,
                                        <DataListCell
                                            key="value"
                                            isFilled={false}
                                            alignRight
                                            className="Value">
                                            {source[item.key]}
                                            {this.renderStatusIcon(source, item.statusKey)}
                                        </DataListCell>
                                    ]}/>
                                </DataListItemRow>
                            </DataListItem>
                        )
                    }
                </DataList>
            </>
        )
    }

    renderAssemblyMeasurement(t, measurement, index) {
        return (
            <>
                <Title className="TopSpacer" headingLevel="h5">
                    {measurement["number"]}
                </Title>
                <DataList
                    key={`assembly-measurement-${index}`}
                    isCompact
                    aria-label="assembly measurement"
                >
                    {
                        SensorDiscPage.ASSEMBLY_MEASUREMENT.filter(item => measurement[item.key]).map(item =>
                            <DataListItem key={`assembly-measurement-${item.key}-${index}`}>
                                <DataListItemRow>
                                    <DataListItemCells dataListCells={[
                                        <DataListCell
                                            key="label"
                                            isFilled={false}>
                                            {t(item.label)}
                                        </DataListCell>,
                                        <DataListCell
                                            key="value"
                                            isFilled={false}
                                            alignRight
                                            className="Value">
                                            {measurement[item.key]}
                                            {item.okayKey && measurement[item.okayKey] === true && <CheckCircleIcon className="Success StatusIcon"/>}
                                            {item.okayKey && measurement[item.okayKey] === false && <ExclamationCircleIcon className="Danger StatusIcon"/>}
                                        </DataListCell>
                                    ]}/>
                                </DataListItemRow>
                            </DataListItem>
                        )
                    }
                </DataList>
            </>
        )
    }

    renderTemperatureProfile(t, profile, key, title) {
        return <>
            <Title className="TopSpacer" headingLevel="h5">
                {title}
            </Title>
            <DataList
                key={key}
                isCompact
                aria-label="temperature profile"
            >
                {
                    SensorDiscPage.TEMPERATURE_PROFILE_MEASUREMENT.map(item =>
                        <DataListItem key={`${item.key}-${key}`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell
                                        key="label"
                                        isFilled={false}>
                                        {t(item.label)}
                                    </DataListCell>,
                                    <DataListCell
                                        key="value"
                                        isFilled={false}
                                        alignRight
                                        className="Value">
                                        {profile[item.key]}
                                        {item.okayKey && profile[item.okayKey] === true && <CheckCircleIcon className="Success StatusIcon"/>}
                                        {item.okayKey && profile[item.okayKey] === false && <ExclamationCircleIcon className="Danger StatusIcon"/>}
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    )
                }
            </DataList>
        </>
    }

    renderFinalCalibrationMetadata(t, metadata, key, title) {
        return <>
            <Title className="TopSpacer" headingLevel="h5">
                {title}
            </Title>
            <DataList
                key={key}
                isCompact
                aria-label="final calibration metadata"
            >
                {
                    SensorDiscPage.FINAL_CALIBRATION_METADATA.map(item =>
                        <DataListItem key={`${item.key}-${key}`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell
                                        key="label"
                                        isFilled={false}>
                                        {t(item.label)}
                                    </DataListCell>,
                                    <DataListCell
                                        key="value"
                                        isFilled={false}
                                        alignRight
                                        className="Value">
                                        {metadata[item.key]}
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    )
                }
            </DataList>
        </>
    }

    renderFinalCalibrationMeasurement(t, measurement, key, title, unit) {
        return <>
            <Title className="TopSpacer" headingLevel="h5">
                {title} {unit}
            </Title>
            <DataList
                key={key}
                isCompact
                aria-label="final calibration measurement"
            >
                {
                    SensorDiscPage.FINAL_CALIBRATION_MEASUREMENT.map(item =>
                        <DataListItem key={`${item.key}-${key}`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell
                                        key="label"
                                        isFilled={false}>
                                        {t(item.label)}
                                    </DataListCell>,
                                    <DataListCell
                                        key="value"
                                        isFilled={false}
                                        alignRight
                                        className="Value">
                                        {measurement[item.key]}
                                        {this.renderOkayIcon(measurement, item.okayKey)}
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    )
                }
            </DataList>
        </>
    }

    renderTemperatureProfileCalculations(t, source, key) {
        return <>
            <Title className="TopSpacer" headingLevel="h5">
                {t("fe_disc_validity-check")}
            </Title>
            <DataList
                key={key}
                isCompact
                aria-label="validity check"
            >
                {
                    SensorDiscPage.TEMPERATURE_PROFILE_CALCULATED_VALUES.map(item =>
                        <DataListItem key={`${item.valueKey}-${key}`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell
                                        key="label"
                                        isFilled={false}>
                                        {t(item.label)}
                                    </DataListCell>,
                                    <DataListCell
                                        key="value"
                                        isFilled={false}
                                        alignRight
                                        className="Value">
                                        {source[item.key]}
                                        {this.renderOkayIcon(source, item.okayKey)}
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    )
                }
            </DataList>
        </>
    }

    renderOkayIcon(source, okayKey) {
        if (source[okayKey] === undefined) return null

        if (source[okayKey] === true) {
            return <CheckCircleIcon className="Success StatusIcon"/>
        } else {
            return <ExclamationCircleIcon className="Danger StatusIcon"/>
        }
    }

    renderStatusIcon(source, statusKey) {
        if (!source[statusKey]) return null

        switch (source[statusKey]) {
        case "INCOMPLETE_MEASUREMENTS":
            return <ExclamationTriangleIcon className="Warning StatusIcon"/>
        case "OKAY":
            return <CheckCircleIcon className="Warning StatusIcon"/>
        case "ACCEPTABLE":
            return <CheckCircleIcon className="Danger StatusIcon"/>
        case "FAILED":
            return <ExclamationCircleIcon className="Danger StatusIcon"/>
        default:
            return <CheckCircleIcon className="Success StatusIcon"/>
        }
    }

    renderFinalCalibrationCalculations(t, source) {
        return <>
            <Title
                className="TopSpacer"
                headingLevel="h5"
                size={TitleSizes['lg']}
            >
                {t("fe_disc_validity-check")}
            </Title>
            <DataList
                key="final-calibration-calculations"
                isCompact
                aria-label="final calibration calculations"
            >
                {
                    SensorDiscPage.FINAL_CALIBRATION_CALCULATED_VALUES.map(item => {
                            const calculatedValue = source[item.key]
                            return <DataListItem key={item.key}>
                                <DataListItemRow>
                                    <DataListItemCells dataListCells={[
                                        <DataListCell
                                            key="label"
                                            isFilled={false}>
                                            {t(item.label)}
                                        </DataListCell>,
                                        <DataListCell
                                            key="value"
                                            isFilled={false}
                                            alignRight
                                            className="Value">
                                            {calculatedValue["value"]}
                                            {calculatedValue["okay"] === true &&
                                                <CheckCircleIcon className="Success StatusIcon"/>}
                                            {calculatedValue["okay"] === false &&
                                                <ExclamationCircleIcon className="Danger StatusIcon"/>}
                                        </DataListCell>
                                    ]}/>
                                </DataListItemRow>
                            </DataListItem>
                        }
                    )
                }
            </DataList>
        </>
    }

    renderDateRangeModal(t) {
        return (
            <Modal
                width={950}
                variant={ModalVariant.small}
                title={t("fe_chart_select-period")}
                isOpen={true}
                onClose={this.closeDateRangeModal}
                actions={[
                    <Button key="confirm" variant="primary" onClick={this.setDateRange}>{t("fe_common_ok")}</Button>,
                    <Button key="cancel" variant="link" onClick={this.closeDateRangeModal}>{t("fe_common_cancel")}</Button>
                ]}
            >
                <DateRangePicker
                    onChange={this.editDateRange}
                    showSelectionPreview
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={this.state.dateRangeModalRanges}
                    direction="horizontal"
                    locale={i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS}
                    maxDate={new Date()}
                    dateDisplayFormat={t("fe_chart_period-selector-date-pattern")}
                    monthDisplayFormat="MMMM yyyy"
                    staticRanges={stickyChartsDateRanges(t)}
                    inputRanges={stickyChartsInputRanges(t)}
                />
            </Modal>
        )
    }

    onDiscIdSearchInputChange(newValue) {
        this.setState({
            serialNumber: false,
            selectedSummaryIndex: -1,
            discIdSearchValue: newValue
        }, () => {
            if (newValue !== '' && this.discIdSearchInputRef && this.discIdSearchInputRef.current && this.discIdSearchInputRef.current.contains(document.activeElement)) {
                this.setState({discIdAutocompletePending: true}, () => this.loadDiscIdsForAutocompletion(newValue))
            } else {
                this.setState({
                    discIdAutocompleteOpen: false
                })
            }
        })
    }

    onDiscIdAutocompleteSelect(event, itemId) {
        event.stopPropagation()
        this.setState(prevState => {
            return {
                discIdSearchValue: itemId,
                discIdAutocompleteOpen: false,
                serialNumber: itemId,
                selectedSummaryIndex: prevState.selectedOwner || prevState.selectedOrderNumber || prevState.dateFilterLabel ? prevState.discSummaries.findIndex(d => d["discId"] === itemId) : prevState.selectedSummaryIndex
            }
        })
        this.discIdSearchInputRef.current.focus()
    }

    loadDiscIdsForAutocompletion(query) {
        if (this.state.selectedOwner || this.state.selectedOrderNumber || this.state.dateFilterLabel) {
            const lcQuery = query.toLowerCase()
            const regex = lcQuery.startsWith("25sqcmicsog") ? new RegExp(`${lcQuery}.*`) : new RegExp(`25sqcmicsog.*${lcQuery}.*`)
            const filteredDiscIds = this.state.discSummaries.map(s => s["discId"]).filter(d => regex.test(d.toLowerCase()))
            this.setState({
                discIdAutocompletePending: false,
                discIdAutocompleteOptions: filteredDiscIds.map(d => <MenuItem itemId={d} key={d}>{d}</MenuItem>),
                discIdAutocompleteOpen: filteredDiscIds.length > 0
            })
        } else {
            const requestInit = {
                cache: "no-cache",
                headers: {
                    "Authorization": `Bearer ${this.props.app["jwt"]}`
                }
            }
            fetch(`${AppSettings.rootPath}/v1/sensorDiscs/autocompleteDiscId?query=${query}`, requestInit)
                .then(response => {
                    if (response.status === 401) {
                        this.props.app.sessionExpired()
                    } else if (!response.ok) {
                        response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                    } else {
                        response.json().then(payload => this.setState({
                            discIdAutocompletePending: false,
                            discIdAutocompleteOptions: payload.map(o => <MenuItem itemId={o} key={o}>{o}</MenuItem>),
                            discIdAutocompleteOpen: payload.length > 0
                        }))
                    }
                })
                .catch(error => this.props.app.showErrorMessage(error.message))
        }
    }

    handleDiscIdAutoCompletionKeys(event) {
        if (this.state.discIdAutocompleteOpen && this.discIdSearchInputRef.current && this.discIdSearchInputRef.current === event.target) {
            // if the autocomplete is open and the browser focus is on the search input,
            if (event.key === 'Escape') {
                // the escape key closes the autocomplete menu and keeps the focus on the search input.
                this.setState({discIdAutocompleteOpen: false})
                this.discIdSearchInputRef.current.focus();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                // the up and down arrow keys move browser focus into the autocomplete menu
                const firstElement = this.discIdAutocompleteRef.current.querySelector('li > button:not(:disabled)')
                firstElement && firstElement.focus()
                event.preventDefault() // by default, the up and down arrow keys scroll the window
            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
                // the tab, enter, and space keys will close the menu, and the tab key will move browser
                // focus forward one element (by default)
                this.setState({discIdAutocompleteOpen: false})
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault()
                }
            }
        } else if (this.state.discIdAutocompleteOpen && this.discIdAutocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            // If the autocomplete is open and the browser focus is in the autocomplete menu
            // hitting tab will close the autocomplete and but browser focus back on the search input.
            event.preventDefault();
            this.setState({discIdAutocompleteOpen: false})
            this.discIdSearchInputRef.current.focus();
        }
    }

    handleClickOutsideDiscIdAutocompletion(event) {
        if (
            this.state.discIdAutocompleteOpen &&
            this.discIdAutocompleteRef &&
            this.discIdAutocompleteRef.current &&
            !this.discIdAutocompleteRef.current.contains(event.target)
        ) {
            this.setState({discIdAutocompleteOpen: false})
        }
    }

    onSummaryRowClick(_, rowIndex) {
        this.setState({
            selectedSummaryIndex: rowIndex,
            serialNumber: this.state.discSummaries[rowIndex]["discId"],
            discIdSearchValue: this.state.discSummaries[rowIndex]["discId"]
        })
    }

    onOwnerSearchInputChange(newValue) {
        if (newValue !== '' && this.ownerSearchInputRef && this.ownerSearchInputRef.current && this.ownerSearchInputRef.current.contains(document.activeElement)) {
            this.setState({ownerAutocompletePending: true}, () => this.loadOwnersForAutocompletion(newValue))
        } else {
            this.setState({
                discSummaries: [],
                ownerAutocompleteOpen: false,
            })
        }
        this.setState({
            selectedOwner: false,
            ownerSearchValue: newValue
        })
    }

    onOwnerAutocompleteSelect(event, itemId) {
        event.stopPropagation()
        const owner = this.state.matchingOwners.find((o) => o["key"] === itemId)
        this.setState({
            ownerSearchValue: owner["displayName"],
            ownerAutocompleteOpen: false,
            selectedOwner: owner
        })
        this.ownerSearchInputRef.current.focus()
    }

    loadOwnersForAutocompletion(query) {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/autocompleteDiscOwner?query=${encodeURIComponent(query)}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => this.setState({
                        ownerAutocompletePending: false,
                        matchingOwners: payload,
                        ownerAutocompleteOptions: payload.map(o => <MenuItem
                            icon={o["type"]==="Company" ? <EnterpriseIcon /> : <UserIcon />}
                            itemId={o["key"]}
                            key={o["key"]}>
                            {o["displayName"]}
                        </MenuItem>),
                        ownerAutocompleteOpen: payload.length > 0
                    }))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    handleOwnerAutoCompletionKeys(event) {
        if (this.state.ownerAutocompleteOpen && this.ownerSearchInputRef.current && this.ownerSearchInputRef.current === event.target) {
            // if the autocomplete is open and the browser focus is on the search input,
            if (event.key === 'Escape') {
                // the escape key closes the autocomplete menu and keeps the focus on the search input.
                this.setState({ownerAutocompleteOpen: false})
                this.ownerSearchInputRef.current.focus();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                // the up and down arrow keys move browser focus into the autocomplete menu
                const firstElement = this.ownerAutocompleteRef.current.querySelector('li > button:not(:disabled)')
                firstElement && firstElement.focus()
                event.preventDefault() // by default, the up and down arrow keys scroll the window
            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
                // the tab, enter, and space keys will close the menu, and the tab key will move browser
                // focus forward one element (by default)
                this.setState({ownerAutocompleteOpen: false})
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault()
                }
            }
        } else if (this.state.ownerAutocompleteOpen && this.ownerAutocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            // If the autocomplete is open and the browser focus is in the autocomplete menu
            // hitting tab will close the autocomplete and but browser focus back on the search input.
            event.preventDefault();
            this.setState({ownerAutocompleteOpen: false})
            this.ownerSearchInputRef.current.focus();
        }
    }

    handleClickOutsideOwnerAutocompletion(event) {
        if (
            this.state.ownerAutocompleteOpen &&
            this.ownerAutocompleteRef &&
            this.ownerAutocompleteRef.current &&
            !this.ownerAutocompleteRef.current.contains(event.target)
        ) {
            this.setState({ownerAutocompleteOpen: false})
        }
    }

    onOrderNumberSearchInputChange(newValue) {
        if (newValue !== '' && this.orderNumberSearchInputRef && this.orderNumberSearchInputRef.current && this.orderNumberSearchInputRef.current.contains(document.activeElement)) {
            this.setState({orderNumberAutocompletePending: true}, () => this.loadOrderNumbersForAutocompletion(newValue))
        } else {
            this.setState({
                discSummaries: [],
                orderNumberAutocompleteOpen: false,
            })
        }
        this.setState({
            selectedOrderNumber: false,
            orderNumberSearchValue: newValue
        })
    }

    onOrderNumberAutocompleteSelect(event, itemId) {
        event.stopPropagation()
        const orderNumber = this.state.matchingOrderNumbers.find((o) => o === itemId)
        this.setState({
            orderNumberSearchValue: orderNumber,
            orderNumberAutocompleteOpen: false,
            selectedOrderNumber: orderNumber
        })
        this.orderNumberSearchInputRef.current.focus()
    }

    loadOrderNumbersForAutocompletion(query) {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/autocompleteOrderNumber?query=${encodeURIComponent(query)}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => this.setState({
                        orderNumberAutocompletePending: false,
                        matchingOrderNumbers: payload,
                        orderNumberAutocompleteOptions: payload.map(o => <MenuItem
                            itemId={o}
                            key={o}>
                            {o}
                        </MenuItem>),
                        orderNumberAutocompleteOpen: payload.length > 0
                    }))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    handleOrderNumberAutoCompletionKeys(event) {
        if (this.state.orderNumberAutocompleteOpen && this.orderNumberSearchInputRef.current && this.orderNumberSearchInputRef.current === event.target) {
            // if the autocomplete is open and the browser focus is on the search input,
            if (event.key === 'Escape') {
                // the escape key closes the autocomplete menu and keeps the focus on the search input.
                this.setState({orderNumberAutocompleteOpen: false})
                this.orderNumberSearchInputRef.current.focus();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                // the up and down arrow keys move browser focus into the autocomplete menu
                const firstElement = this.orderNumberAutocompleteRef.current.querySelector('li > button:not(:disabled)')
                firstElement && firstElement.focus()
                event.preventDefault() // by default, the up and down arrow keys scroll the window
            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
                // the tab, enter, and space keys will close the menu, and the tab key will move browser
                // focus forward one element (by default)
                this.setState({orderNumberAutocompleteOpen: false})
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault()
                }
            }
        } else if (this.state.orderNumberAutocompleteOpen && this.orderNumberAutocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            // If the autocomplete is open and the browser focus is in the autocomplete menu
            // hitting tab will close the autocomplete and but browser focus back on the search input.
            event.preventDefault();
            this.setState({orderNumberAutocompleteOpen: false})
            this.orderNumberSearchInputRef.current.focus();
        }
    }

    handleClickOutsideOrderNumberAutocompletion(event) {
        if (
            this.state.orderNumberAutocompleteOpen &&
            this.orderNumberAutocompleteRef &&
            this.orderNumberAutocompleteRef.current &&
            !this.orderNumberAutocompleteRef.current.contains(event.target)
        ) {
            this.setState({orderNumberAutocompleteOpen: false})
        }
    }

    async exportSettingsAsCsv() {
        this.setState({exportCsvMenuOpen: false})
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const parameters = {}
            if (this.state.serialNumber) {
                parameters["discId"] = this.state.serialNumber
            }
            if (this.state.selectedOwner) {
                parameters["ownerId"] = this.state.selectedOwner["key"]
            }
            if (this.state.selectedOrderNumber) {
                parameters["orderNumber"] = this.state.selectedOrderNumber
            }
            if (this.state.dateFilterStart && this.state.dateFilterEnd) {
                parameters["dateFrom"] = date2ISOString(this.state.dateFilterStart)
                parameters["dateUntil"] = date2ISOString(this.state.dateFilterEnd)
            }
            if (Object.entries(parameters).length === 0) {
                console && console.error("no parameters!")
                return
            }
            const url = `${AppSettings.rootPath}/v1/sensorDiscs/settings/csv?${Object.entries(parameters).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')}`
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("user-settings.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    async exportMeasurementsAsCsv() {
        this.setState({exportCsvMenuOpen: false})
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const parameters = {}
            if (this.state.serialNumber) {
                parameters["discId"] = this.state.serialNumber
            }
            if (this.state.selectedOwner) {
                parameters["ownerId"] = this.state.selectedOwner["key"]
            }
            if (this.state.selectedOrderNumber) {
                parameters["orderNumber"] = this.state.selectedOrderNumber
            }
            if (this.state.dateFilterStart && this.state.dateFilterEnd) {
                parameters["dateFrom"] = date2ISOString(this.state.dateFilterStart)
                parameters["dateUntil"] = date2ISOString(this.state.dateFilterEnd)
            }
            if (Object.entries(parameters).length === 0) {
                console && console.error("no parameters!")
                return
            }
            const url = `${AppSettings.rootPath}/v1/sensorDiscs/measurements/csv?${Object.entries(parameters).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')}`
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("measurements.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    async exportAssemblyMeasurementsAsCsv() {
        this.setState({exportCsvMenuOpen: false})
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const parameters = {}
            if (this.state.serialNumber) {
                parameters["discId"] = this.state.serialNumber
            }
            if (this.state.selectedOwner) {
                parameters["ownerId"] = this.state.selectedOwner["key"]
            }
            if (this.state.selectedOrderNumber) {
                parameters["orderNumber"] = this.state.selectedOrderNumber
            }
            if (this.state.dateFilterStart && this.state.dateFilterEnd) {
                parameters["dateFrom"] = date2ISOString(this.state.dateFilterStart)
                parameters["dateUntil"] = date2ISOString(this.state.dateFilterEnd)
            }
            if (Object.entries(parameters).length === 0) {
                console && console.error("no parameters!")
                return
            }
            const url = `${AppSettings.rootPath}/v1/sensorDiscs/assemblyMeasurements/csv?${Object.entries(parameters).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')}`
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("assembly_measurements.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    async exportTemperatureProfilesAsCsv() {
        this.setState({exportCsvMenuOpen: false})
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const parameters = {}
            if (this.state.serialNumber) {
                parameters["discId"] = this.state.serialNumber
            }
            if (this.state.selectedOwner) {
                parameters["ownerId"] = this.state.selectedOwner["key"]
            }
            if (this.state.selectedOrderNumber) {
                parameters["orderNumber"] = this.state.selectedOrderNumber
            }
            if (this.state.dateFilterStart && this.state.dateFilterEnd) {
                parameters["dateFrom"] = date2ISOString(this.state.dateFilterStart)
                parameters["dateUntil"] = date2ISOString(this.state.dateFilterEnd)
            }
            if (Object.entries(parameters).length === 0) {
                console && console.error("no parameters!")
                return
            }
            const url = `${AppSettings.rootPath}/v1/sensorDiscs/temperatureProfiles/csv?${Object.entries(parameters).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')}`
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("temperature_profiles.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    async exportFinalCalibrationsAsCsv() {
        this.setState({exportCsvMenuOpen: false})
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const parameters = {}
            if (this.state.serialNumber) {
                parameters["discId"] = this.state.serialNumber
            }
            if (this.state.selectedOwner) {
                parameters["ownerId"] = this.state.selectedOwner["key"]
            }
            if (this.state.selectedOrderNumber) {
                parameters["orderNumber"] = this.state.selectedOrderNumber
            }
            if (this.state.dateFilterStart && this.state.dateFilterEnd) {
                parameters["dateFrom"] = date2ISOString(this.state.dateFilterStart)
                parameters["dateUntil"] = date2ISOString(this.state.dateFilterEnd)
            }
            if (Object.entries(parameters).length === 0) {
                console && console.error("no parameters!")
                return
            }
            const url = `${AppSettings.rootPath}/v1/sensorDiscs/finalCalibrations/csv?${Object.entries(parameters).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')}`
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("final_calibrations.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    onTabSelect(event, tabIndex) {
        if (tabIndex === 0) {
            this.setState({activeTabKey: tabIndex})
        } else {
            this.setState({
                activeTabKey: tabIndex,
                discDataLoaded: false
            }, () => {
                if (tabIndex === 1) {
                    this.loadCalibrationData()
                } else {
                    this.loadOperationData()
                }
            })
        }
    }

    loadDiscSummaries() {
        const parameters = {}
        if (this.state.serialNumber) {
            parameters["discId"] = this.state.serialNumber
        }
        if (this.state.selectedOwner) {
            parameters["ownerId"] = this.state.selectedOwner["key"]
        }
        if (this.state.selectedOrderNumber) {
            parameters["orderNumber"] = this.state.selectedOrderNumber
        }
        if (this.state.dateFilterStart && this.state.dateFilterEnd) {
            parameters["dateFrom"] = date2ISOString(this.state.dateFilterStart)
            parameters["dateUntil"] = date2ISOString(this.state.dateFilterEnd)
        }
        if (Object.entries(parameters).length === 0) {
            console && console.error("no parameters!")
            return
        }
        const url = `${AppSettings.rootPath}/v1/sensorDiscs/summaries?${Object.entries(parameters).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')}`
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(url, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status === 204) {
                    this.setState({
                        metaData: {},
                        discDataLoaded: true
                    })
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => this.setState(prevState => {
                        return {
                            discSummaries: payload,
                            discDataLoaded: true,
                            selectedSummaryIndex: payload.length === 1 ? 0 : prevState.selectedSummaryIndex,
                            serialNumber: payload.length === 1 ? payload[0]["discId"] : prevState.serialNumber,
                            discIdSearchValue: payload.length === 1 ? payload[0]["discId"] : prevState.discIdSearchValue
                        }
                    }))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    loadCalibrationData() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/calibrationData/${this.state.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status === 204) {
                    this.setState({
                        calibrationData: {},
                        discDataLoaded: true
                    })
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => this.setState({
                        calibrationData: payload,
                        discDataLoaded: true
                    }))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    loadOperationData() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/operationData/${this.state.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status === 204) {
                    this.setState({
                        operationData: {},
                        discDataLoaded: true
                    })
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(payload => this.setState({
                        operationData: payload,
                        discDataLoaded: true
                    }))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    resetFirstProvisioning() {
        const requestInit = {
            cache: "no-cache",
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/resetFirstProvisioning/${this.state.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else if (this.state.activeTabKey === 2) {
                    this.loadOperationData()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({confirmResetFirstProvisioning: false}))
    }

    decommissionDisc() {
        const requestInit = {
            cache: "no-cache",
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/decommission/${this.state.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else if (this.state.activeTabKey === 2) {
                    this.loadOperationData()
                    this.setState(prevState => {
                        return {
                            chartReloadTrigger: !prevState.chartReloadTrigger
                        }
                    })
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({confirmDecommission: false}))
    }

    refreshData() {
        switch (this.state.activeTabKey) {
        case 0:
            this.loadDiscSummaries()
            break
        case 1:
            this.loadCalibrationData()
            break
        default:
            this.loadOperationData()
            this.setState(prevState => {
                return {
                    chartReloadTrigger: !prevState.chartReloadTrigger
                }
            })
        }
    }

    closeDateRangeModal() {
        this.setState(prevState => {
            return {
                showDateRangeModal: false,
                dateRanges: [{
                    startDate: prevState.dateFilterStart,
                    endDate: prevState.dateFilterEnd,
                    key: "selection"
                }]
            }
        })
    }

    editDateRange(item) {
        const { startDate, endDate } = item["selection"]
        this.setState({
            dateRangeModalRanges: [{
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }]
        })
    }

    setDateRange() {
        this.setState(prevState => {
            let startDate = prevState.dateRangeModalRanges[0].startDate
            let endDate = prevState.dateRangeModalRanges[0].endDate
            return {
                dateFilterStart: startDate,
                dateFilterEnd: endDate,
                showDateRangeModal: false,
                dateFilterLabel: formatDateRange(startDate, endDate)
            }
        })
    }

    handleWindowResize() {
        const width = window.innerWidth
        const useWidth = width - (this.props.isNavOpen && width >= 1200 ? 290 : 0)
        this.setState({
            contentWidth: useWidth
        })
    }
}

export default withRouter(SensorDiscPage)