import React from "react";
import ReactDOM from "react-dom";

import "@patternfly/react-core/dist/styles/base.css";
import "./index.css";

import AuthenticatedApp from "./AuthenticatedApp";
import "./i18n";

ReactDOM.render(
    <React.Suspense fallback="Loading...">
        <AuthenticatedApp />
    </React.Suspense>,
    document.getElementById("root")
)
