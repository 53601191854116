import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerContentBody,
    Form,
    FormGroup,
    TextArea,
    TextInput
} from "@patternfly/react-core";

import {
    CheckCircleIcon,
    TrashIcon,
    WrenchIcon
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";
import "./DetailsDrawer.css";

class ModemUrlDetails extends Component {
    static MODEM_URL_DETAILS = [
        {key:"updatesVersion", label:"fe_common_modem-version"},
        {key:"localizedCreatedAt", label:"fe_common_created-on"},
        {key:"localizedReleasedAt", label:"fe_firmware_released-on"}
    ]

    constructor(props) {
        super(props)

        this.state = {
            details: {},
            formChanged: false
        }

        this.loadDetails = this.loadDetails.bind(this)
        this.getStatusAlertVariant = this.getStatusAlertVariant.bind(this)
        this.getStatusAlertIcon = this.getStatusAlertIcon.bind(this)
        this.submitChanges = this.submitChanges.bind(this)
        this.releaseModemUrl = this.releaseModemUrl.bind(this)
        this.removeModemUrl = this.removeModemUrl.bind(this)
    }

    componentDidMount() {
        this.loadDetails()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updatesVersion !== prevProps.updatesVersion) {
            this.loadDetails()
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <DrawerContentBody className="DetailsDrawer">
                        <Alert variant={this.getStatusAlertVariant()} isInline
                               title={this.state.details["localizedStatus"] || ""}
                               customIcon={this.getStatusAlertIcon()}/>
                        <DataList isCompact aria-label="details">
                            <DataList isCompact aria-label="details">
                                {
                                    ModemUrlDetails.MODEM_URL_DETAILS.map((item) => {
                                        return (
                                            <DataListItem key={item.key}>
                                                <DataListItemRow>
                                                    <DataListItemCells dataListCells={[
                                                        <DataListCell key="label"
                                                                      isFilled={false}>{t(item.label)}</DataListCell>,
                                                        <DataListCell key="value" isFilled={false} alignRight
                                                                      className="Value">{this.state.details[item.key]}</DataListCell>
                                                    ]}/>
                                                </DataListItemRow>
                                            </DataListItem>
                                        )
                                    })
                                }
                            </DataList>
                            <Form className="DetailsForm">
                                <FormGroup
                                    label="URL"
                                    isRequired
                                    fieldId="url"
                                    helperText={t("fe_modem-urls_url-hint")}>
                                    <TextInput
                                        type="text"
                                        id="url"
                                        name="url"
                                        value={this.state.details["url"]}
                                        onChange={(value) => this.setState(prevState => {
                                            prevState.details["url"] = value
                                            return {
                                                details: prevState.details,
                                                formChanged: true
                                            }
                                        })}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={t("fe_common_note")}
                                    fieldId="modem-firmware-notes"
                                    helperText={t("fe_firmware_notes-hint")}>
                                    <TextArea
                                        id="modem-firmware-notes"
                                        name="modem-firmware-notes"
                                        autoResize
                                        resizeOrientation="vertical"
                                        value={this.state.details["notes"]}
                                        onChange={(value) => this.setState(prevState => {
                                            prevState.details["notes"] = value
                                            return {
                                                details: prevState.details,
                                                formChanged: true
                                            }
                                        })}
                                    />
                                </FormGroup>
                                <ActionGroup className="DetailsActionGroup">
                                    <Button isDisabled={!this.state.formChanged} variant="primary"
                                            onClick={this.submitChanges}>{t("fe_common_save")}</Button>
                                    <Button isDisabled={this.state.details["released"]} variant="primary"
                                            onClick={this.releaseModemUrl}>{t("fe_common_publish")}</Button>
                                    <Button variant="danger" icon={<TrashIcon/>}
                                            onClick={this.removeModemUrl}>{t("fe_common_delete")}</Button>
                                </ActionGroup>
                            </Form>
                        </DataList>
                    </DrawerContentBody>
                }
            </Translation>
        )
    }

    loadDetails() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls/${this.props.updatesVersion}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({
                            details: data,
                            formChanged: false
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    getStatusAlertVariant() {
        return this.state.details["released"] ? "success" : "info"
    }

    getStatusAlertIcon() {
        return this.state.details["released"] ? <CheckCircleIcon/> : <WrenchIcon/>
    }

    submitChanges() {
        const requestInit = {
            cache: "no-cache",
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                url: this.state.details["url"],
                notes: this.state.details["notes"]
            })
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls/${this.props.updatesVersion}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.onDetailsChanged()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    releaseModemUrl() {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                url: this.state.details["url"],
                notes: this.state.details["notes"]
            })
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls/${this.props.updatesVersion}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.onDetailsChanged()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    removeModemUrl() {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls/${this.props.updatesVersion}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.onDetailsChanged()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default ModemUrlDetails