import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerActions,
    DrawerCloseButton,
    DrawerContentBody,
    DrawerHead,
    Dropdown,
    DropdownItem,
    DropdownPosition,
    DropdownSeparator,
    DropdownToggle,
    ExpandableSection, Flex, FlexItem,
    Form,
    FormGroup,
    KebabToggle,
    Modal,
    ModalVariant,
    Text, TextInput,
    TextVariants,
    Title
} from "@patternfly/react-core";

import {
    AsleepIcon,
    CheckCircleIcon,
    CogIcon,
    DisconnectedIcon,
    DownloadIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    ExternalLinkSquareAltIcon,
    HistoryIcon,
    MonitoringIcon,
    OutlinedClockIcon,
    QuestionCircleIcon,
    SyncAltIcon
} from "@patternfly/react-icons";

import streamSaver from "streamsaver";

import ForceTemperatureChart from "./ForceTemperatureChart";
import CurrentForceTemperatureChart from "./CurrentForceTemperatureChart";
import { AppSettings } from "./AppSettings";

import "./GatewayDetails.css";
import "./DetailsDrawer.css";
import "./DetailsForm.css";
import QRCodeModal from "./QRCodeModal";
import NotificationsModal from "./NotificationsModal";

class GatewayDetails extends Component {
    static PROFILE_EXPANDABLE = [
        {key:"name", label:"fe_common_name"},
        {key:"firmwareVersion", label:"fe_common_firmware"},
        {key:"testFirmwareVersion", label:"fe_gateways_test-firmware"},
        {key:"modemVersion", label:"fe_common_modem-version"},
        {key:"testModemVersion", label:"fe_gateways_test-modem"},
        {key:"localizedHeartbeatInterval", label:"fe_common_heartbeat-interval"},
        {key:"poweredBy", label:"fe_common_power-supply"},
        {key:"batteryLevel", label:"fe_common_remaining-battery"},
        {key:"lowBatteryAlertAt", label:"fe_common_battery-warning"},
        {key:"temperature", label:"fe_gateway_temperature"},
        {key:"network", label:"fe_common_network"},
        {key:"signalStrength", label:"fe_common_signal-strength"},
        {key:"location", label:"fe_common_location"},
        {key:"startTime", label:"fe_gateway_last-restart"},
        {key:"lastMessageReceivedAt", label:"fe_common_last-feedback"}
    ]

    static HARDWARE_EXPANDABLE = [
        {key:"version", label:"gateway_prop_version"},
        {key:"connectors", label:"gateway_prop_ports"},
        {key:"manufacturedAt", label:"gateway_prop_date-of-manufacture"},
        {key:"chargeNumber", label:"gateway_prop_charge"},
        {key:"registeredAt", label:"fe_common_registered-at"},
        {key:"preSharedKey", label:"fe_gateway_psk"}
    ]

    static SIMCARD_EXPANDABLE = [
        {key:"cardNumber", label:"gateway_prop_simcard-number"},
        {key:"provider", label:"gateway_prop_simcard-provider"},
        {key:"type", label:"gateway_prop_simcard-type"},
        {key:"localizedExpiry", label:"gateway_prop_simcard-valid-until"}
    ]

    static CUSTOMER_EXPANDABLE = [
        {key:"customerName", label:"fe_common_name"},
        {key:"customerEmail", label:"fe_login_email"},
        {key:"provisionedAt", label:"fe_common_provisioned-at"},
        {key:"lastConfiguredAt", label:"fe_common_configured-at"}
    ]

    static ANCHOR_EXPANDABLE = [
        {key:"anchorId", label:"fe_common_serial-number"},
        {key:"name", label:"fe_common_name"},
        {key:"localizedMeasureInterval", label:"fe_common_measuring-interval"},
        {key:"environment", label:"fe_anchor_environment"},
        {key:"surface", label:"fe_anchor_surface"},
        {key:"torque", label:"fe_anchor_torque"},
        {key:"factorySignalBias1", label:"anchor_prop_factory-cp1-signal"},
        {key:"factoryForceBias1", label:"anchor_prop_factory-cp1-load"},
        {key:"factorySignalBias2", label:"anchor_prop_factory-cp2-signal"},
        {key:"factoryForceBias2", label:"anchor_prop_factory-cp2-load"},
        {key:"userSignalBias1", label:"anchor_prop_user-cp1-signal"},
        {key:"userForceBias1", label:"anchor_prop_user-cp1-load"},
        {key:"userSignalBias2", label:"anchor_prop_user-cp2-signal"},
        {key:"userForceBias2", label:"anchor_prop_user-cp2-load"},
        {key:"lastTaredAt", label:"fe_anchor_tared-at"},
        {key:"minForceThreshold", label:"fe_anchor_load-lower-threshold"},
        {key:"maxForceThreshold", label:"fe_anchor_load-upper-threshold"},
        {key:"minTemperatureThreshold", label:"fe_anchor_temperature-lower-threshold"},
        {key:"maxTemperatureThreshold", label:"fe_anchor_temperature-upper-threshold"},
        {key:"lastSignal", label:"fe_anchor_last-signal"}
    ]

    static FORECASTING_EXPANDABLE = [
        {key:"localizedDuration", label:"fe_anchor_forecasting-duration-with-label"},
        {key:"localizedGranularity", label:"fe_anchor_forecasting-granularity-with-label"},
        {key:"lastModified", label:"fe_common_forecasting-configured"}
    ]

    static MODAL_PROPERTY_GW_DEBUGGING = "configureGatewayDebugging"
    static MODAL_PROPERTY_TEST_FIRMWARE = "configureTestFirmware"
    static MODAL_PROPERTY_TEST_MODEM = "configureTestModem"

    constructor(props) {
        super(props)

        this.state = {
            details: {
                simInfo: {},
                anchors: []
            },
            expandedSections: {
                profile: true,
                hardware: true,
                simInfo: true,
                customer: true
            },
            isHeaderToggleMenuOpen: false,
            toggleMenus: {},
            confirmResetAnchor: false,
            confirmDecommission: false,
            csvDownloadInProgress: false,
            csvDownloadCompleted: false,
            showQRCodeModal: false,
            showNotificationsModal: false,
            modalPropertyKey: false,
            modalPropertyValue: false,
            modalDropdownOpen: false,
            showModal: false,
            modalGatewayId: false,
            modalFirmwareIds: [],
            modalModemUrlVersions: [],
            forecastingConfigInProgress: false
        }

        this.loadDetails = this.loadDetails.bind(this)
        this.getStatusAlertIcon = this.getStatusAlertIcon.bind(this)
        this.getStatusAlertVariant = this.getStatusAlertVariant.bind(this)
        this.getStatus = this.getStatus.bind(this)
        this.expandSection = this.expandSection.bind(this)
        this.renderSensorAnchorExpandable = this.renderSensorAnchorExpandable.bind(this)
        this.onAnchorMenuSelect = this.onAnchorMenuSelect.bind(this)
        this.onAnchorMenuToggle = this.onAnchorMenuToggle.bind(this)
        this.exportAnchorTelemetryAsCsv = this.exportAnchorTelemetryAsCsv.bind(this)
        this.resetAnchor = this.resetAnchor.bind(this)
        this.showInAnchorList = this.showInAnchorList.bind(this)
        this.showInSimCardsList = this.showInSimCardsList.bind(this)
        this.confirmResetAnchor = this.confirmResetAnchor.bind(this)
        this.decommissionGateway = this.decommissionGateway.bind(this)
        this.onDropDownMenuSelect = this.onDropDownMenuSelect.bind(this)
        this.renderNotificationsModal = this.renderNotificationsModal.bind(this)
        this.renderQRCodeModal = this.renderQRCodeModal.bind(this)
        this.editTestFirmware = this.editTestFirmware.bind(this)
        this.editTestModem = this.editTestModem.bind(this)
        this.reloadFirmwareList = this.reloadFirmwareList.bind(this)
        this.reloadModemUrlList = this.reloadModemUrlList.bind(this)
        this.onModalPropertySelect = this.onModalPropertySelect.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirmModal = this.confirmModal.bind(this)
        this.editForecasting = this.editForecasting.bind(this)
        this.renderForecastingModal = this.renderForecastingModal.bind(this)
        this.confirmForecastingModal = this.confirmForecastingModal.bind(this)
        this.disableForecasting = this.disableForecasting.bind(this)
    }

    loadDetails() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/details/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({
                            details: data,
                            toggleMenus: {}
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    componentDidMount() {
        this.loadDetails()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.serialNumber !== prevProps.serialNumber || this.props.testFirmware !== prevProps.testFirmware || this.props.reloadTrigger !== prevProps.reloadTrigger) {
            this.loadDetails()
        }
    }

    render() {
        return (
            <Translation>
                { t =>
                    <>
                        <DrawerHead>
                            <Title headingLevel="h1">Sensor Gateway</Title>
                            <Text component={TextVariants.h6}>{this.props.serialNumber}</Text>
                            <DrawerActions>
                                <Button variant="plain" onClick={this.loadDetails}><SyncAltIcon/></Button>
                                <Button variant="plain" isInline onClick={() => this.props.addGatewayToStickyCharts(this.props.serialNumber)}><MonitoringIcon/></Button>
                                {
                                    this.props.showInGatewayList && <Button variant="link" isInline iconPosition="right" icon={<ExternalLinkSquareAltIcon />} onClick={this.props.showInGatewayList}/>
                                }
                                <Dropdown
                                    className="GatewayMenu"
                                    position={DropdownPosition.right}
                                    onSelect={this.onDropDownMenuSelect}
                                    toggle={<KebabToggle onToggle={(isOpen) => this.setState({isHeaderToggleMenuOpen: isOpen})} id="toggle-menu-gateway-details" />}
                                    isOpen={this.state.isHeaderToggleMenuOpen}
                                    isPlain
                                    dropdownItems={[
                                        <DropdownItem key="show-qrcode" component="button" onClick={() => this.setState({showQRCodeModal: true})}>{t("fe_qrcode_action")}</DropdownItem>,
                                        <DropdownItem key="show-notifications" component="button" onClick={() => this.setState({showNotificationsModal: true})}>{t("fe_notifications_action")}</DropdownItem>,
                                        <DropdownSeparator key="separator1" />,
                                        <DropdownItem key="edit-test-firmware" component="button" onClick={this.editTestFirmware}>{t("fe_gateways_test-firmware")}</DropdownItem>,
                                        <DropdownItem key="edit-test-modem" component="button" onClick={this.editTestModem}>{t("fe_gateways_test-modem")}</DropdownItem>
                                    ]}
                                />
                                <DrawerCloseButton onClick={this.props.closeDrawer}/>
                            </DrawerActions>
                        </DrawerHead>
                        <DrawerContentBody className="DetailsDrawer">
                            <Alert
                                customIcon={this.getStatusAlertIcon()}
                                variant={this.getStatusAlertVariant()}
                                isInline title={this.getStatus(t)}
                            >
                                {
                                    this.state.details["errorMessage"] && <p>{this.state.details["errorMessage"]}</p>
                                }
                            </Alert>
                            {
                                this.state.details.profile && (
                                    <ExpandableSection
                                        toggleText={t("fe_gateway-section_profile-state")}
                                        isExpanded={this.state.expandedSections.profile}
                                        onToggle={(isExpanded) => this.expandSection("profile", isExpanded)}
                                    >
                                        <DataList isCompact aria-label="profile">
                                            {
                                                GatewayDetails.PROFILE_EXPANDABLE
                                                    .filter((item) => this.state.details.profile[item.key] !== null)
                                                    .map((item) => (
                                                        <DataListItem key={item.key}>
                                                            <DataListItemRow>
                                                                <DataListItemCells dataListCells={[
                                                                    <DataListCell
                                                                        key="label"
                                                                        isFilled={false}>
                                                                        {t(item.label)}
                                                                    </DataListCell>,
                                                                    <DataListCell
                                                                        key="value"
                                                                        isFilled={false}
                                                                        alignRight
                                                                        className="Value">
                                                                        {this.state.details.profile[item.key]}
                                                                    </DataListCell>
                                                                ]}/>
                                                            </DataListItemRow>
                                                        </DataListItem>
                                                    ))
                                            }
                                        </DataList>
                                    </ExpandableSection>
                                )
                            }
                            <ExpandableSection
                                toggleText={t("fe_gateway-section_hardware")}
                                isExpanded={this.state.expandedSections.hardware}
                                onToggle={(isExpanded) => this.expandSection("hardware", isExpanded)}
                            >
                                <DataList isCompact aria-label="hardware">
                                    {
                                        GatewayDetails.HARDWARE_EXPANDABLE.map((item) => (
                                            <DataListItem key={item.key}>
                                                <DataListItemRow>
                                                    <DataListItemCells dataListCells={[
                                                        <DataListCell
                                                            key="label"
                                                            isFilled={false}>
                                                            {t(item.label)}
                                                        </DataListCell>,
                                                        <DataListCell
                                                            key="value"
                                                            isFilled={false}
                                                            alignRight
                                                            className="Value">
                                                            {this.state.details[item.key]}
                                                        </DataListCell>
                                                    ]}/>
                                                </DataListItemRow>
                                            </DataListItem>
                                        ))
                                    }
                                </DataList>
                            </ExpandableSection>
                            {
                                <ExpandableSection
                                    toggleText={t("fe_gateway-section_sim-card")}
                                    isExpanded={this.state.expandedSections.simInfo}
                                    onToggle={(isExpanded) => this.expandSection("simInfo", isExpanded)}
                                >
                                    <DataList isCompact aria-label="simInfo">
                                        {
                                            GatewayDetails.SIMCARD_EXPANDABLE.map((item) => (
                                                <DataListItem key={item.key}>
                                                    <DataListItemRow>
                                                        <DataListItemCells dataListCells={[
                                                            <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                                            <DataListCell key="value" isFilled={false} alignRight className="Value">
                                                                {this.state.details.simInfo[item.key] || ""}
                                                                {item.key === "cardNumber" && (
                                                                    <Button
                                                                        variant="link"
                                                                        isInline
                                                                        iconPosition="right"
                                                                        icon={<ExternalLinkSquareAltIcon />}
                                                                        onClick={() => this.showInSimCardsList(this.state.details.simInfo[item.key])}
                                                                    />
                                                                )}
                                                            </DataListCell>
                                                        ]}/>
                                                    </DataListItemRow>
                                                </DataListItem>
                                            ))
                                        }
                                    </DataList>
                                </ExpandableSection>
                            }
                            {
                                this.state.details.profile && (
                                    <ExpandableSection
                                        toggleText={t("fe_gateway-section_customer")}
                                        isExpanded={this.state.expandedSections.customer}
                                        onToggle={(isExpanded) => this.expandSection("customer", isExpanded)}
                                    >
                                        <DataList isCompact aria-label="customer">
                                            {
                                                GatewayDetails.CUSTOMER_EXPANDABLE.map((item) => (
                                                    <DataListItem key={item.key}>
                                                        <DataListItemRow>
                                                            <DataListItemCells dataListCells={[
                                                                <DataListCell
                                                                    key="label"
                                                                    isFilled={false}>
                                                                    {t(item.label)}
                                                                </DataListCell>,
                                                                <DataListCell
                                                                    key="value"
                                                                    isFilled={false}
                                                                    alignRight
                                                                    className="Value">
                                                                    {this.state.details.profile[item.key]}
                                                                </DataListCell>
                                                            ]}/>
                                                        </DataListItemRow>
                                                    </DataListItem>
                                                ))
                                            }
                                        </DataList>
                                        <Form className="DetailsForm">
                                            <ActionGroup className="DetailsActionGroup">
                                                <Button variant="primary" onClick={() => this.setState({confirmDecommission: true})}>{t("fe_gateway_decommission")}</Button>
                                            </ActionGroup>
                                        </Form>
                                    </ExpandableSection>
                                )
                            }
                            {
                                this.state.details["anchors"].map((anchor => {
                                    return this.renderSensorAnchorExpandable(anchor, t)
                                }))
                            }
                        </DrawerContentBody>
                        {
                            this.state.confirmResetAnchor && (
                                <Modal
                                    variant={ModalVariant.small}
                                    title={t("fe_common_confirm-title")}
                                    isOpen={true}
                                    onClose={() => this.setState({confirmResetAnchor: false})}
                                    actions={[
                                        <Button key="confirm" variant="primary" onClick={this.resetAnchor}>{t("fe_action_reset")}</Button>,
                                        <Button key="cancel" variant="link" onClick={() => this.setState({confirmResetAnchor: false})}>{t("fe_common_cancel")}</Button>
                                    ]}
                                >{t("fe_gateway_reset-description")}</Modal>
                            )
                        }
                        {
                            this.state.confirmDecommission && (
                                <Modal
                                    variant={ModalVariant.small}
                                    title={t("fe_common_confirm-title")}
                                    isOpen={true}
                                    onClose={() => this.setState({confirmDecommission: false})}
                                    actions={[
                                        <Button key="confirm" variant="primary" onClick={this.decommissionGateway}>{t("fe_gateway_decommission")}</Button>,
                                        <Button key="cancel" variant="link" onClick={() => this.setState({confirmDecommission: false})}>{t("fe_common_cancel")}</Button>
                                    ]}
                                >{t("fe_gateway_decommission-description")}</Modal>
                            )
                        }
                        {
                            this.state.showModal && this.renderModal(t)
                        }
                        {
                            this.state.showNotificationsModal && this.renderNotificationsModal()
                        }
                        {
                            this.state.showQRCodeModal && this.renderQRCodeModal()
                        }
                        {
                            this.state.showForecastingModal && this.renderForecastingModal(this.state.showForecastingModal, t)
                        }
                    </>
                }
            </Translation>
        )
    }

    renderSensorAnchorExpandable(anchor, t) {
        const anchorId = anchor["anchorId"]
        const telemetryId = anchor["telemetryId"]
        const anchorProfile = anchor["profile"] || {}
        const anchorDetails = {...anchor, ...anchorProfile}
        const isConfigured = anchor["configured"]
        const toggleText = t(isConfigured ? "fe_configured-anchor-section_title" : "fe_unconfigured-anchor-section_title").replace("%", anchor["connector"])
        const isExpanded = this.state.expandedSections[anchorId] !== false
        const haveMeasurements = anchorDetails["lastSignal"] !== ""
        return (
            <ExpandableSection
                key={anchorId}
                toggleText={toggleText}
                isExpanded={isExpanded}
                onToggle={(isExpanded) => this.expandSection(anchorId, isExpanded)}
            >
                {
                    isExpanded && haveMeasurements &&
                    <>
                        <Dropdown
                            className="AnchorMenu"
                            position={DropdownPosition.right}
                            onSelect={(event) => this.onAnchorMenuSelect(event, anchorId)}
                            toggle={<KebabToggle onToggle={(isOpen) => this.onAnchorMenuToggle(isOpen, anchorId)} id={`toggle-menu-${anchorId}`} />}
                            isOpen={this.state.toggleMenus[anchorId]}
                            isPlain
                            dropdownItems={[
                                <DropdownItem key="export-as-csv" component="button" onClick={() => this.exportAnchorTelemetryAsCsv(anchorId, telemetryId)}>{t("fe_common_csv-export")}</DropdownItem>,
                                <DropdownItem key="reset-anchor" component="button" onClick={() => this.confirmResetAnchor(anchorId)}>{t("fe_action_reset")}</DropdownItem>
                            ]}
                        />
                        <CurrentForceTemperatureChart
                            t={t}
                            app={this.props.app}
                            force={anchorProfile["lastForce"]}
                            temperature={anchorProfile["lastTemperature"]}
                        />
                        <div className="TopExtraSpacer">
                            <ForceTemperatureChart
                                t={t}
                                app={this.props.app}
                                telemetryId={telemetryId}
                                reloadTrigger={this.props.reloadTrigger}
                            />
                        </div>
                    </>
                }
                <DataList isCompact aria-label={anchorId}>
                    {
                        GatewayDetails.ANCHOR_EXPANDABLE.map((item) => {
                            return (
                                <DataListItem key={item.key}>
                                    <DataListItemRow>
                                        <DataListItemCells dataListCells={[
                                            <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                            <DataListCell key="value" isFilled={false} alignRight className="Value">
                                                {anchorDetails[item.key] || ""}
                                                {item.key === "anchorId" && <Button variant="link" isInline iconPosition="right" icon={<ExternalLinkSquareAltIcon />} onClick={() => this.showInAnchorList(anchorDetails[item.key])}/>}
                                            </DataListCell>
                                        ]}/>
                                    </DataListItemRow>
                                </DataListItem>
                            )
                        })
                    }
                </DataList>
            </ExpandableSection>
        )
    }

    getStatusAlertVariant() {
        if (this.state.details) {
            switch (this.state.details["statusEnum"]) {
            case "Error":
            case "Offline":
                return "danger"
            case "Operational":
            case "Sleep":
                return "success"
            case "IncompleteAnchorSettings":
            case "Warning":
                return "warning"
            default:
                return "info"
            }
        }
        return "default"
    }

    getStatusAlertIcon() {
        if (this.state.details) {
            switch (this.state.details["statusEnum"]) {
            case "WaitingForFeedback":
                return <OutlinedClockIcon/>
            case "SleepDesired":
            case "Sleep":
                return <AsleepIcon/>
            case "Setup":
                return <CogIcon/>
            case "FirmwareUpgrade":
                return <DownloadIcon/>
            case "IncompleteAnchorSettings":
            case "Warning":
                return <ExclamationTriangleIcon/>
            case "Operational":
                return <CheckCircleIcon/>
            case "RebootDesired":
                return <HistoryIcon/>
            case "Offline":
                return <DisconnectedIcon/>
            case "Error":
                return <ExclamationCircleIcon/>
            default:
            }
        }
        return <QuestionCircleIcon/>
    }

    getStatus(t) {
        if (this.state.details) {
            return this.state.details["status"]
        }
        return t("gatewayStatusUnknown")
    }

    expandSection(sectionId, isExpanded) {
        this.setState(prevState => {
            prevState.expandedSections[sectionId] = isExpanded
            return {expandedSections: prevState.expandedSections}
        })
    }

    onAnchorMenuSelect(event, anchorId) {
        this.setState(prevState => {
            prevState.toggleMenus[anchorId] = !prevState.toggleMenus[anchorId]
            return {toggleMenus: prevState.toggleMenus}
        });
        document.getElementById(`toggle-menu-${anchorId}`).focus();
    }

    onAnchorMenuToggle(isOpen, anchorId) {
        this.setState(prevState => {
            prevState.toggleMenus[anchorId] = isOpen
            return {toggleMenus: prevState.toggleMenus}
        })
    }

    async exportAnchorTelemetryAsCsv(anchorId, telemetryId) {
        this.onAnchorMenuToggle(true, anchorId)

        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        try {
            const response = await fetch(`${AppSettings.rootPath}/v1/telemetry/${telemetryId}/csv`, requestInit)
            if (response.status === 401) {
                this.props.app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream(`${anchorId}.csv`)

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            this.props.app.showErrorMessage(error.message)
        }
    }

    confirmResetAnchor(anchorId) {
        this.onAnchorMenuToggle(true, anchorId)
        this.setState({confirmResetAnchor: anchorId})
    }

    resetAnchor() {
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/reset/${this.state.confirmResetAnchor}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.loadDetails()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({confirmResetAnchor: false}))
    }

    showInAnchorList(anchorId) {
        this.props["history"].push(`${AppSettings.rootPath}/sensorAnchors?filter=q:${anchorId}`)
    }

    showInSimCardsList(cardNumber) {
        this.props["history"].push(`${AppSettings.rootPath}/simCards?filter=q:${cardNumber}`)
    }

    decommissionGateway() {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/decommission/${this.props.serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.props.refreshList()
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({confirmDecommission: false}))
    }

    onDropDownMenuSelect() {
        this.setState(prevState => {
            return {isHeaderToggleMenuOpen: prevState.isHeaderToggleMenuOpen}
        })
        document.getElementById("toggle-menu-gateway-details").focus()
    }

    editTestFirmware() {
        this.reloadFirmwareList()
        const serialNumber = this.props.serialNumber
        this.setState({
            modalPropertyKey: GatewayDetails.MODAL_PROPERTY_TEST_FIRMWARE,
            modalGatewayId: serialNumber,
            modalPropertyValue: "",
            showModal: true
        })
    }

    editTestModem() {
        this.reloadModemUrlList()
        const serialNumber = this.props.serialNumber
        this.setState({
            modalPropertyKey: GatewayDetails.MODAL_PROPERTY_TEST_MODEM,
            modalGatewayId: serialNumber,
            modalPropertyValue: "",
            showModal: true
        })
    }

    onModalPropertyChanged(value) {
        this.setState({modalPropertyValue: value})
    }

    onModalPropertySelect() {
        this.setState({modalDropdownOpen: false})
        document.getElementById("modal-dropdown-toggle").focus()
    }

    closeModal() {
        this.setState({showModal: false})
    }

    confirmModal(event) {
        event && event.preventDefault()
        const serialNumber = this.props.serialNumber
        this.setState({showModal: false})

        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                key: this.state.modalPropertyKey,
                value: this.state.modalPropertyValue
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorGateways/configure/${serialNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    if (this.state.modalPropertyKey === GatewayDetails.MODAL_PROPERTY_TEST_FIRMWARE ||
                        this.state.modalPropertyKey === GatewayDetails.MODAL_PROPERTY_TEST_MODEM)
                    {
                        this.loadDetails()
                    }
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    renderNotificationsModal() {
        return <NotificationsModal
            gatewayId={this.props.serialNumber}
            closeModal={() => this.setState({showNotificationsModal: false})}
            app={this.props.app}
        />
    }

    renderQRCodeModal() {
        return <QRCodeModal
            gatewayId={this.props.serialNumber}
            closeModal={() => this.setState({showQRCodeModal: false})}
        />
    }

    reloadFirmwareList() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({modalFirmwareIds: data}))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    reloadModemUrlList() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/firmware/modem/urls`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({modalModemUrlVersions: data}))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    renderModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_modal-title")}
                isOpen={this.state.showModal}
                onClose={this.closeModal}
                actions={[
                    <Button
                        key="confirm"
                        variant="primary"
                        form="modal-with-form-form"
                        onClick={() => this.confirmModal(false)}
                    >
                        {t("fe_common_ok")}
                    </Button>
                ]}
            >
                {
                    this.state.modalPropertyKey === GatewayDetails.MODAL_PROPERTY_TEST_FIRMWARE && (
                        <Form id="modal-with-form" onSubmit={(event) => this.confirmModal(event)}>
                            <FormGroup
                                label={t("fe_gateways_test-firmware")}
                                fieldId="testFirmware"
                                helperText={t("fe_gateways_test-firmware-hint")}
                            >
                                <Dropdown
                                    className="ScrollableDropdownContainer"
                                    style={{width:"100%"}}
                                    id="testFirmware"
                                    onSelect={this.onModalPropertySelect}
                                    toggle={
                                        <DropdownToggle
                                            id="modal-dropdown-toggle"
                                            onToggle={isOpen => this.setState({modalDropdownOpen: isOpen})}
                                            style={{width:"100%"}}
                                        >
                                            {this.state.modalPropertyValue || t("fe_common_select-placeholder")}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.modalDropdownOpen}
                                    dropdownItems={
                                        this.state.modalFirmwareIds.map((option, index) => (
                                            <DropdownItem
                                                key={index}
                                                description={option.summary}
                                                component="button"
                                                onClick={() => this.onModalPropertyChanged(option.id)}
                                            >
                                                {`HW v${option["hardwareVersion"]}, FW ${option["firmwareVersion"]}`}
                                            </DropdownItem>
                                        ))
                                    }
                                    menuAppendTo={() => document.body}
                                />
                            </FormGroup>
                        </Form>
                    )
                }
                {
                    this.state.modalPropertyKey === GatewayDetails.MODAL_PROPERTY_TEST_MODEM && (
                        <Form id="modal-with-form-form" onSubmit={(event) => this.confirmModal(event)}>
                            <FormGroup
                                label={t("fe_gateways_test-modem")}
                                fieldId="testModem"
                                helperText={t("fe_gateways_test-modem-hint")}>
                                <Dropdown
                                    className="ScrollableDropdownContainer"
                                    style={{width:"100%"}}
                                    id="testModem"
                                    onSelect={this.onModalPropertySelect}
                                    toggle={
                                        <DropdownToggle
                                            id="modal-dropdown-toggle"
                                            onToggle={isOpen => this.setState({modalDropdownOpen: isOpen})}
                                            style={{width:"100%"}}
                                        >
                                            {this.state.modalPropertyValue || t("fe_common_select-placeholder")}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.modalDropdownOpen}
                                    dropdownItems={
                                        this.state.modalModemUrlVersions.map((option, index) => (
                                            <DropdownItem
                                                key={index}
                                                description={option.summary}
                                                component="button"
                                                onClick={() => this.onModalPropertyChanged(option.updatesVersion)}
                                            >
                                                {option.updatesVersion}
                                            </DropdownItem>
                                        ))
                                    }
                                    menuAppendTo={() => document.body}
                                />
                            </FormGroup>
                        </Form>
                    )
                }
            </Modal>
        )
    }

    editForecasting(anchorId) {
        const forecasting = this.state.details["anchors"].find(a => a["anchorId"] === anchorId)["forecasting"] || false
        this.setState({
            showForecastingModal: anchorId,
            forecastingDurationValue: forecasting ? forecasting.durationValue : 30,
            forecastingDurationUnit: forecasting ? forecasting.durationUnit : "d",
            forecastingGranularityValue: forecasting ? forecasting.granularityValue : 1,
            forecastingGranularityUnit: forecasting ? forecasting.granularityUnit : "h"
        })
    }

    renderForecastingModal(anchorId, t) {
        const forecasting = this.state.details["anchors"].find(a => a["anchorId"] === anchorId)["forecasting"] || false
        const isUpdate = forecasting !== false
        const actions = [
            <Button
                key="confirm"
                variant="primary"
                form="modal-with-form-form"
                onClick={() => this.confirmForecastingModal(false, anchorId)}
                isDisabled={this.state.forecastingConfigInProgress}
                icon={this.state.forecastingConfigInProgress && <SyncAltIcon className="ProgressAnimation"/>}
            >
                {t(this.state.forecastingConfigInProgress ? "fe_action_forecasting-model-generating" : isUpdate ? "fe_common_update" : "fe_common_ok")}
            </Button>
        ]
        if (isUpdate && !this.state.forecastingConfigInProgress) {
            actions.push(
                <Button
                    key="disable"
                    variant="danger"
                    onClick={() => this.disableForecasting(anchorId)}
                >
                    {t("fe_action_forecasting-disable")}
                </Button>
            )
        }
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_forecasting")}
                isOpen={this.state.showForecastingModal}
                onClose={() => this.setState({showForecastingModal: false})}
                actions={actions}
            >
                <Form id="modal-with-form-form" onSubmit={(event) => this.confirmForecastingModal(event, anchorId)}>
                    <FormGroup label={t("fe_anchor_forecasting-duration")} fieldId="duration-value" className="TopSpacer">
                        <Flex>
                            <FlexItem>
                                <TextInput type="number" id="duration-value"
                                           name="duration-value"
                                           value={this.state.forecastingDurationValue}
                                           onChange={(value) => this.setState({forecastingDurationValue: value})}
                                           style={{width:"400px"}}
                                />
                            </FlexItem>
                            <FlexItem>
                                <Dropdown
                                    onSelect={() => this.setState({forecastDurationDropdownOpen: false})}
                                    toggle={
                                        <DropdownToggle
                                            id="duration-unit"
                                            onToggle={() => this.setState(prevState => {return {forecastDurationDropdownOpen: !prevState.forecastDurationDropdownOpen}})}
                                        >
                                            {this.state.forecastingDurationUnit}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.forecastDurationDropdownOpen}
                                    dropdownItems={[
                                        <DropdownItem key="m" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "m"})}>m</DropdownItem>,
                                        <DropdownItem key="h" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "h"})}>h</DropdownItem>,
                                        <DropdownItem key="d" component="button"
                                                      onClick={() => this.setState({forecastingDurationUnit: "d"})}>d</DropdownItem>
                                    ]}
                                    menuAppendTo="parent"
                                />
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                    <FormGroup label={t("fe_anchor_forecasting-granularity")} fieldId="granularity-value">
                        <Flex>
                            <FlexItem>
                                <TextInput type="number" id="granularity-value"
                                           name="granularity-value"
                                           value={this.state.forecastingGranularityValue}
                                           onChange={(value) => this.setState({forecastingGranularityValue: value})}
                                           style={{width:"400px"}}
                                />
                            </FlexItem>
                            <FlexItem>
                                <Dropdown
                                    onSelect={() => this.setState({forecastGranularityDropdownOpen: false})}
                                    toggle={
                                        <DropdownToggle
                                            id="granularity-unit"
                                            onToggle={() => this.setState(prevState => {return {forecastGranularityDropdownOpen: !prevState.forecastGranularityDropdownOpen}})}
                                        >
                                            {this.state.forecastingGranularityUnit}
                                        </DropdownToggle>
                                    }
                                    isOpen={this.state.forecastGranularityDropdownOpen}
                                    dropdownItems={[
                                        <DropdownItem key="m" component="button"
                                                      onClick={() => this.setState({forecastingGranularityUnit: "m"})}>m</DropdownItem>,
                                        <DropdownItem key="h" component="button"
                                                      onClick={() => this.setState({forecastingGranularityUnit: "h"})}>h</DropdownItem>,
                                        <DropdownItem key="d" component="button"
                                                      onClick={() => this.setState({forecastingGranularityUnit: "d"})}>d</DropdownItem>
                                    ]}
                                    menuAppendTo="parent"
                                />
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                </Form>
            </Modal>
        )
    }

    confirmForecastingModal(event, anchorId) {
        event && event.preventDefault()
        this.setState({forecastingConfigInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                durationValue: this.state.forecastingDurationValue,
                durationUnit: this.state.forecastingDurationUnit,
                granularityValue: this.state.forecastingGranularityValue,
                granularityUnit: this.state.forecastingGranularityUnit
            })
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/forecasting/${anchorId}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({showForecastingModal: false}, () => this.loadDetails())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({forecastingConfigInProgress: false}))
    }

    disableForecasting(anchorId) {
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorAnchors/forecasting/${anchorId}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState({showForecastingModal: false}, () => this.loadDetails())
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default withRouter(GatewayDetails)