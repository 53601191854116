import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";

import zoomPlugin from 'chartjs-plugin-zoom';

export const registerCharts = () => {
    Chart.register(
        ArcElement,
        BarElement,
        CategoryScale,
        Filler,
        Legend,
        LineElement,
        LinearScale,
        PointElement,
        TimeScale,
        Tooltip,
        Title,
        zoomPlugin
    )
}