import React, { Component } from "react";

import {
    Flex,
    FlexItem
} from "@patternfly/react-core";

import { formatNumber } from "./Utils"

import Gauge from "./Gauge";

import "./ForceTemperatureChart.css";

class CurrentForceTemperatureChart extends Component {
    static MIN_FORCE = 0
    static MAX_FORCE = 200
    static MIN_TEMPERATURE = -25
    static MAX_TEMPERATURE = 50

    render() {
        return (
            <Flex justifyContent={{ default: "justifyContentSpaceAround" }}>
                <FlexItem>
                    <div>
                        <Gauge
                            diameter={140}
                            color='#c9190b'
                            rangeStart={CurrentForceTemperatureChart.MIN_FORCE}
                            rangeEnd={CurrentForceTemperatureChart.MAX_FORCE}
                            value={this.props.force || 0}
                            formattedValue={
                                this.props.force !== undefined ? `${formatNumber(this.props.force, 1, this.props.t)} kN` : "?"
                            }
                            label="Last force"
                        />
                    </div>
                </FlexItem>
                <FlexItem>
                    <div>
                        <Gauge
                            diameter={140}
                            color='#0066cc'
                            rangeStart={CurrentForceTemperatureChart.MIN_TEMPERATURE}
                            rangeEnd={CurrentForceTemperatureChart.MAX_TEMPERATURE}
                            value={this.props.temperature || 0}
                            formattedValue={
                                this.props.temperature !== undefined ? `${formatNumber(this.props.temperature, 1, this.props.t)} °C` : "?"
                            }
                            label="Last temperature"
                        />
                    </div>
                </FlexItem>
            </Flex>
        )
    }
}

export default CurrentForceTemperatureChart