import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
} from 'date-fns';

function defines() {
    return {
        now: new Date(),
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
    }
}

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

export function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export function stickyChartsDateRanges(t) {
    const defs = defines()
    return createStaticRanges([
        {
            label: t("fe_chart_period-today"),
            range: () => ({
                startDate: defs.startOfToday,
                endDate: defs.endOfToday
            }),
        },
        {
            label: t("fe_chart_period-last2d"),
            range: () => ({
                startDate: addDays(defs.startOfToday, -1),
                endDate: defs.endOfToday
            }),
        },
        {
            label: t("fe_chart_period-last3d"),
            range: () => ({
                startDate: addDays(defs.startOfToday, -2),
                endDate: defs.endOfToday
            }),
        },
        {
            label: t("fe_chart_period-last7d"),
            range: () => ({
                startDate: addDays(defs.startOfToday, -6),
                endDate: defs.endOfToday
            }),
        },
        {
            label: t("fe_chart_period-last30d"),
            range: () => ({
                startDate: addDays(defs.startOfToday, -29),
                endDate: defs.endOfToday
            }),
        },
        {
            label: t("fe_chart_period-last60d"),
            range: () => ({
                startDate: addDays(defs.startOfToday, -59),
                endDate: defs.endOfToday
            }),
        },
        {
            label: t("fe_chart_period-last-month"),
            range: () => ({
                startDate: defs.startOfLastMonth,
                endDate: defs.endOfLastMonth,
            }),
        },
    ]);
}

export function stickyChartsInputRanges(t) {
    const defs = defines()
    return [{
        label: t("fe_chart_input-range-till-today"),
        range(value) {
            return {
                startDate: addDays(defs.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                endDate: defs.endOfToday,
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.endDate, defs.endOfToday)) return '-';
            if (!range.startDate) return '∞';
            return differenceInCalendarDays(defs.endOfToday, range.startDate);
        },
    }]
}