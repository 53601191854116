import React from "react";

import i18n from "i18next";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import { de, enUS, fr } from 'date-fns/locale';

import { AppSettings } from "./AppSettings";
import {
    formatDateRange,
    formatDateTimeForChartAxis,
    formatNumber
} from "./Utils";

import "./SensorDiscChart.css";

const SensorDiscChart = ({t, app, discId, chartContent, showTemperature, naturalScaling, smoothLines, fillArea, showDots, setZoomDateRange, reloadTrigger, resetZoomTrigger}) => {
    const ZOOM_DURATION_MILLIS = 1000
    const [chartOptions, setChartOptions] = React.useState({})
    const [chartData, setChartData] = React.useState({
        labels: [],
        datasets: [{
            data: []
        }, {
            data: []
        }, {
            data: []
        }, {
            data: []
        }]
    })
    const [mounted, setMounted] = React.useState(false)
    const [startDate, setStartDate] = React.useState()
    const [endDate, setEndDate] = React.useState()
    const [showTooltip, setShowTooltip] = React.useState(true)

    React.useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [setMounted])

    React.useEffect(() => {
        setStartDate(undefined)
        setEndDate(undefined)
    }, [resetZoomTrigger])

    const onZoomComplete = React.useCallback(({chart}) => {
        window.setTimeout(() => setShowTooltip(true), ZOOM_DURATION_MILLIS)
        const {min, max} = chart.scales.x
        setStartDate(min)
        setEndDate(max)
        setZoomDateRange(formatDateRange(new Date(min), new Date(max)))
    }, [setShowTooltip, setZoomDateRange])

    React.useEffect(() => {
        setChartData(cd => ({
            labels: [],
            datasets: [{
                id: 'percent',
                label: t("fe_chart_load"),
                borderColor: 'rgb(201, 25, 11)',
                backgroundColor: 'rgba(201, 25, 11, 0.25)',
                yAxisID: 'leftAxis',
                borderWidth: 2,
                fill: fillArea ? 'start' : false,
                pointHitRadius: 10,
                pointRadius: showDots ? 2 : 0,
                tension: smoothLines ? 0.2 : 0,
                unit: '%',
                data: cd.datasets[0].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'pe'
                },
                hidden: chartContent !== 'percent'
            }, {
                id: 'signal',
                label: t("fe_disc_signal"),
                borderColor: 'rgb(201, 25, 11)',
                backgroundColor: 'rgba(201, 25, 11, 0.25)',
                yAxisID: 'leftAxis',
                borderWidth: 2,
                fill: fillArea ? 'start' : false,
                pointHitRadius: 10,
                pointRadius: showDots ? 2 : 0,
                tension: smoothLines ? 0.2 : 0,
                unit: 'µV/V',
                data: cd.datasets[1].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'si'
                },
                hidden: chartContent !== 'signal'
            }, {
                id: 'signal-compensated',
                label: t("fe_chart_compensated-signal"),
                borderColor: 'rgb(201, 25, 11)',
                backgroundColor: 'rgba(201, 25, 11, 0.25)',
                yAxisID: 'leftAxis',
                borderWidth: 2,
                fill: fillArea ? 'start' : false,
                pointHitRadius: 10,
                pointRadius: showDots ? 2 : 0,
                tension: smoothLines ? 0.2 : 0,
                unit: 'µV/V',
                data: cd.datasets[2].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'sc'
                },
                hidden: chartContent !== 'signal-compensated'
            }, {
                id: 'temperature',
                label: t("fe_chart_temperature"),
                borderColor: 'rgb(0, 102, 204)',
                backgroundColor: 'rgba(0, 102, 204, 0.25)',
                yAxisID: 'rightAxis',
                borderWidth: 2,
                fill: fillArea ? 'start' : false,
                pointHitRadius: 10,
                pointRadius: showDots ? 2 : 0,
                tension: smoothLines ? 0.2 : 0,
                unit: '°C',
                data: cd.datasets[3].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'te'
                },
                hidden: !showTemperature
            }]
        }))
    }, [t, chartContent, showTemperature, smoothLines, fillArea, showDots])

    React.useEffect(() => {
        setChartOptions({
            animation: true,
            responsive: true,
            maintainAspectRatio: false,
            parsing: false,
            scales: {
                x: {
                    type: 'time',
                    min: startDate,
                    max: endDate,
                    adapters: {
                        date: {
                            locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS
                        }
                    },
                    time: {
                        displayFormats: {
                            hour: 'dd MMM HH:mm',
                            minute: 'HH:mm',
                            second: 'HH:mm:ss'
                        }
                    },
                    ticks: {
                        maxTicksLimit: 10,
                        maxRotation: 0,
                        major: {
                            enabled: true
                        },
                        font: function(context) {
                            if (context.tick && context.tick.major) {
                                return {
                                    weight: 'bold'
                                }
                            }
                        }
                    }
                },
                leftAxis: {
                    axis: 'y',
                    type: 'linear',
                    position: 'left',
                    grace: '5%',
                    min: naturalScaling ? chartContent === 'percent' ? 0 : -5000 : undefined,
                    max: naturalScaling ? chartContent === 'percent' ? 100 : 5000 : undefined,
                    grid: {
                        borderColor: '#c9190b',
                        borderWidth: 2
                    }
                },
                rightAxis: {
                    axis: 'y',
                    type: 'linear',
                    position: 'right',
                    grace: '5%',
                    min: naturalScaling ? -20 : undefined,
                    max: naturalScaling ? 50 : undefined,
                    grid: {
                        display: false,
                        borderColor: '#0066cc',
                        borderWidth: 2
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: showTooltip,
                    callbacks: {
                        title: context => formatDateTimeForChartAxis(context[0].parsed.x, t),
                        label: context => {
                            let label = context.dataset.label || ''
                            if (label) {
                                label += ': '
                            }
                            if (context.parsed.y !== null) {
                                label += formatNumber(context.parsed.y, 2, t) + ' ' + context.dataset.unit
                            }
                            return label
                        }
                    },
                    position: 'nearest',
                    mode: 'index',
                    intersect: false
                },
                zoom: {
                    limits: {
                        x: {min: 'original', max: 'original', minRange: 10 * 60 * 1000},
                    },
                    pan: {
                        enabled: false
                    },
                    zoom: {
                        wheel: {
                            enabled: false,
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: '#dddddd'
                        },
                        pinch: {
                            enabled: false
                        },
                        mode: 'x',
                        onZoomStart: () => setShowTooltip(false),
                        onZoomRejected: () => setShowTooltip(true),
                        onZoomComplete: onZoomComplete
                    }
                }
            },
            transitions: {
                zoom: {
                    animation: {
                        duration: ZOOM_DURATION_MILLIS
                    }
                }
            }
        })
    }, [t, startDate, endDate, chartContent, naturalScaling, showTooltip, onZoomComplete])

    const loadTelemetry = React.useCallback(() => {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorDiscs/measurements/${discId}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(telemetry => {
                            mounted && setChartData(cd => ({
                                ...cd,
                                datasets: [{
                                    ...cd.datasets[0],
                                    data: telemetry
                                }, {
                                    ...cd.datasets[1],
                                    data: telemetry
                                }, {
                                    ...cd.datasets[2],
                                    data: telemetry
                                }, {
                                    ...cd.datasets[3],
                                    data: telemetry
                                }]
                            }))
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => app.showErrorMessage(error.message))
    }, [app, discId, mounted])

    React.useEffect(() => {
        loadTelemetry()
    }, [discId, startDate, endDate, reloadTrigger, loadTelemetry])

    return <div className="SensorDiscChart">
        <div className="Distinguished" style={{height: '400px'}}>
            <Line
                datasetIdKey='id'
                options={chartOptions}
                data={chartData}
            />
        </div>
    </div>
}

export default SensorDiscChart