import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    Button,
    Modal,
    ModalVariant
} from "@patternfly/react-core";

import { AppSettings } from "./AppSettings";
import "./App.css";

class QRCodeModal extends Component {
    render() {
        return (
            <Translation>
                { t =>
                    <Modal
                        variant={ModalVariant.small}
                        title={this.props.gatewayId}
                        isOpen={true}
                        onClose={() => this.props.closeModal()}
                        actions={[
                            <Button
                                key="confirm"
                                variant="primary"
                                form="modal-with-form-form"
                                onClick={this.props.closeModal}
                            >
                                {t("fe_common_ok")}
                            </Button>
                        ]}
                    >
                        <div>
                            <img src={`${AppSettings.rootPath}/v1/qrcodes/generate/${this.props.gatewayId}`} alt="" style={{width:"500px",height:"500px"}}/>
                        </div>
                    </Modal>
                }
            </Translation>
        )
    }
}

export default QRCodeModal