import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Level,
    LevelItem,
    Modal,
    ModalVariant,
    Panel
} from "@patternfly/react-core";

import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";

import "./App.css"

class NotificationsModal extends Component {
    static PAGE_SIZE = 100

    constructor(props) {
        super(props)

        this.state = {
            total: false,
            notifications: [],
            lastId: false,
            haveMoreToLoad: false
        }

        this.loadNextPage = this.loadNextPage.bind(this)
        this.showInAnchorList = this.showInAnchorList.bind(this)
    }

    componentDidMount() {
        this.setState({
            total: false,
            notifications: [],
            lastId: false,
            haveMoreToLoad: false,
            selectedCardId: false
        }, () => this.loadNextPage())
    }

    render() {
        return (
            <Translation>
                { t =>
                    <Modal
                        variant={ModalVariant.medium}
                        title={t("fe_notifications_title").replace("%", this.state.total)}
                        isOpen={true}
                        onClose={() => this.props.closeModal()}
                        actions={[
                            <Button
                                key="loadNextPage"
                                variant="primary"
                                isDisabled={this.state.haveMoreToLoad === false}
                                onClick={this.loadNextPage}
                            >
                                {t("fe_common_load-more")}
                            </Button>,
                            <Button
                                key="closeModal"
                                variant="secondary"
                                onClick={() => this.props.closeModal()}
                            >
                                {t("fe_common_close")}
                            </Button>
                        ]}
                    >
                        <Panel isScrollable>
                            {
                                this.state.notifications.map((notification) => (
                                    <Card
                                        key={notification.id}
                                        className="Notification"
                                        component="div"
                                        hasSelectableInput={notification.anchorId !== null}
                                        isSelectableRaised={notification.anchorId !== null}
                                        onClick={() => notification.anchorId !== null && this.showInAnchorList(notification.anchorId)}
                                        isSelected={false}
                                        selectableInputAriaLabel="card"
                                    >
                                        <CardTitle>
                                            <Level>
                                                <LevelItem>{notification.timestamp}</LevelItem>
                                                <LevelItem>
                                                    {notification.priority === "Info" && <CheckCircleIcon className="Success"/>}
                                                    {notification.priority === "Warning" && <ExclamationTriangleIcon className="Warning"/>}
                                                    {notification.priority === "Critical" && <ExclamationCircleIcon className="Danger"/>}
                                                </LevelItem>
                                            </Level>
                                        </CardTitle>
                                        <CardBody><div dangerouslySetInnerHTML={{__html:notification.message}}></div></CardBody>
                                    </Card>
                                ))
                            }
                        </Panel>
                    </Modal>
                }
            </Translation>
        )
    }

    loadNextPage() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/notifications?gatewayId=${this.props.gatewayId}&pageSize=${NotificationsModal.PAGE_SIZE}${this.state.lastId !== false ? `&lastId=${this.state.lastId}` : ""}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.setState(prevState => {
                                const total = prevState.total === false ? data.total : prevState.total
                                const notifications = prevState.notifications.concat(data.items)
                                return {
                                    total: total,
                                    notifications: notifications,
                                    lastId: notifications.length > 0 ? notifications[notifications.length-1].id : false,
                                    haveMoreToLoad: total > notifications.length
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    showInAnchorList(anchorId) {
        this.props["history"].push(`${AppSettings.rootPath}/sensorAnchors?filter=q:${anchorId}`)
    }
}

export default withRouter(NotificationsModal)