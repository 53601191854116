import React from "react";
import i18n from "i18next";

import { Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import { de, enUS, fr } from 'date-fns/locale';

import { AppSettings } from "./AppSettings";
import { formatDateTimeForChartAxis, formatNumber } from "./Utils";

import "./StickyCharts.css";

const StickyChart = ({t, app, telemetryId, height, width, animations, startDate, endDate, showLoadChart, showTemperatureChart, naturalScaling, fillArea, showDots, smoothLines, setChartTelemetryLoaded, setDateRangeTo}) => {
    const ZOOM_DURATION_MILLIS = 1000
    const POINTS = 200
    const [chartOptions, setChartOptions] = React.useState({})
    const [chartData, setChartData] = React.useState({
        labels: [],
        datasets: [{
            data: []
        }, {
            data: []
        }]
    })
    const [showTooltip, setShowTooltip] = React.useState(true)

    const fetchTelemetry = React.useCallback(() => {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/telemetry/${telemetryId}/ofRange?simulate=${AppSettings.simulateTelemetry}&startMillis=${startDate.getTime()}&endMillis=${endDate.getTime()}&numberOfValues=${POINTS}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(telemetry => {
                        setChartData(cd => ({
                            ...cd,
                            datasets: [{
                                ...cd.datasets[0],
                                data: telemetry
                            }, {
                                ...cd.datasets[1],
                                data: telemetry
                            }]
                        }))
                        setChartTelemetryLoaded()
                    })
                }
            })
    }, [app, telemetryId, startDate, endDate, setChartTelemetryLoaded])

    React.useEffect(() => {
        setChartData(cd => ({
            labels: [],
            datasets: [{
                id: 'load',
                label: t("fe_chart_load"),
                borderColor: 'rgb(201, 25, 11)',
                backgroundColor: 'rgba(201, 25, 11, 0.25)',
                yAxisID: 'leftAxis',
                borderWidth: 2,
                fill: fillArea ? 'start' : false,
                pointHitRadius: 10,
                pointRadius: showDots ? 2 : 0,
                tension: smoothLines ? 0.2 : 0,
                unit: 'kN',
                data: cd.datasets[0].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'fo'
                },
                hidden: !showLoadChart
            }, {
                id: 'temperature',
                label: t("fe_chart_temperature"),
                borderColor: 'rgb(0, 102, 204)',
                backgroundColor: 'rgba(0, 102, 204, 0.25)',
                yAxisID: 'rightAxis',
                borderWidth: 2,
                fill: fillArea ? 'start' : false,
                pointHitRadius: 10,
                pointRadius: showDots ? 2 : 0,
                tension: smoothLines ? 0.2 : 0,
                unit: '°C',
                data: cd.datasets[1].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'te'
                },
                hidden: !showTemperatureChart
            }]
        }))
    }, [t, showLoadChart, showTemperatureChart, smoothLines, fillArea, showDots])

    const onZoomComplete = React.useCallback(({chart}) => {
        window.setTimeout(() => setShowTooltip(true), ZOOM_DURATION_MILLIS)
        const {min, max} = chart.scales.x
        setTimeout(() => {
            setDateRangeTo(new Date(min), new Date(max))
        }, 500)
    }, [setShowTooltip, setDateRangeTo])

    React.useEffect(() => {
        setChartOptions({
            animation: animations,
            responsive: true,
            maintainAspectRatio: false,
            parsing: false,
            scales: {
                x: {
                    type: 'time',
                    min: startDate.getTime(),
                    max: endDate.getTime(),
                    adapters: {
                        date: {
                            locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS
                        }
                    },
                    time: {
                        displayFormats: {
                            hour: 'dd MMM HH:mm',
                            minute: 'HH:mm',
                            second: 'HH:mm:ss'
                        }
                    },
                    ticks: {
                        maxTicksLimit: 10,
                        maxRotation: 0,
                        major: {
                            enabled: true
                        },
                        font: function(context) {
                            if (context.tick && context.tick.major) {
                                return {
                                    weight: 'bold'
                                }
                            }
                        }
                    }
                },
                leftAxis: {
                    axis: 'y',
                    type: 'linear',
                    position: 'left',
                    grace: '5%',
                    min: naturalScaling ? 0 : undefined,
                    max: naturalScaling ? 200 : undefined,
                    grid: {
                        borderColor: '#c9190b',
                        borderWidth: 2
                    }
                },
                rightAxis: {
                    axis: 'y',
                    type: 'linear',
                    position: 'right',
                    grace: '5%',
                    min: naturalScaling ? -20 : undefined,
                    max: naturalScaling ? 50 : undefined,
                    grid: {
                        display: false,
                        borderColor: '#0066cc',
                        borderWidth: 2
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: showTooltip,
                    callbacks: {
                        title: context => formatDateTimeForChartAxis(context[0].parsed.x, t),
                        label: context => {
                            let label = context.dataset.label || ''
                            if (label) {
                                label += ': '
                            }
                            if (context.parsed.y !== null) {
                                label += formatNumber(context.parsed.y, 2, t) + ' ' + context.dataset.unit
                            }
                            return label
                        }
                    },
                    position: 'nearest',
                    mode: 'index',
                    intersect: false
                },
                zoom: {
                    limits: {
                        x: {min: 'original', max: 'original', minRange: 10 * 60 * 1000},
                    },
                    pan: {
                        enabled: false
                    },
                    zoom: {
                        wheel: {
                            enabled: false,
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: '#dddddd'
                        },
                        pinch: {
                            enabled: false
                        },
                        mode: 'x',
                        onZoomStart: () => setShowTooltip(false),
                        onZoomRejected: () => setShowTooltip(true),
                        onZoomComplete: onZoomComplete
                    }
                }
            },
            transitions: {
                zoom: {
                    animation: {
                        duration: ZOOM_DURATION_MILLIS
                    }
                }
            }
        })
    }, [t, animations, startDate, endDate, naturalScaling, showTooltip, onZoomComplete])

    React.useEffect(() => {
        fetchTelemetry()
    }, [startDate, endDate, telemetryId, fetchTelemetry])

    return <div style={{height: `${height}px`, width: `${width}px`, position: "relative"}}>
        <Line
            width={width}
            height={height}
            className="StickyChart"
            datasetIdKey='id'
            options={chartOptions}
            data={chartData}
        />
    </div>
}

export default StickyChart