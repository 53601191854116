import React from "react";
import i18n from "i18next";

import { Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import { de, enUS, fr } from 'date-fns/locale';
import { addDays } from 'date-fns';

import { AppSettings } from "./AppSettings";
import { formatDateTimeForChartAxis, formatNumber } from "./Utils";

import "./ForceTemperatureChart.css";

const ForceTemperatureChart = ({t, app, telemetryId, reloadTrigger}) => {
    const points = 120
    const now = new Date()

    const [startDate, setStartDate] = React.useState(addDays(now, -1))
    const [endDate, setEndDate] = React.useState(now)
    const [chartOptions, setChartOptions] = React.useState({})
    const [chartData, setChartData] = React.useState({
        labels: [],
        datasets: [{
            data: []
        }, {
            data: []
        }]
    })
    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [setMounted])

    React.useEffect(() => {
        const now = new Date()
        setStartDate(addDays(now, -1))
        setEndDate(now)
    }, [reloadTrigger])

    React.useEffect(() => {
        setChartData(cd => ({
            labels: [],
            datasets: [{
                id: 'load',
                label: t("fe_chart_load-with-unit"),
                borderColor: 'rgb(201, 25, 11)',
                backgroundColor: 'rgba(201, 25, 11, 0.25)',
                yAxisID: 'leftAxis',
                borderWidth: 2,
                fill: 'start',
                pointHitRadius: 10,
                pointRadius: 2,
                tension: 0.2,
                unit: 'kN',
                data: cd.datasets[0].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'fo'
                }
            }, {
                id: 'temperature',
                label: t("fe_chart_temperature-with-unit"),
                borderColor: 'rgb(0, 102, 204)',
                backgroundColor: 'rgba(0, 102, 204, 0.25)',
                yAxisID: 'rightAxis',
                borderWidth: 2,
                fill: 'start',
                pointHitRadius: 10,
                pointRadius: 2,
                tension: 0.2,
                unit: '°C',
                data: cd.datasets[1].data,
                normalized: true,
                parsing: {
                    xAxisKey: 'ts',
                    yAxisKey: 'te'
                }
            }]
        }))
    }, [t])

    React.useEffect(() => {
        setChartOptions({
            animation: true,
            responsive: true,
            maintainAspectRatio: false,
            parsing: false,
            scales: {
                x: {
                    type: 'time',
                    min: startDate.getTime(),
                    max: endDate.getTime(),
                    adapters: {
                        date: {
                            locale: i18n.language.startsWith("de") ? de : i18n.language.startsWith("fr") ? fr : enUS
                        }
                    },
                    time: {
                        displayFormats: {
                            hour: 'dd MMM HH:mm',
                            minute: 'HH:mm',
                            second: 'HH:mm:ss'
                        }
                    },
                    ticks: {
                        maxTicksLimit: 6,
                        maxRotation: 0,
                        major: {
                            enabled: true
                        },
                        font: function(context) {
                            if (context.tick && context.tick.major) {
                                return {
                                    weight: 'bold'
                                }
                            }
                        }
                    }
                },
                leftAxis: {
                    axis: 'y',
                    type: 'linear',
                    position: 'left',
                    grace: '5%',
                    grid: {
                        borderColor: '#c9190b',
                        borderWidth: 2
                    }
                },
                rightAxis: {
                    axis: 'y',
                    type: 'linear',
                    position: 'right',
                    grace: '5%',
                    grid: {
                        display: false,
                        borderColor: '#0066cc',
                        borderWidth: 2
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: t('fe_chart_last24h')
                },
                legend: {
                    position: 'bottom'
                },
                tooltip: {
                    callbacks: {
                        title: context => formatDateTimeForChartAxis(context[0].parsed.x, t),
                        label: context => {
                            let label = context.dataset.label || ''
                            if (label) {
                                label += ': '
                            }
                            if (context.parsed.y !== null) {
                                label += formatNumber(context.parsed.y, 2, t) + ' ' + context.dataset.unit
                            }
                            return label
                        }
                    },
                    position: 'nearest',
                    mode: 'index',
                    intersect: false
                }
            }
        })
    }, [t, startDate, endDate])

    const fetchTelemetry = React.useCallback(() => {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/telemetry/${telemetryId}/ofRange?simulate=${AppSettings.simulateTelemetry}&startMillis=${startDate.getTime()}&endMillis=${endDate.getTime()}&numberOfValues=${points}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(telemetry => {
                            mounted && setChartData(cd => ({
                                ...cd,
                                datasets: [{
                                    ...cd.datasets[0],
                                    data: telemetry
                                }, {
                                    ...cd.datasets[1],
                                    data: telemetry
                                }]
                            }))
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => app.showErrorMessage(error.message))
    }, [app, mounted, telemetryId, startDate, endDate])

    React.useEffect(() => {
        fetchTelemetry()
    }, [telemetryId, fetchTelemetry])

    return <div className="ForceTemperatureChart">
        <Line
            datasetIdKey='id'
            options={chartOptions}
            data={chartData}
        />
    </div>
}

export default ForceTemperatureChart