import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Bullseye,
    Button,
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerContentBody,
    ExpandableSection,
    Form,
    Modal,
    ModalVariant,
    Spinner
} from "@patternfly/react-core";

import {
    BanIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    ExternalLinkSquareAltIcon,
    UnknownIcon
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";
import UsageChart from "./UsageChart";
import "./DetailsForm.css"

class SimCardDetails extends Component {
    static GENERAL_FIELDS = [
        {key: "provider", label: "simcard_provider"},
        {key: "type", label: "simcard_type"},
        {key: "gatewayId", label: "fe_gateway"}
    ]

    static CUSTOMER_FIELDS = [
        {key:"name", label:"fe_common_name"},
        {key:"email", label:"fe_common_email"}
    ]

    static PROVIDER_STATUS_FIELDS = [
        {key:"status", label:"simcard_reported-status"},
        {key:"operator", label:"simcard_operator"},
        {key:"country", label:"fe_common_location"},
        {key:"updated", label:"simcard_location-reported-on"},
        {key:"since", label:"simcard_connected-since"},
        {key:"duration", label:"simcard_connection-duration"},
        {key:"technology", label:"simcard_network"}
    ]

    static VOLUME_USAGE_FIELDS = [
        {key:"total", label:"simcard_volume_total"},
        {key:"remaining", label:"simcard_volume_remaining"},
        {key:"expiresAt", label:"simcard_volume_expiry"},
        {key:"lastVolumeAdded", label:"simcard_credit_added"},
        {key:"volumeStatusChangedAt", label:"simcard_credit_added-on"},
        {key:"lastMonthTotalVolume", label:"simcard_usage_30d"},
        {key:"lastYearTotalVolume", label:"simcard_usage_1y"}
    ]

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            details: {},
            expandedSections: {
                customer: true,
                providerState: true,
                usage: true
            },
            showConfirmTopUpVolumeModal: false,
            topUpInProgress: false,
            topUpOrder: false
        }

        this.loadDetails = this.loadDetails.bind(this)
        this.getStatusAlertIcon = this.getStatusAlertIcon.bind(this)
        this.getStatusAlertVariant = this.getStatusAlertVariant.bind(this)
        this.getStatus = this.getStatus.bind(this)
        this.renderDataList = this.renderDataList.bind(this)
        this.expandSection = this.expandSection.bind(this)
        this.showInGatewayList = this.showInGatewayList.bind(this)
        this.confirmTopUpVolume = this.confirmTopUpVolume.bind(this)
        this.renderConfirmTopUpVolumeModal = this.renderConfirmTopUpVolumeModal.bind(this)
        this.topUpVolume = this.topUpVolume.bind(this)
        this.renderTopUpOrderModal = this.renderTopUpOrderModal.bind(this)
    }

    componentDidMount() {
        this.loadDetails()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cardNumber !== prevProps.cardNumber || this.props.reloadTrigger !== prevProps.reloadTrigger) {
            this.loadDetails()
        }
    }

    loadDetails() {
        this.setState({isLoading: true})
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simCards/details/${this.props.cardNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => this.setState({details: data}))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({isLoading: false}))
    }

    render() {
        return (
            <Translation>
                { t =>
                    <>
                        <DrawerContentBody className="DetailsDrawer">
                            <Alert customIcon={this.getStatusAlertIcon()} variant={this.getStatusAlertVariant()} isInline
                                   title={this.getStatus(t)}/>
                            <DataList isCompact aria-label="details">
                                {
                                    SimCardDetails.GENERAL_FIELDS.map((item) => {
                                        return (
                                            <DataListItem key={item.key}>
                                                <DataListItemRow>
                                                    <DataListItemCells dataListCells={[
                                                        <DataListCell key="label"
                                                                      isFilled={false}>{t(item.label)}</DataListCell>,
                                                        <DataListCell key="value" isFilled={false} alignRight
                                                                      className="Value">
                                                            {this.state.details[item.key]}
                                                            {item.key === "gatewayId" &&
                                                                <Button variant="link" isInline iconPosition="right"
                                                                        icon={<ExternalLinkSquareAltIcon/>}
                                                                        onClick={() => this.showInGatewayList(this.state.details[item.key])}/>}
                                                        </DataListCell>
                                                    ]}/>
                                                </DataListItemRow>
                                            </DataListItem>
                                        )
                                    })
                                }
                            </DataList>
                            {
                                this.state.details.customer && this.renderDataList(t, "fe_common_customer", "customer", SimCardDetails.CUSTOMER_FIELDS, this.state.details.customer)
                            }
                            {
                                this.state.details.providerStatus && this.renderDataList(t, "simcard_operating-status", "providerState", SimCardDetails.PROVIDER_STATUS_FIELDS,
                                    {
                                        status: this.state.details.providerStatus.status,
                                        ...this.state.details.providerStatus.location,
                                        ...this.state.details.providerStatus.connection
                                    }
                                )
                            }
                            {
                                this.state.details.providerStatus && this.renderDataList(t, "simcard_data-usage", "usage", SimCardDetails.VOLUME_USAGE_FIELDS,
                                    {
                                        lastMonthTotalVolume: this.state.details.providerStatus['usageLastMonth'] ? this.state.details.providerStatus['usageLastMonth']['totalVolume'] : "0 MB",
                                        lastYearTotalVolume: this.state.details.providerStatus['usageLastYear'] ? this.state.details.providerStatus['usageLastYear']['totalVolume'] : "0 MB",
                                        ...this.state.details.providerStatus.volume
                                    },
                                    [
                                        <UsageChart
                                            t={t}
                                            key="usage-30d"
                                            chartLabel={t("simcard_usage_30d")}
                                            chartData={this.state.details.providerStatus['usageLastMonth']["usages"]}
                                            unit="MB"
                                        />,
                                        <UsageChart
                                            t={t}
                                            key="usage-1y"
                                            chartLabel={t("simcard_usage_1y")}
                                            chartData={this.state.details.providerStatus['usageLastYear']["usages"]}
                                            unit="MB"
                                        />
                                    ]
                                )
                            }
                            {
                                this.state.details.providerStatus && (
                                    <Form className="DetailsForm">
                                        <ActionGroup className="DetailsActionGroup">
                                            <Button variant="primary" onClick={this.confirmTopUpVolume}>
                                                {t("simcard_action_top-up")}
                                            </Button>
                                        </ActionGroup>
                                    </Form>
                                )
                            }
                        </DrawerContentBody>
                        {
                            this.state.showConfirmTopUpVolumeModal && this.renderConfirmTopUpVolumeModal(t)
                        }
                        {
                            this.state.topUpInProgress && this.renderTopUpInProgressModal(t)
                        }
                        {
                            this.state.topUpOrder && this.renderTopUpOrderModal(t)
                        }
                    </>
                }
            </Translation>
        )
    }

    renderDataList(t, title, key, fields, keyValues, children) {
        return (
            <ExpandableSection
                toggleText={t(title)}
                isExpanded={this.state.expandedSections[key]}
                onToggle={(isExpanded) => this.expandSection(key, isExpanded)}
            >
                <DataList isCompact aria-label={key}>
                    {
                        fields
                            .filter((item) => keyValues[item.key] !== null)
                            .map((item) => {
                                return (
                                    <DataListItem key={item.key}>
                                        <DataListItemRow>
                                            <DataListItemCells dataListCells={[
                                                <DataListCell key="label" isFilled={false}>{t(item.label)}</DataListCell>,
                                                <DataListCell key="value" isFilled={false} alignRight className="Value">{keyValues[item.key]}</DataListCell>
                                            ]}/>
                                        </DataListItemRow>
                                    </DataListItem>
                                )
                            })
                    }
                </DataList>
                {
                    children && children
                }
            </ExpandableSection>
        )
    }

    renderConfirmTopUpVolumeModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_confirm-title")}
                isOpen={true}
                onClose={() => this.setState({showConfirmTopUpVolumeModal: false})}
                actions={[
                    <Button key="confirm" variant="primary" onClick={this.topUpVolume}>{t("simcard_action_top-up")}</Button>,
                    <Button key="cancel" variant="link" onClick={() => this.setState({showConfirmTopUpVolumeModal: false})}>{t("fe_common_cancel")}</Button>
                ]}
            >
                {t("fe_simcard_top-up-hint1")}
                <p/>
                {t("fe_simcard_top-up-hint2")}
            </Modal>
        )
    }

    renderTopUpInProgressModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_simcard_top-up-in-progress")}
                isOpen={true}
            >
                <Bullseye>
                    <Spinner isSVG size="xl"/>
                </Bullseye>
            </Modal>
        )
    }

    renderTopUpOrderModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_simcard_credit-ordered")}
                isOpen={true}
                onClose={() => this.setState({topUpOrder: false})}
                actions={[
                    <Button key="confirm" variant="primary" onClick={() => this.setState({topUpOrder: false})}>{t("fe_common_ok")}</Button>
                ]}
            >
                <DataList aria-label="Compact data list example" isCompact>
                    <DataListItem>
                        <DataListItemRow>
                            <DataListItemCells
                                dataListCells={[
                                    <DataListCell key="1">{t("fe_common_date")}</DataListCell>,
                                    <DataListCell key="2">{this.state.topUpOrder["date"]}</DataListCell>
                                ]}
                            />
                        </DataListItemRow>
                    </DataListItem>
                    <DataListItem>
                        <DataListItemRow>
                            <DataListItemCells
                                dataListCells={[
                                    <DataListCell key="1">{t("fe_common_status")}</DataListCell>,
                                    <DataListCell key="2">{this.state.topUpOrder["status"]}</DataListCell>
                                ]}
                            />
                        </DataListItemRow>
                    </DataListItem>
                    <DataListItem>
                        <DataListItemRow>
                            <DataListItemCells
                                dataListCells={[
                                    <DataListCell key="1">{t("fe_common_order-number")}</DataListCell>,
                                    <DataListCell key="2">{this.state.topUpOrder["invoiceNumber"]}</DataListCell>
                                ]}
                            />
                        </DataListItemRow>
                    </DataListItem>
                    <DataListItem>
                        <DataListItemRow>
                            <DataListItemCells
                                dataListCells={[
                                    <DataListCell key="1">{t("fe_common_price")}</DataListCell>,
                                    <DataListCell key="2">{this.state.topUpOrder["invoiceAmount"]}</DataListCell>
                                ]}
                            />
                        </DataListItemRow>
                    </DataListItem>
                </DataList>
            </Modal>
        )
    }

    expandSection(sectionId, isExpanded) {
        this.setState(prevState => {
            prevState.expandedSections[sectionId] = isExpanded
            return {expandedSections: prevState.expandedSections}
        })
    }

    getStatusAlertIcon() {
        if (this.state.isLoading) {
            return <Spinner isSVG size="md"/>
        }
        if (this.state.details["status"]) {
            switch (this.state.details["status"]["enum"]) {
            case "Disabled":
                return <BanIcon/>
            case "QuotaWarning":
                return <ExclamationTriangleIcon/>
            case "QuotaExceeded":
                return <ExclamationCircleIcon/>
            case "Error":
                return <UnknownIcon/>
            case "Operational":
                return <CheckCircleIcon/>
            default:
                return <UnknownIcon/>
            }
        }
        return <UnknownIcon/>
    }

    getStatusAlertVariant() {
        if (this.state.isLoading) {
            return "info"
        }
        if (this.state.details["status"]) {
            switch (this.state.details["status"]["enum"]) {
            case "Disabled":
                return "info"
            case "QuotaWarning":
                return "warning"
            case "QuotaExceeded":
                return "danger"
            case "Error":
                return "danger"
            case "Operational":
                return "success"
            default:
                return "info"
            }
        }
        return "info"
    }

    getStatus(t) {
        if (this.state.isLoading) {
            return t("fe_common_loading")
        }
        if (this.state.details["status"]) {
            return this.state.details["status"]["label"]
        }
        return t("fe_common_unknown")
    }

    showInGatewayList(gatewayId) {
        this.props["history"].push(`${AppSettings.rootPath}/sensorGateways?filter=q:${gatewayId}`)
    }

    confirmTopUpVolume() {
        this.setState({showConfirmTopUpVolumeModal: true})
    }

    topUpVolume() {
        this.setState({
            showConfirmTopUpVolumeModal: false,
            topUpInProgress: true
        })
        const requestInit = {
            cache: "no-cache",
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/simCards/topUp/${this.props.cardNumber}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(order => this.setState({topUpOrder: order}))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
            .finally(() => this.setState({topUpInProgress: false}))
    }
}

export default withRouter(SimCardDetails)